const IconArrowLeft = ({ className }: any) => (
  <svg className={className} width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width={24} height={24} rx={4} fill="#1C48E7" />
    <path
      d="M15 17L10.4068 12.8838C9.86439 12.3977 9.86439 11.6023 10.4068 11.1162L15 7"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconArrowLeft;
