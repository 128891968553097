import styles from '../index.module.scss';
import { HOLE_LIST } from '../constants/holeList';
import useItem from 'src/hook/useItem';
import { nanoid } from 'nanoid';
import { useEffect, useState } from 'react';
import { ACRYLIC_WIDTH_MAX, ACRYLIC_WIDTH_MIN, WIDTH_HOLE, WIDTH_STAGE } from 'src/assets/dataUI';
import convert from 'convert-length';
import { useCart } from 'src/components/common/Context';
import { clamp } from 'src/utils';
import { Button } from 'antd';
import { calculateSvgSize } from '../../SvgMerge/utils';
import SvgMerge from '../../SvgMerge';

type Props = {
  handleSetFeature: any;
  infoGeneralTemplate: any;
};

export default function Step1({ handleSetFeature, infoGeneralTemplate }: Props) {
  const { createItem } = useItem();
  const { acrylic } = useCart();
  const [hole, setHole] = useState<any>();
  const [svgContent, setSvgContent] = useState<any>();
  const [smallRadius, setSmallRadius] = useState<any>(convert(WIDTH_HOLE, 'mm', 'px'));
  const [paddingSmallCircle, setPaddingSmallCircles] = useState<any>(5);

  useEffect(() => {
    const acryWidth = clamp(infoGeneralTemplate?.width ?? 0, ACRYLIC_WIDTH_MIN, ACRYLIC_WIDTH_MAX);
    const result = convert(acryWidth, 'mm', 'px');
    if (svgContent) {
      setSmallRadius((convert(svgContent?.smallRadius ?? WIDTH_HOLE, 'mm', 'px') * (WIDTH_STAGE / result)) / 2);
      setPaddingSmallCircles((convert(svgContent?.paddingSmallCircle ?? 2, 'mm', 'px') * (WIDTH_STAGE / result)) / 2);
    }
  }, [acrylic, svgContent, infoGeneralTemplate]);

  useEffect(() => {
    setSvgContent({
      ...hole,
    });
  }, [hole]);

  const onSubmitFinal = () => {
    let shapeSize = calculateSvgSize({
      idToCheck: 'svg_outline_shape',
      baseShapeInfo: svgContent,
      isText: false,
    });

    createItem({
      id: nanoid(),
      attrs: {
        zIndex: 1,
        shapeData: [
          hole?.type === 99
            ? {
                cate: 'socket',
                ...svgContent,
                smallRadius: smallRadius,
                paddingSmallCircle: paddingSmallCircle,
                scale: {
                  base: 1,
                },
                outlinePadding: 0,
                shapeSize: shapeSize,
              }
            : {
                cate: 'socket',
                ...svgContent,
              },
        ],
      },
    });

    handleSetFeature();
  };

  return (
    <div className={styles.step1}>
      <div className={styles.holeType} key={'socketItem'}>
        <p className={styles.title}>アタッチメント[穴]</p>

        <div className={styles.holes}>
          {HOLE_LIST?.map((i: any, index: number) => {
            return (
              <div
                className={`${styles.holeItem} ${i.id === hole?.id ? styles.holeItemBorder : ''}`}
                key={i.key}
                onClick={() => {
                  setHole(i);
                }}
              >
                <div>
                  <img src="/hole1.svg" alt="hole" width={i?.smallRadius * 10} height={i?.smallRadius * 10} />
                  <p>内径 {i?.smallRadius}mm</p>
                </div>
              </div>
            );
          })}
        </div>

        <div
          style={{
            visibility: 'hidden',
            position: 'absolute',
          }}
        >
          {svgContent && (
            <SvgMerge
              shapeData={[
                {
                  cate: 'socket',
                  ...svgContent,
                  smallRadius: smallRadius,
                  paddingSmallCircle: paddingSmallCircle,
                  // shapeSize: shapeSize,
                  scale: {
                    base: 1,
                  },
                  outlinePadding: 0,
                },
              ]}
              outline={{
                showOutline: true,
              }}
            />
          )}
        </div>

        <div className="flex justify-center gap-2 mt-3">
          <Button
            className="button-form button-form-Submit !w-auto"
            onClick={() => {
              handleSetFeature();
            }}
          >
            {'<<詳細画面へ戻る'}
          </Button>
          <Button className="button-form button-form-Submit !w-auto" onClick={() => onSubmitFinal()} disabled={!hole}>
            OK
          </Button>
        </div>
      </div>
    </div>
  );
}
