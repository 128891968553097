import styles from '../index.module.scss';
import { useEffect, useState } from 'react';
import IconImageHaveBG from 'src/assets/icon/IconImageHaveBG';
import IconImageNoBG from 'src/assets/icon/IconImageNoBG';
import { nanoid } from 'nanoid';
import { Button, Spin, message } from 'antd';
import { HEIGHT_STAGE, WIDTH_STAGE } from 'src/assets/dataUI';
import { useCart } from 'src/components/common/Context';
import { cropVisibleArea } from 'src/utils';
import MenuBottom from 'src/components/common/menuBottom';

type Props = {
  image: any;
  setImage: (val: any) => void;
  setImageBackUp: (val: any) => void;
  setStep: (val: any) => void;
  imageRef: any;
  setIsRemoveBg: any;
  svgContent: any;
  setNewImage: any;
  newImage: any;
  imageSize: any;
  setImageSize: any;
  handleSetFeature: any;
};

const TAB_IMAGE = {
  haveBG: 'haveBG',
  noBG: 'noBG',
};

export default function Step1({
  imageSize,
  setImageSize,
  image,
  setImage,
  setImageBackUp,
  setStep,
  imageRef,
  setIsRemoveBg,
  svgContent,
  setNewImage,
  newImage,
  handleSetFeature,
}: Props) {
  const { autoRemoveBackground, modelLoaded } = useCart();
  const [isLoading, setIsLoading] = useState(false);
  const [currentImage, setCurrentImage] = useState<any>(false);
  const menu = [
    {
      id: TAB_IMAGE.noBG,
      isActive: false,
      name: '画像追加',
      sub: '(背景なし)',
      icon: <IconImageNoBG />,
      disabled: isLoading,
      onClick: () => {
        uploadImageRemoveBGHook();
        setIsRemoveBg(true);
      },
    },
    {
      id: TAB_IMAGE.haveBG,
      isActive: false,
      name: '画像追加',
      sub: '(背景あり)',
      icon: <IconImageHaveBG />,
      disabled: isLoading,
      onClick: () => {
        uploadImage();
        setIsRemoveBg(false);
      },
    },
  ];

  const uploadImage = () => {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/png, image/jpeg, image/jpg';

    fileInput.onchange = (e: any) => {
      setIsLoading(true);
      const files = e.target.files;
      if (files && files.length > 0) {
        const selectedFile = files[0];

        const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!validFileTypes.includes(selectedFile.type)) {
          message.error('アップロードするファイルは「JPG, JPEG, PNG」のいずれかの形式にしてください');
          setIsLoading(false);
          return;
        }

        const maxSize = 10 * 1024 * 1024;
        if (selectedFile.size > maxSize) {
          message.error('写真が重すぎる');
          setIsLoading(false);
          return;
        }

        const fileReader = new FileReader();
        fileReader.onload = () => {
          const backupImage = new Image();
          backupImage.src = fileReader.result as string;

          backupImage.onload = () => {
            // Create a canvas to resize the image
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            const maxWidth = 800; // set max width for the resized image
            const maxHeight = 800; // set max height for the resized image

            // Calculate the new dimensions while maintaining the aspect ratio
            let width = backupImage.width;
            let height = backupImage.height;

            if (width > maxWidth || height > maxHeight) {
              const aspectRatio = width / height;
              if (width > height) {
                width = maxWidth;
                height = maxWidth / aspectRatio;
              } else {
                height = maxHeight;
                width = maxHeight * aspectRatio;
              }
            }

            // Resize the image on the canvas
            canvas.width = width;
            canvas.height = height;
            ctx?.drawImage(backupImage, 0, 0, width, height);

            // Get the resized image as a base64 string
            const resizedImageDataUrl = canvas.toDataURL();

            setImageBackUp({
              type: 'image',
              id: nanoid(),
              name: 'imported image backup',
              src: resizedImageDataUrl,
            });

            const image = new Image();
            image.onload = () => {
              cropVisibleArea(
                image.src,
                (
                  imageBase64Data: any,
                  width: any,
                  height: any,
                  minX: any,
                  minY: any,
                  baseWidth: any,
                  baseHeight: any
                ) => {
                  setNewImage(imageBase64Data);
                  setImageSize({
                    x: minX,
                    y: minY,
                    width: width,
                    height: height,
                    baseWidth: baseWidth,
                    baseHeight: baseHeight,
                  });
                }
              );

              setImage({
                type: 'image',
                id: nanoid(),
                name: 'imported image',
                src: image.src,
              });
              setIsLoading(false);
            };
            image.src = resizedImageDataUrl;
          };
        };

        fileReader.readAsDataURL(selectedFile);
      } else {
        setIsLoading(false);
      }
    };
    fileInput.click();
  };

  const uploadImageRemoveBGHook = () => {
    const file = document.createElement('input');
    file.type = 'file';
    file.accept = 'image/png, image/jpeg';

    file.onchange = (e) => {
      setIsLoading(true);
      const files = (e.target as HTMLInputElement).files;
      if (files && files.length > 0) {
        const selectedFile = files[0];

        const validFileTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!validFileTypes.includes(selectedFile.type)) {
          message.error('アップロードするファイルは「JPG, JPEG, PNG」のいずれかの形式にしてください');
          setIsLoading(false);
          return;
        }

        const fileSizeInMB = selectedFile.size / (1024 * 1024);
        if (fileSizeInMB > 10) {
          message.error('ファイルサイズが5MBを超えています');
          setIsLoading(false);
          return;
        }

        const fileReader = new FileReader();
        fileReader.onload = () => {
          setIsLoading(true);

          const backupImage = new Image();
          backupImage.src = fileReader.result as string;
          setImageBackUp({
            type: 'image',
            id: nanoid(),
            name: 'imported image backup',
            src: backupImage.src,
          });
          setCurrentImage(fileReader.result);
        };
        fileReader.readAsDataURL(selectedFile);
      } else {
        setIsLoading(false);
      }
    };
    file.click();
  };

  useEffect(() => {
    if (modelLoaded) {
      const image = new Image();
      image.onload = () => {
        autoRemoveBackground(image).then((base64: string | undefined) => {
          //try to crop and save data here
          cropVisibleArea(
            base64,
            (imageBase64Data: any, width: any, height: any, minX: any, minY: any, baseWidth: any, baseHeight: any) => {
              setNewImage(imageBase64Data);
              setImageSize({
                x: minX,
                y: minY,
                width: width,
                height: height,
                baseWidth: baseWidth,
                baseHeight: baseHeight,
              });
            }
          );

          setImage({
            type: 'image',
            id: nanoid(),
            name: 'imported image',
            src: base64,
          });
          setIsLoading(false);
        });
      };
      image.src = currentImage;
    }
  }, [currentImage, modelLoaded]);

  return (
    <div className={styles.step1}>
      <div className="flex justify-center">
        <div
          className={styles.wrapImage}
          style={{
            width: WIDTH_STAGE,
            height: HEIGHT_STAGE,
          }}
        >
          {isLoading ? (
            <Spin />
          ) : (
            <>
              {image?.src && (
                <img
                  ref={imageRef}
                  src={image?.src}
                  alt="image"
                  id="imageId"
                  style={{
                    maxWidth: WIDTH_STAGE - 50,
                    maxHeight: HEIGHT_STAGE - 50,
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>

      <MenuBottom list={menu} />

      <div className="flex justify-center gap-2 mt-3">
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            handleSetFeature();
          }}
        >
          {'<<詳細画面へ戻る'}
        </Button>
        <Button
          className="button-form button-form-Submit !w-auto"
          disabled={!svgContent}
          onClick={() => {
            setStep((prev: number) => prev + 1);
          }}
        >
          {'次のステップへ>>'}
        </Button>
      </div>
    </div>
  );
}
