import { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { Input, Button, Form, message } from 'antd';
import { login } from 'src/api/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './login.modules.scss';
import { getToken, isFirstLogin } from 'src/utils/auth';
import Loading from 'src/components/common/loading';
import { getLocalStorage, setLocalStorage } from 'src/utils/cookie';
import { ROLE_ADMIN } from 'src/assets/constants';

const Login = () => {
  const history = useNavigate();
  const location = useLocation();
  const [messError, setMessError] = useState('');
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const roleUser = getLocalStorage('role');
  const { isLoading, mutateAsync } = useMutation((param: any) => login(param), {
    onSuccess: (data) => {
      const expiresTimeToken = new Date(new Date().getTime() + 1000 * data?.data?.expires_in);
      Cookies.set('token', data?.data?.access_token, { path: '/', expires: expiresTimeToken });
      Cookies.set('is_first_login', data?.data?.user?.is_first_login, { path: '/', expires: expiresTimeToken });
      setLocalStorage('role', data?.data?.user?.role);
      message.success('ログインに成功しました');
    },
    onError: () => {
      setMessError('メールアドレスもしくはパスワードが正しくないです。確認し、再度入力してください');
      return;
    },
  });

  const onNavigateWhenLogin = (role?: number | string) => {
    history('/order');
  };

  const onFinish = async (values: any) => {
    const { data } = await mutateAsync(values);
    localStorage.setItem('token', data?.access_token);
    if (isFirstLogin() && getToken()) {
      history('/change-password');
    } else {
      onNavigateWhenLogin(data?.user?.role);
    }
  };

  const handleResetMessError = () => {
    setMessError('');
  };

  // eslint-disable-next-line
  const validateEmail = (rule: any, value: string, callback: any) => {
    // eslint-disable-next-line
    const emailRegex = /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!value?.trim()) {
      callback('メールアドレスを入力してください');
    } else if (!emailRegex.test(value.trim())) {
      callback('メールアドレスが無効です');
    } else {
      callback();
    }
  };

  useEffect(() => {
    if (getToken()) {
      setIsAuth(true);
      onNavigateWhenLogin(roleUser);
    } else {
      setIsAuth(false);
    }
  }, [history, getToken(), location]);

  if (isAuth) return null;

  return (
    <div className="login">
      {isLoading && <Loading />}
      <div className="login__form">
        <div className="login__form-content">
          <h1 className="form-title mb-[40px]">ログイン</h1>
          <Form onFinish={onFinish} layout="vertical" autoComplete="off">
            <div className="mb-[16px]">
              <Form.Item
                required
                label="メール："
                name="email"
                rules={[{ validator: (rule, value, callback) => validateEmail(rule, value, callback) }]}
              >
                <Input
                  onChange={() => handleResetMessError()}
                  className="login__form__input"
                  size="large"
                  placeholder="メールを入力してください"
                />
              </Form.Item>
            </div>
            <Form.Item
              required
              label="パスワード："
              name="password"
              rules={[{ required: true, message: 'パスワードを入力してください' }]}
            >
              <Input.Password
                onChange={() => handleResetMessError()}
                className="login__form__input"
                placeholder="パスワードを入力してください"
              />
            </Form.Item>
            {messError && <p className="invalid-feedback whitespace-pre-line">{messError}</p>}
            <Button htmlType="submit" className="login__form__button mx-auto block my-[16px]">
              ログイン
            </Button>

            <div className="text-center login__form__guide">
              <a href="/forgot-password">パスワードをお忘れですか?</a>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default Login;
