import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import 'src/components/features/superAdmin/detailContent.modules.scss';
import { Button, Form } from 'antd';
import Progress from 'src/components/common/progress';
import IconFirst from 'src/assets/icon/IconFirst';
import IconSecend from 'src/assets/icon/IconSecend';
import IconThird from 'src/assets/icon/IconThird';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import useItem from 'src/hook/useItem';
import EditorComponent from './EditorComponent';
import {RatioProvider}  from 'src/hook/useChangeRatio';

interface TemplateDesignProps {
  setImageUrl?(val: any): void;
  setImageFile?(val: any): void;
  background?: any;
  setBackground?(val: any): void;
  detailData: any;
  infoGeneralTemplate: any;
}

const TemplateDesignCreateEditComponent = ({
  setImageUrl,
  setImageFile,
  background,
  setBackground,
  detailData,
  infoGeneralTemplate,
}: TemplateDesignProps) => {
  const [antForm] = Form.useForm();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isOpenModalAlertGoBack, setIsOpenModalAlertGoBack] = useState<boolean>(false);
  const [isAddToCart, setIsAddToCart] = useState(false);
  const { stageData, clearItems } = useItem();
  const [isAddItem, setIsAddItem] = useState(false);
  const [isSelectItem, setIsSelectItem] = useState(false);

  const STEPS_TEMPLATE = [
    {
      icon: <IconFirst />,
      name: '基本情報',
      processing: 1,
    },
    {
      icon: <IconSecend />,
      name: '詳細情報 ',
      processing: 2,
    },
    {
      icon: <IconThird />,
      name: '確認',
      processing: 3,
    },
  ];

  const handleGoBack = () => {
    if (id) {
      if (stageData.length) {
        handleOpenModal();
      } else {
        history(-1);
        setBackground?.(undefined);
      }
    } else {
      if (stageData.length) {
        handleOpenModal();
      } else {
        history(-1);
        setBackground?.(undefined);
      }
    }
  };

  const handleCloseModal = () => {
    setIsOpenModalAlertGoBack(false);
  };

  const handleOpenModal = () => {
    setIsOpenModalAlertGoBack(true);
  };

  const handleConfirmModal = () => {
    history(-1);
    setImageUrl?.('');
    setImageFile?.(undefined);
    setBackground?.(undefined);
    clearItems();
  };

  const handleDesignTemplate = async () => {
    if (stageData.length) {
      setIsAddToCart(true);
    }
  };

  return (
    <RatioProvider>
      <div className="page-content company-page">
        <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
          <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
            <div className="mr-[17px]">
              <IconArrowLeft />
            </div>
            <span className="line-1">{id ? 'テンプレートの編集' : 'テンプレートの追加'}</span>
          </div>
          <div></div>
        </div>
        <Form form={antForm} className="form-create w-[900px] mx-auto" layout="vertical" autoComplete="off">
          <Progress status={2} stepsOption={STEPS_TEMPLATE} classNameStepper="stepper-item-template" />
          <div className="pb-[12px] pt-[30px]">
            <div className="mb-[16px]">
              <div className="wrap-design-image-template">
                <EditorComponent
                  setImageUrl={setImageUrl}
                  setImageFile={setImageFile}
                  background={background}
                  setBackground={setBackground}
                  isAddToCart={isAddToCart}
                  detailData={detailData}
                  infoGeneralTemplate={infoGeneralTemplate}
                  setIsAddItem={setIsAddItem}
                  setIsSelectItem={setIsSelectItem}
                />
              </div>
            </div>
          </div>
          {!isAddItem && !isSelectItem ? (
            <div className={`footer-form flex`}>
              <Button disabled={isSelectItem} className="button-form mr-[16px]" onClick={handleGoBack}>
                基本情報画面へ戻る
              </Button>
              <Button
                disabled={!stageData.length || isAddItem || isSelectItem}
                className="button-form button-form-Submit"
                onClick={handleDesignTemplate}
              >
                <span></span>
                確認画面へ
              </Button>
            </div>
          ) : (
            <div className="h-[48px]"></div>
          )}
        </Form>
        <ModalConfirm
          isShowModal={isOpenModalAlertGoBack}
          onCancel={handleCloseModal}
          onOk={handleConfirmModal}
          content="中止確認"
          subContent={'作成を中止して、前の段階に戻りますか？'}
          isClose={false}
          isIcon={false}
          textOk="戻る"
        />
      </div>
    </RatioProvider>
  );
};

export default TemplateDesignCreateEditComponent;
