import { apiFormData } from 'src/api/axios';

export interface NotifyCreateType {
  title: string;
  content: string;
  image_thumbnail: File;
  status: number | string;
  highlight?: number | string;
}

export const useCreateNotify = (params: NotifyCreateType) => apiFormData.post(`/api/cms/notification`, params);
