const IconVerify = ({ className }: any) => (
  <svg className={className} width={25} height={24} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.28027 12.0001L11.6903 14.4201L16.5203 9.58008"
      stroke="#C0C0C0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6503 2.45031C12.3403 1.86031 13.4703 1.86031 14.1703 2.45031L15.7503 3.81031C16.0503 4.07031 16.6103 4.28031 17.0103 4.28031H18.7103C19.7703 4.28031 20.6403 5.15031 20.6403 6.21031V7.91031C20.6403 8.30031 20.8503 8.87031 21.1103 9.17031L22.4703 10.7503C23.0603 11.4403 23.0603 12.5703 22.4703 13.2703L21.1103 14.8503C20.8503 15.1503 20.6403 15.7103 20.6403 16.1103V17.8103C20.6403 18.8703 19.7703 19.7403 18.7103 19.7403H17.0103C16.6203 19.7403 16.0503 19.9503 15.7503 20.2103L14.1703 21.5703C13.4803 22.1603 12.3503 22.1603 11.6503 21.5703L10.0703 20.2103C9.77027 19.9503 9.21027 19.7403 8.81027 19.7403H7.08027C6.02027 19.7403 5.15027 18.8703 5.15027 17.8103V16.1003C5.15027 15.7103 4.94027 15.1503 4.69027 14.8503L3.34027 13.2603C2.76027 12.5703 2.76027 11.4503 3.34027 10.7603L4.69027 9.17031C4.94027 8.87031 5.15027 8.31031 5.15027 7.92031V6.20031C5.15027 5.14031 6.02027 4.27031 7.08027 4.27031H8.81027C9.20027 4.27031 9.77027 4.06031 10.0703 3.80031L11.6503 2.45031Z"
      stroke="#C0C0C0"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconVerify;
