import axios from 'axios';
import { getCookie } from 'src/utils/cookie';

export const baseURL = process.env.REACT_APP_API;
export const api = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const apiFormData = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

export const setHeaderAuthorization = (token: string) => {
  api.defaults.headers.common.Authorization = `Bearer ${token}`;
};

export const setToken = (token: string, id: string) => {
  setHeaderAuthorization(token);
};

export const clearToken = () => setToken('', '');

api.interceptors.request.use(
  (config) => {
    config.headers!.Authorization = `Bearer ${getCookie('token')}`;
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

apiFormData.interceptors.request.use(
  (config) => {
    config.headers!.Authorization = `Bearer ${getCookie('token')}`;
    return config;
  },
  (error) => Promise.reject(error)
);

apiFormData.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
