const IconEditImage = ({ className }: any) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={50} height={50} viewBox="0 0 50 50" fill="none">
    <path
      d="M27.6247 7.50004L10.5205 25.6042C9.87465 26.2917 9.24965 27.6459 9.12465 28.5834L8.35382 35.3334C8.08298 37.7709 9.83298 39.4375 12.2497 39.0209L18.958 37.875C19.8955 37.7084 21.208 37.0209 21.8538 36.3125L38.958 18.2084C41.9163 15.0834 43.2497 11.5209 38.6455 7.16671C34.0621 2.85421 30.583 4.37504 27.6247 7.50004Z"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.7705 10.5215C25.6663 16.2715 30.333 20.6673 36.1247 21.2506"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.25 45.834H43.75"
      stroke="black"
      strokeWidth="3"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconEditImage;
