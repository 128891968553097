import { api } from './axios';

interface TypeFormChangePass {
  new_password: string;
  old_password: string;
  new_password_confirmation: string;
}
interface TypeFormResetPass {
  token: string;
  password: string;
}

export const changePassword = (params: TypeFormChangePass) => api.post(`/api/cms/change-password`, params);

export const forgotPassword = (params: { email: string }) => api.post(`/api/cms/send-link-forgot-password`, params);

export const resetPassword = (params: TypeFormResetPass) => api.post(`/api/cms/reset-password`, params);

export const checkResetPasswordExpired = (params: { token: string }) => api.post(`/api/cms/check-token`, params);

export const editAvatar = (params: FormData) => api.post(`/api/cms/update-info-user`, params);
