const IconRevenue = ({ className }: any) => (
  <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.7308 10.517C17.896 11.1122 16.8745 11.4622 15.7712 11.4622C12.9522 11.4622 10.667 9.17703 10.667 6.35807C10.667 3.53912 12.9522 1.25391 15.7712 1.25391C17.5984 1.25391 19.2014 2.2141 20.1031 3.65745M7.75033 27.631H11.557C12.0533 27.631 12.5466 27.6901 13.0278 27.8082L17.05 28.7856C17.9227 28.9982 18.8319 29.0189 19.7137 28.8476L24.1609 27.9824C25.3357 27.7535 26.4164 27.191 27.2633 26.3671L30.4097 23.3064C31.3083 22.4338 31.3083 21.0179 30.4097 20.1438C29.6008 19.3568 28.3197 19.2682 27.4045 19.9356L23.7374 22.611C23.2123 22.9949 22.5733 23.2016 21.9161 23.2016H18.375L20.629 23.2015C21.8994 23.2015 22.9284 22.2004 22.9284 20.9646V20.5172C22.9284 19.4911 22.2105 18.5963 21.1875 18.3483L17.7087 17.5023C17.1425 17.365 16.5627 17.2956 15.9799 17.2956C14.5729 17.2956 12.026 18.4605 12.026 18.4605L7.75033 20.2485M28.167 7.81641C28.167 10.6354 25.8818 12.9206 23.0628 12.9206C20.2439 12.9206 17.9587 10.6354 17.9587 7.81641C17.9587 4.99745 20.2439 2.71224 23.0628 2.71224C25.8818 2.71224 28.167 4.99745 28.167 7.81641ZM1.91699 19.6289L1.91699 28.0872C1.91699 28.904 1.91699 29.3124 2.07594 29.6243C2.21576 29.8987 2.43885 30.1218 2.71326 30.2616C3.02521 30.4206 3.43358 30.4206 4.25032 30.4206H5.41699C6.23373 30.4206 6.64211 30.4206 6.95406 30.2616C7.22846 30.1218 7.45156 29.8987 7.59138 29.6243C7.75033 29.3124 7.75033 28.904 7.75033 28.0872V19.6289C7.75033 18.8122 7.75033 18.4038 7.59138 18.0918C7.45156 17.8174 7.22846 17.5943 6.95406 17.4545C6.64211 17.2956 6.23374 17.2956 5.41699 17.2956L4.25033 17.2956C3.43358 17.2956 3.02521 17.2956 2.71326 17.4545C2.43885 17.5943 2.21576 17.8174 2.07594 18.0918C1.91699 18.4038 1.91699 18.8122 1.91699 19.6289Z"
      stroke="#1C48E7"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconRevenue;
