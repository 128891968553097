export const transformerList = {
  image: {
    rotateEnabled: true,
    enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
  },
  line: {
    rotateEnabled: true,
    enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
  },
  default: {
    rotateEnabled: true,
    enabledAnchors: [
      'top-left',
      'top-center',
      'top-right',
      'middle-left',
      'middle-right',
      'bottom-left',
      'bottom-center',
      'bottom-right',
    ],
  },
};

export const FEATURE = {
  text: 'text',
  icon: 'icon',
  panel: 'panel',
  image: 'image',
  background: 'background',
  hole: 'hole',
  socket: 'socket',
};

export const ACTION = {
  erase: 'erase',
  brush: 'brush',
  changeWidth: 'changeWidth',
  enlargeReduce: 'enlargeReduce',
  standHole: 'standHole',
  socket: 'socket',
};

const RATIO = 1.5;
export const WIDTH_STAGE = 290;
export const HEIGHT_STAGE = WIDTH_STAGE * RATIO;

export const WIDTH_HOLE = 3;
export const WIDTH_SOCKET_SPEC = 17;
export const HEIGHT_SOCKET_SPEC = 5;

export const ACRYLIC_WIDTH_MIN = 50;
export const ACRYLIC_WIDTH_MAX = 250;

export const WIDTH_PANEL_STAMP_ITEM_LINE = 100;
export const WIDTH_PANEL_STAMP_ITEM = 120;

export const OUTLINE_PADDING_DEFAULT = 20;

export const FONT_SIZE_DEFAULT = 40;
export const FONT_SIZE_TEXT_RANGE_SLIDER = 30;
export const RANGE_SLIDER_STEP_SIZE_FONT_SIZE = 1;

export const PADDING_SELECT_SVG_OUTLINE = 10;

export const LIMIT_WIDTH_MIN = 400;
export const LIMIT_HEIGHT_MIN = 400;

// Const for range slider
export const SCALE_RANGE_SLIDER = 1;
export const RANGE_SLIDER_STEP_SIZE = 0.00001;

export const PANEL_RANGE_SLIDER = 70;

export const SIZE_TEXT_RANGE_SLIDER = 0.5;
export const OUTLINE_PADDING_TEXT_RANGE_SLIDER = 16;

export const SIZE_STAMP_RANGE_SLIDER = 5;

export enum TypeLangFontTextEditor {
  JA = 1,
  EN = 2,
}
