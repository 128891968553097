import { CartProvider } from 'src/components/common/Context';
import TemplateCreateEditComponent from 'src/components/features/superAdmin/templateCreateEditComponent';

const CreateEditTemplatePage = () => (
  <CartProvider>
    <TemplateCreateEditComponent />
  </CartProvider>
);

export default CreateEditTemplatePage;
