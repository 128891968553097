import { Button, Modal } from 'antd';
import closeIcon from 'src/assets/images/close-circle.png';
import confirmIcon from 'src/assets/images/confirm-circle.png';
interface ModalConfirmType {
  isShowModal: boolean;
  onOk: () => void;
  onCancel: () => void;
  content: string;
  loadingButtonOk?: boolean;
  subContent?: string;
  subContentPrefix?: string;
  subContentSuffix?: string;
  isIcon?: boolean;
  className?: string;
  width?: number;
  textOk?: string;
  isClose?: boolean;
}
const ModalConfirm = ({
  isShowModal,
  onOk,
  onCancel,
  content,
  loadingButtonOk,
  subContent,
  subContentPrefix = '',
  subContentSuffix = '',
  isIcon,
  className,
  width,
  textOk,
  isClose = true,
}: ModalConfirmType) => {
  const handleClickButtonOk = () => {
    onOk();
  };
  const handleClickButtonCancel = () => {
    onCancel();
  };

  const ContentModal = ({ text }: { text: string }) => {
    const characters = text.split('').map((char, index) => {
      const color =
        subContent?.includes(char) &&
        index > subContentPrefix?.length - 1 &&
        index < subContentPrefix?.length + subContent?.length
          ? '#1C48E7'
          : '#2E2E2E';
      return (
        <span key={index} style={{ color }}>
          {char}
        </span>
      );
    });
    return <div className="whitespace-pre-line text-center w-full">{characters}</div>;
  };

  return (
    <Modal
      title=""
      open={isShowModal}
      width={width ? width : 400}
      className={`delete-Modal ${className}`}
      footer={false}
      closable={false}
      centered={true}
    >
      {isClose && (
        <img src={closeIcon} alt="" className="close-modal cursor-pointer" onClick={handleClickButtonCancel} />
      )}
      {isIcon && <img src={confirmIcon} alt="" className="mx-auto mb-[16px]" />}
      <p className="text-center text-[20px] text-[#1C48E7] font-medium whitespace-pre-line mb-[8px]">{content}</p>
      <div className="justify-center mb-[44px] text-[#2E2E2E] whitespace-pre-line flex">
        {!!subContentPrefix || !!subContentSuffix ? (
          <ContentModal text={`${subContentPrefix || ''}${subContent || ''}${subContentSuffix || ''}`} />
        ) : (
          <p className={'text-[#2E2E2E]'}>{subContent}</p>
        )}
      </div>

      <div className="footer-form flex">
        <Button className="button-form mr-[16px]" onClick={handleClickButtonCancel}>
          キャンセル
        </Button>
        <Button loading={loadingButtonOk} className="button-form button-form-Submit" onClick={handleClickButtonOk}>
          <span></span>
          {textOk ? textOk : 'OK'}
        </Button>
      </div>
    </Modal>
  );
};

export default ModalConfirm;
