import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";

interface RulerProps {
  startValue: number;
  endValue: number;
  step: number;
  width: number;
  reverse?: boolean;
}

const Ruler: React.FC<RulerProps> = ({
  startValue,
  endValue,
  step,
  width,
  reverse,
}) => {
  const [steps, setSteps] = useState<number[]>([]);

  useEffect(() => {
    if (step <= 0) {
      return;
    }
    if (startValue > endValue) {
      return;
    }
    const newSteps = [];
    const newStep = Math.floor(step);
    for (let i = startValue; i <= endValue; i += newStep) {
      newSteps.push(i);
    }
    if (newSteps[newSteps.length - 1] !== endValue) {
      newSteps.push(endValue);
    }
    setSteps(newSteps);
  }, [startValue, endValue, step]);

  return (
    <div className={styles.ruler} style={{ width: width - 2 }}>
      {(reverse ? [...steps].reverse() : steps)?.map(
        (value: any, index: number) => (
          <div
            key={value + index}
            className={`${styles.rulerStep} ${value === startValue || value === endValue ? styles.rulerStepStartAndEnd : ""}`}
          >
            {value === startValue || value === endValue || index % 2 === 0 ? (
              <span className={styles.rulerValue}>{value}</span>
            ) : (
              ""
            )}
          </div>
        )
      )}
    </div>
  );
};

export default Ruler;
