import 'moment/locale/ja';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
  Chart as ChartJS,
  ChartOptions,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import { useEffect, useState } from 'react';
import { useDataChartRevenue } from 'src/api/useDataChartRevenue';
import { labels } from 'src/assets/constants';
import IconRevenue from 'src/assets/icon/IconRevenue';
import IconOrdersStatistic from 'src/assets/icon/IconOrdersStatistic';
import Icon from '@ant-design/icons';
import { DatePicker } from 'antd';
import iconSelect from 'src/assets/images/arrow-down-2.png';
import moment from 'moment';
import { formatPrice } from 'src/utils';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const RevenueChartComponent = () => {
  const history = useNavigate();
  const [yearFilter, setYearFilter] = useState<any>(dayjs()?.get('year'));
  const { data: dataChartRevenue, isLoading } = useDataChartRevenue(yearFilter);
  const [curData, setCurData] = useState<{
    totalRevenue: any;
    totalOrders: any;
    datasets: Array<any>;
    firstYear: any;
  }>({
    totalOrders: 0,
    totalRevenue: 0,
    datasets: [],
    firstYear: 0,
  });

  useEffect(() => {
    handleGenerateData(dataChartRevenue || []);
  }, [dataChartRevenue]);

  const handleGenerateData = (listRevenueStatistic: any) => {
    const listDataRevenue: any[] = [];
    const listDataOrder: any[] = [];
    (listRevenueStatistic?.monthly_data || [])?.forEach((item: any) => {
      listDataRevenue.push(Number(item.total_amount));
      listDataOrder.push(Number(item.total_orders));
    });
    setCurData({
      totalRevenue: dataChartRevenue?.total_year_amount,
      totalOrders: dataChartRevenue?.total_year_orders,
      firstYear: dataChartRevenue?.first_year,
      datasets: [
        {
          type: 'line' as const,
          label: '売上',
          yAxisID: 'revenue',
          data: listDataRevenue,
          borderColor: '#FF7A00',
          pointBackgroundColor: '#FF7A00',
          backgroundColor: 'transparent',
        },
        {
          type: 'bar' as const,
          label: '注文数',
          yAxisID: 'orders',
          data: listDataOrder,
          borderColor: '#6DC8EC',
          backgroundColor: '#6DC8EC',
        },
      ],
    });
  };

  const disabledYear = (date: any) => {
    return date && (dayjs(date)?.get('year') < curData?.firstYear || dayjs(date)?.get('year') > dayjs()?.get('year'));
  };

  const optionDefault: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        labels: {
          boxWidth: 10,
          boxHeight: 10,
          borderRadius: 5,
          useBorderRadius: true,
        },
      },
      tooltip: {
        backgroundColor: '#FFFFFF',
        displayColors: true,
        titleColor: '#333333',
        titleAlign: 'left',
        titleFont: {
          weight: 500 as any,
          size: 14,
        },
        bodyAlign: 'left',
        bodyColor: '#333333',
        bodyFont: {
          size: 14,
          weight: 400 as any,
        },
        cornerRadius: 8,
        position: 'average',
        yAlign: 'bottom',
        callbacks: {
          label(tooltipItems: any) {
            return `  ${tooltipItems.formattedValue} ${tooltipItems?.dataset?.type === 'bar' ? '(件)' : '(円)'}`;
          },
          title(tooltipItems: any) {
            return `${tooltipItems?.[0]?.label}/${yearFilter}`;
          },
        },
        borderColor: 'rgba(0, 0, 0, 0.15)',
        borderWidth: 1,
        padding: {
          left: 15,
          top: 10,
          right: 15,
          bottom: 10,
        },
      },
    },
    scales: {
      revenue: {
        title: { display: false, text: 'left' },
        grid: { color: curData?.totalRevenue && curData?.totalOrders ? '#C0C0C0' : 'transparent' },
        border: { dash: [6, 8] },
        min: 0,
        ticks: {
          font: {
            size: 14,
            weight: 400 as any,
          },
          padding: 15,
          color: '#8D8D8D',
        },
        position: 'left',
      },
      orders: {
        title: { display: false, text: 'right' },
        grid: { color: 'transparent' },
        border: { dash: [6, 8] },
        min: 0,
        ticks: {
          font: {
            size: 14,
            weight: 400 as any,
          },
          padding: 15,
          color: '#8D8D8D',
        },
        position: 'right',
      },
      x: {
        title: { display: false, text: 'month' },
        grid: { color: curData?.totalRevenue && curData?.totalOrders ? '#d9d9d9' : 'transparent' },
        border: { dash: [6, 8] },
        ticks: {
          callback(tickValue, index, ticks) {
            return `${Number(tickValue) + 1}/${yearFilter}`;
          },
          font: {
            size: 12,
            weight: 400 as any,
            family: 'Noto Sans JP',
          },
          padding: 15,
          color: '#8D8D8D',
        },
      },
    },
  };

  return (
    <div className="page-content company-page">
      <div className="container-chart-revenue">
        <div className="header-chart">
          <div>売上情報</div>
          <DatePicker
            className="date-picker-custom w-[130px] h-[40px]"
            suffixIcon={<Icon component={() => <img src={iconSelect} alt="iconSelect" />} />}
            inputReadOnly
            picker="year"
            format={'YYYY年'}
            defaultValue={dayjs() as any}
            onChange={(value: any) => {
              setYearFilter(dayjs(value).format('YYYY'));
            }}
            allowClear={false}
            disabledDate={disabledYear}
          />
        </div>
        <div className="wrap-info-statistic">
          <div className="info-statistic">
            <div>
              <div className="title">総売上</div>
              <div className="statistic">
                {curData?.totalRevenue ? `${formatPrice(curData?.totalRevenue || 0)}円` : 'ー'}
              </div>
            </div>
            <IconRevenue />
          </div>
          <div className="line" />
          <div className="info-statistic">
            <div>
              <div className="title">注文数</div>
              <div className="statistic">{curData?.totalOrders ? `${curData?.totalOrders}件` : 'ー'}</div>
            </div>
            <IconOrdersStatistic />
          </div>
        </div>
        <div className="title-yAxis">
          <div className="amount">売上（円）</div>
          <div className="orders">注文数（件）</div>
        </div>
        <div className="wrap-chart">
          <div className="chart-revenue" style={{ height: '523px', width: '100%' }}>
            {!curData?.totalRevenue && !curData?.totalOrders ? (
              <div className="title-no-data">データがありません</div>
            ) : (
              <div />
            )}
            <Chart type="line" options={optionDefault} data={{ labels, datasets: curData?.datasets }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevenueChartComponent;
