export const convertStageDataToShapeData = (stageData: any) => {
  let output: any = [];

  stageData.map((shapeInfo: any) => {
    const shapesWithId = shapeInfo?.attrs?.shapeData?.map((shape: any) => ({
      ...shape,
      id: shapeInfo.id,
    }));
    output = shapesWithId ? [...output, ...shapesWithId] : [...output];
  });

  return output;
};

const hexToRGB = (hexStr: any) => {
  var col: any = {};
  col.r = parseInt(hexStr.substr(1, 2), 16);
  col.g = parseInt(hexStr.substr(3, 2), 16);
  col.b = parseInt(hexStr.substr(5, 2), 16);
  return col;
};

export const changeColInUri = ({ base64, colfrom, colto, callBack }: any) => {
  // create fake image to calculate height / width
  var img = new Image();
  let output = base64;

  img.onload = function () {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;

    var ctx: any = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    // do actual color replacement
    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    var data = imageData.data;

    if (!colfrom) {
      var rgbto = hexToRGB(colto);
      var r, g, b;
      for (var x = 0, len = data.length; x < len; x += 4) {
        r = data[x];
        g = data[x + 1];
        b = data[x + 2];

        data[x] = rgbto.r;
        data[x + 1] = rgbto.g;
        data[x + 2] = rgbto.b;
      }
    } else {
      var rgbfrom = hexToRGB(colfrom);
      var rgbto = hexToRGB(colto);

      var r, g, b;
      for (var x = 0, len = data.length; x < len; x += 4) {
        r = data[x];
        g = data[x + 1];
        b = data[x + 2];

        if (r == rgbfrom.r && g == rgbfrom.g && b == rgbfrom.b) {
          data[x] = rgbto.r;
          data[x + 1] = rgbto.g;
          data[x + 2] = rgbto.b;
        }
      }
    }

    ctx.putImageData(imageData, 0, 0);

    output = canvas.toDataURL();

    if (typeof callBack == 'function') {
      callBack(output);
    }

    return output;
  };
  img.src = base64;

  return output;
};
