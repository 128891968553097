import { apiFormData } from 'src/api/axios';

export interface PartEditType {
  name: string;
  parts?: Array<any>;
  part_category_id: number;
  delete_ids: Array<number | string>;
}

export const useEditPart = (params?: PartEditType, id?: number | string) =>
  apiFormData.post(`/api/cms/part/${id}`, params);
