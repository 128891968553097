import { useQuery } from 'react-query';
import { api } from 'src/api/axios';

export const useListAcrylicPlate = (keyword: string, page: number, size: number) =>
  useQuery(['listAcrylicPlate', keyword, page, size], async () => {
    const res = await api.get('/api/cms/acrylic-plate', {
      params: {
        keyword: keyword,
        page: page,
        size: size,
      },
    });
    return res.data.data;
  });
