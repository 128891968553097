import { useQuery } from 'react-query';
import { api } from 'src/api/axios';

export const useDetailUser = (id: number) =>
  useQuery(
    ['detailUser', id],
    async () => {
      if (id) {
        const res = await api.get(`/api/cms/user/${id}`);
        return res.data.data;
      }
      return;
    },
    {
      retry: 0,
    }
  );
