import React from 'react';

const RectangleSvg = ({ shapeInfo, outline, drawOutline, handleClick, index }: any) => {
  if (drawOutline) {
    return (
      <rect
        id={index}
        // id="21"
        onClick={handleClick}
        width={shapeInfo?.width + 10}
        height={shapeInfo?.height + 10}
        x={shapeInfo?.x - 5}
        y={shapeInfo?.y - 5}
        transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
        stroke="black"
        strokeWidth={1}
        fill="black"
      />
    );
  } else {
    return (
      <g
        id={index}
        onClick={handleClick}
        transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
      >
        <rect
          width={shapeInfo?.width - 10}
          height={shapeInfo?.height - 10}
          x={shapeInfo?.x + 5}
          y={shapeInfo?.y + 5}
          fill="none"
        />
        <image
          x={shapeInfo?.x + (shapeInfo?.outlinePadding ?? 0) - 15}
          y={shapeInfo?.y + (shapeInfo?.outlinePadding ?? 0) - 15}
          width={shapeInfo?.width + 30}
          height={shapeInfo?.height + 30}
          preserveAspectRatio="none"
        />
      </g>
    );
  }
};

export default RectangleSvg;
