import { Button, Form, Modal, Spin, Table, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import type { ColumnsType } from 'antd/es/table';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import 'src/components/features/superAdmin/form.modules.scss';
import 'src/components/features/superAdmin/superAdmin.modules.scss';
import moment from 'moment';
import { numberWithCommas } from 'src/utils/price';
import { useDetailOrder } from 'src/api/useDetailOrder';
import Progress from 'src/components/common/progress';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import {
  ORDER_STATUS,
  ORDER_STATUS_CANCEL,
  ORDER_STATUS_PROCESSING,
  ORDER_STATUS_SUCCESS,
  VAT_PAYMENT_CART,
} from 'src/assets/constants';
import { useMutation, useQueryClient } from 'react-query';
import { EditStatusType, useChangeOrderStatus } from 'src/api/useChangeOrderStatus';
import TextArea from 'antd/lib/input/TextArea';
import { CancelOrderType, useCancelOrder } from 'src/api/useCancelOrder';
import Loading from 'src/components/common/loading';
import IconClose from 'src/assets/icon/IconClose';
import { decodeUrl } from 'src/utils/decodeUrl';
import ReactImageFallback from 'react-image-fallback';
import noImage from 'src/assets/images/no-image.png';
import IconBlackDownload from 'src/assets/icon/IconBlackDownload';
import { saveAs } from 'file-saver';
import IconWhiteDownload from 'src/assets/icon/IconWhiteDownload';
import { formatZipCode } from 'src/utils/formatZipCode';
import { baseURL } from 'src/api/axios';
import { getCookie } from 'src/utils/cookie';
import NotFoundPage from 'src/pages/notFoundPage';
import { getTotalPriceCartNotFee, handleDownload } from 'src/utils';

interface DataType {
  key: string;
  name: string;
  location: string;
  updated_at: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: '商品名',
    key: 'title',
    dataIndex: 'title',
    width: 320,
  },
  {
    title: '量',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 50,
  },
  {
    title: '単価',
    dataIndex: 'price',
    key: 'price',
    width: 320,
    align: 'right',
  },
  {
    title: '配送料',
    dataIndex: 'shipping_fee',
    key: 'shipping_fee',
    width: 320,
    align: 'right',
  },
  {
    title: '金額',
    key: 'total',
    dataIndex: 'total',
    width: 320,
    align: 'right',
  },
];

const detailOrderInfo = [
  {
    label: '氏名：',
    name: 'username',
    id: 1,
  },
  {
    label: '電話番号：',
    name: 'phone',
    id: 2,
  },
  {
    label: '受取住所：',
    name: 'address',
    id: 3,
  },
];

const OrderDetailComponent = () => {
  const history = useNavigate();
  const [antForm] = Form.useForm();
  const { id } = useParams<{ id: string }>();
  const searchParams = new URLSearchParams(window.location.search);
  const isFromRevenue = searchParams.get('isFromRevenue');
  const { data: detailData, isLoading: isLoadingDetail, refetch, isError } = useDetailOrder(Number(id));
  const { isLoading: isLoadingEdit, mutate: onEditStatus } = useMutation(
    (param: EditStatusType) => useChangeOrderStatus(param, Number(id)),
    {
      onSuccess: () => {
        message.success('ステータスの更新は完了しました');
        refetch();
      },
      onError: () => {
        message.error('ステータスの更新は失敗しました。再度確認してください');
      },
    }
  );
  const { isLoading: isLoadingCancel, mutate: onCancelOrder } = useMutation(
    (param: CancelOrderType) => useCancelOrder(param, Number(id)),
    {
      onSuccess: () => {
        message.success('注文キャンセルは完了しました');
        refetch();
        handleCloseModalCancelOrder();
      },
      onError: () => {
        message.error('注文キャンセルは失敗しました。再度確認してください');
      },
    }
  );

  const [isOpenModalUpdateStatus, setIsOpenModalUpdateStatus] = useState<boolean>(false);
  const [isOpenModalCancelOrder, setIsOpenModalCancelOrder] = useState<boolean>(false);
  const [status, setStatus] = useState<string>('');
  const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(true);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);

  const handleGoBack = () => {
    if (isFromRevenue) {
      history(-1);
    } else {
      history('/order');
    }
  };

  const handleCloseModalUpdateStatus = () => {
    setIsOpenModalUpdateStatus(false);
  };

  const handleOpenModalUpdateStatus = () => {
    setIsOpenModalUpdateStatus(true);
  };

  const handleCloseModalCancelOrder = () => {
    setIsOpenModalCancelOrder(false);
  };

  const handleOpenModalCancelOrders = () => {
    setIsOpenModalCancelOrder(true);
  };

  const handleUpdateStatus = () => {
    if (id) {
      onEditStatus({ status: Number(detailData?.status + 1) });
    }
    handleCloseModalUpdateStatus();
  };

  const trimmedValue = async () => {
    const allValueForm = antForm.getFieldsValue();
    Object.keys(allValueForm).forEach((key: any) => {
      if (typeof allValueForm[key] === typeof '') {
        antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() });
      }
    });
  };

  const handleCancelOrder = async () => {
    await trimmedValue();
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    const valueForm = antForm.getFieldsValue();
    const params = {
      note: valueForm.note,
    };
    if (id) {
      onCancelOrder(params);
    }
  };

  const renderInfoUser = (info: string) => {
    if (info === 'address') {
      return detailData?.post_code
        ? `〒${formatZipCode(detailData?.post_code)} ${detailData?.[info] ? detailData?.[info] : ''}`
        : `${detailData?.[info] ? detailData?.[info] : ''}`;
    }
    if (info === 'phone') {
      return detailData?.phone_1 ? `${detailData?.phone_1}-${detailData?.phone_2}-${detailData?.phone_3}` : '';
    }
    return detailData?.[info];
  };

  const handleShowMore = () => {
    setIsShowMore((prev) => !prev);
  };

  const handleDownloadAll = async () => {
    fetch(`${baseURL}/api/cms/order/download-images/${id}`, {
      method: 'get',
      headers: new Headers({
        Authorization: `Bearer ${getCookie('token')}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.blob())
      .then((blob) => saveAs(blob, 'File.zip'))
      .catch((err) => {
        console.log(err.message);
      });
  };

  const dataSource = useMemo(() => {
    if (detailData) {
      return detailData?.order_details?.map((order: any) => ({
        ...order,
        key: order?.id,
        title: <span className="line-1 break-all">{`${order?.title}[${order?.size}サイズ]`}</span>,
        quantity: <span className="line-1 break-all">{order?.quantity}</span>,
        price: <span className="line-1 break-all">{numberWithCommas(String(order?.price))} 円</span>,
        shipping_fee: <span className="line-1 break-all">{numberWithCommas(String(order?.shipping_fee))} 円</span>,
        total: (
          <span className="line-1 break-all">
            {numberWithCommas(String(order?.price * order?.quantity + order?.shipping_fee * order?.quantity))} 円
          </span>
        ),
      }));
    }
    return [];
  }, [detailData]);

  const dataThumbnailOrder = useMemo(() => {
    if (detailData) {
      return detailData?.order_details?.map((order: any) => (
        <li key={order?.id} className="flex justify-between items-center gap-[24px] item-detail-thumbnail">
          <div className="flex gap-[24px]">
            <div className="order-product-thumbnail">
              <ReactImageFallback
                src={decodeUrl(order.image_url)}
                fallbackImage={noImage}
                initialImage={<Spin />}
                alt="order-image"
                className="order-product-image"
              />
            </div>
            <div className="order-product-info">
              <div className="order-product-title whitespace-pre-line">{order?.title}</div>
              <div className="order-product-size">
                Size: W{order?.width}mm x H{order?.height}mm
              </div>
            </div>
          </div>

          <Button onClick={() => handleDownload(order.image_pdf_url, order.image_pdf)} className="order-button-download">
            <IconBlackDownload />
          </Button>
        </li>
      ));
    }
    return [];
  }, [detailData]);

  const totalPrice = useMemo(() => {
    if (detailData) {
      return String(getTotalPriceCartNotFee(detailData?.order_details));
    }
    return '0';
  }, [detailData]);

  const totalShippingFee = useMemo(() => {
    if (detailData) {
      return String(detailData?.total_shipping_fee);
    }
    return '0';
  }, [detailData]);

  const taxFee = useMemo(() => {
    if (detailData) {
      return String(Math.round((Number(totalShippingFee) + Number(totalPrice)) * VAT_PAYMENT_CART));
    }
    return '0';
  }, [detailData]);

  useEffect(() => {
    const currentStatus = ORDER_STATUS.find((status) => status.value === Number(detailData?.status + 1));
    if (!currentStatus) return;
    setStatus(currentStatus.label);
  }, [detailData]);

  useEffect(() => {
    if (id) {
      setIsDisableSubmit(false);
    }
  }, [id]);

  if ((!detailData || Object.keys(detailData).length === 0 || isError) && !isLoadingDetail && id) {
    return <NotFoundPage />;
  }

  return (
    <>
      {isLoadingDetail ? (
        <Loading />
      ) : (
        <div className="page-content company-page">
          <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
            <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
              <div className="mr-[17px]">
                <IconArrowLeft />
              </div>
              <span className="line-1">注文詳細</span>
            </div>
            {Number(detailData?.status) !== ORDER_STATUS_CANCEL &&
              Number(detailData?.status) !== ORDER_STATUS_SUCCESS && (
                <div className="flex">
                  {Number(detailData?.status) !== ORDER_STATUS_PROCESSING && (
                    <Button className="button-form min-w-[173px] min-h-[48px]" onClick={handleOpenModalCancelOrders}>
                      注文キャンセル
                    </Button>
                  )}

                  <Button
                    className="button-add min-w-[173px] min-h-[48px] ml-[8px]"
                    onClick={handleOpenModalUpdateStatus}
                  >
                    <span></span>
                    {Number(detailData?.status) === ORDER_STATUS_PROCESSING ? '配送済に変更' : '処理中に変更'}
                  </Button>
                </div>
              )}
          </div>
          <div className="license-detail mb-[24px]">
            <div className="license-detail-title">注文情報</div>
            <div className="license-detail-content block">
              {detailOrderInfo.map((item: any) => (
                <div className="mb-[8px] flex" key={item.id}>
                  <label className="labe min-w-[277px]">{item.label}</label>
                  <div className="text-[16px] text-[#000000] break-all px-[10px]">{renderInfoUser(item.name)}</div>
                </div>
              ))}
            </div>
            {detailData?.note && (
              <div className="order-detail-note">
                この注文はキャンセルしました。キャンセル理由は「{detailData?.note}」です。
              </div>
            )}
          </div>

          <div className="license-detail mb-[24px]">
            <div className="license-detail-title">注文内容 </div>
            <div className="license-detail-content block">
              <Table
                columns={columns}
                dataSource={dataSource}
                pagination={false}
                loading={isLoadingDetail}
                locale={{
                  emptyText: <TableNoteNoData />,
                }}
              />
              <div className="license-detail-content block">
                <div className="mb-[8px] flex w-6/12 ml-auto text-right">
                  <label className="labe pl-[16px] pr-[0] py-[12px]" style={{ minWidth: '50%' }}>
                    小計
                  </label>
                  <div className="text-[16px] text-[#000000] break-all w-detail-content px-[16px] py-[12px]">
                    {numberWithCommas(totalPrice)} 円
                  </div>
                </div>
                <div className="mb-[8px] flex w-6/12 ml-auto text-right">
                  <label className="labe pl-[16px] pr-[0] py-[12px]" style={{ minWidth: '50%' }}>
                    配送料
                  </label>
                  <div className="text-[16px] text-[#000000] break-all w-detail-content px-[16px] py-[12px]">
                    {numberWithCommas(totalShippingFee)} 円
                  </div>
                </div>
                <div className="mb-[8px] flex w-6/12 ml-auto text-right">
                  <label className="labe pl-[16px] pr-[0] py-[12px]" style={{ minWidth: '50%' }}>
                    消費税 (10%)
                  </label>
                  <div className="text-[16px] text-[#000000] break-all w-detail-content px-[16px] py-[12px]">
                    {numberWithCommas(taxFee)} 円
                  </div>
                </div>
                <div className="mb-[8px] flex w-6/12 ml-auto text-right">
                  <label className="labe pl-[16px] pr-[0] py-[12px]" style={{ minWidth: '50%' }}>
                    合計
                  </label>
                  <div className="text-[16px] text-[#000000] break-all w-detail-content px-[16px] py-[12px]">
                    {numberWithCommas(Number(totalPrice) + Number(totalShippingFee) + Number(taxFee))} 円
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="license-detail mb-[24px]">
            <div className="flex items-center justify-between license-detail-title">
              <div className="title-order-thumbnail">画像情報</div>

              <Button className="button-add min-w-[176px] min-h-[48px] ml-[8px]" onClick={handleDownloadAll}>
                <IconWhiteDownload className="mr-[10px]" />
                全てダウンロード
              </Button>
            </div>

            <div className="wrap-order-thumbnail">
              <ul className={`order-detail-thumbnail ${isShowMore ? 'full-detail-thumbnail' : ''}`}>
                {dataThumbnailOrder}
              </ul>
              {detailData?.order_details?.length > 3 && (
                <div className="flex items-center justify-center">
                  <Button className="order-detail-more" onClick={handleShowMore}>
                    {isShowMore ? '閉じる' : 'もっと見る'}
                  </Button>
                </div>
              )}
            </div>
          </div>

          <div className="license-detail mb-[24px]">
            <div className="license-detail-title">注文ステータス</div>
            <div className="license-detail-content block">
              <div className="mb-[8px] flex">
                <label className="labe min-w-[277px]">注文番号</label>
                <div className="text-[16px] text-[#000000] break-all px-[10px]">{detailData?.order_code}</div>
              </div>
              <div className="mb-[8px] flex">
                <label className="labe min-w-[277px]">注文日時</label>
                <div className="text-[16px] text-[#000000] break-all px-[10px]">
                  {detailData?.created_at ? moment(detailData?.created_at).utc().format('YYYY/MM/DD') : ''}
                </div>
              </div>
              {isLoadingEdit ? (
                <Spin />
              ) : (
                <div className="mb-[8px] block">
                  <label className="labe min-w-[277px]">ステータス</label>
                  <div className="text-[16px] text-[#000000] break-all px-[10px] mt-[24px]">
                    <Progress
                      status={detailData?.status}
                      cancel={Number(detailData?.status) === ORDER_STATUS_CANCEL}
                      time_accepted={
                        detailData?.time_accepted ? moment(detailData?.time_accepted).utc().format('YYYY/MM/DD') : ''
                      }
                      time_processing={
                        detailData?.time_processing
                          ? moment(detailData?.time_processing).utc().format('YYYY/MM/DD')
                          : ''
                      }
                      time_delivered={
                        detailData?.time_delivered ? moment(detailData?.time_delivered).utc().format('YYYY/MM/DD') : ''
                      }
                      time_cancel={
                        detailData?.time_cancel ? moment(detailData?.time_cancel).utc().format('YYYY/MM/DD') : ''
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          <ModalConfirm
            isShowModal={isOpenModalUpdateStatus}
            onCancel={handleCloseModalUpdateStatus}
            onOk={handleUpdateStatus}
            content="ステータス更新確認"
            subContentPrefix={`注文ステータスを「${status}」に更新してもよろし\nいですか?`}
            width={400}
            isClose={false}
            textOk="更新"
          />

          <Modal
            centered
            className="modal-category"
            title="注文キャンセル"
            width={590}
            open={isOpenModalCancelOrder}
            onCancel={handleCloseModalCancelOrder}
            footer={false}
            maskClosable={false}
            okText="注文キャンセル"
            cancelText="キャンセル"
            closeIcon={<IconClose />}
          >
            <Form form={antForm} className="form" layout="vertical" autoComplete="off">
              <div className="form_item">
                <Form.Item
                  name="note"
                  label="理由"
                  required
                  rules={[
                    {
                      validator: (rule, value, callback, length: number = 10000) => {
                        if (!value?.trim()) {
                          callback('注文キャンセル理由を入力してください');
                        } else if (length && value?.trim()?.length > length) {
                          callback('回答内容は10000文字以内で入力してください');
                        } else {
                          callback();
                        }
                      },
                    },
                  ]}
                >
                  <TextArea
                    placeholder="注文キャンセル理由を入力してください"
                    className="input-form input_form_textarea"
                  />
                </Form.Item>
              </div>

              <div className="footer-form flex">
                <Button className="button-form mr-[16px]" onClick={handleCloseModalCancelOrder}>
                  キャンセル
                </Button>
                <Button
                  loading={isLoadingCancel}
                  disabled={isDisableSubmit}
                  className="button-form button-form-Submit"
                  onClick={handleCancelOrder}
                >
                  <span></span>確認
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      )}
    </>
  );
};

export default OrderDetailComponent;
