const IconEditorText = ({ className }: any) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M30.0312 8.15625V12.5312C30.0312 12.8213 29.916 13.0995 29.7109 13.3046C29.5058 13.5098 29.2276 13.625 28.9375 13.625C28.6474 13.625 28.3692 13.5098 28.1641 13.3046C27.959 13.0995 27.8438 12.8213 27.8438 12.5312V9.25H19.0938V26.75H22.375C22.6651 26.75 22.9433 26.8652 23.1484 27.0704C23.3535 27.2755 23.4688 27.5537 23.4688 27.8438C23.4688 28.1338 23.3535 28.412 23.1484 28.6171C22.9433 28.8223 22.6651 28.9375 22.375 28.9375H13.625C13.3349 28.9375 13.0567 28.8223 12.8516 28.6171C12.6465 28.412 12.5312 28.1338 12.5312 27.8438C12.5312 27.5537 12.6465 27.2755 12.8516 27.0704C13.0567 26.8652 13.3349 26.75 13.625 26.75H16.9062V9.25H8.15625V12.5312C8.15625 12.8213 8.04102 13.0995 7.8359 13.3046C7.63078 13.5098 7.35258 13.625 7.0625 13.625C6.77242 13.625 6.49422 13.5098 6.2891 13.3046C6.08398 13.0995 5.96875 12.8213 5.96875 12.5312V8.15625C5.96875 7.86617 6.08398 7.58797 6.2891 7.38285C6.49422 7.17773 6.77242 7.0625 7.0625 7.0625H28.9375C29.2276 7.0625 29.5058 7.17773 29.7109 7.38285C29.916 7.58797 30.0312 7.86617 30.0312 8.15625Z"
      fill="black"
    />
  </svg>
);

export default IconEditorText;
