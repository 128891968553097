import { useEffect, useState } from 'react';
import styles from 'src/components/features/superAdmin/EditorComponent/FinalStep/index.module.scss';
import stylesPanel from '../index.module.scss';
import {
  HEIGHT_STAGE,
  OUTLINE_PADDING_DEFAULT,
  PANEL_RANGE_SLIDER,
  RANGE_SLIDER_STEP_SIZE,
  WIDTH_PANEL_STAMP_ITEM_LINE,
  WIDTH_STAGE,
} from 'src/assets/dataUI';
import { nanoid } from 'nanoid';
import { calculateSvgSize, revertCalculationBeforeTransform } from '../../SvgMerge/utils';
import useItem from 'src/hook/useItem';
import SvgMerge from '../../SvgMerge';
import MenuBottom from 'src/components/common/menuBottom';
import { Button, Slider } from 'antd';
import { MenuStep2AddPanel } from '../../SvgMerge/menu';
import { useRatioContext } from 'src/hook/useChangeRatio';

const Step2AddPanel = ({ svgContent, handleSetFeature, setStep, defaultStep, editMode, baseSvgContent }: any) => {
  const { createItem, updateItem } = useItem();
  const [width, setWidth] = useState<any>(svgContent?.width ?? WIDTH_PANEL_STAMP_ITEM_LINE);
  const [shapeSize, setShapeSize] = useState<any>();
  const [isShowSlider, setIsShowSlider] = useState(false);
  const {stateRatio} = useRatioContext()

  useEffect(() => {
    if (editMode) {
      setWidth(svgContent?.width ?? WIDTH_PANEL_STAMP_ITEM_LINE);
    }
  }, [svgContent]);

  useEffect(() => {
    //get shape size
    let shapeSize = calculateSvgSize({
      idToCheck: 'svg_outline_shape',
      baseShapeInfo: svgContent,
      isText: false,
    });
    //this is the shape that we get after scale
    //now we will need to recover it back to none transform
    shapeSize = revertCalculationBeforeTransform({
      transform: {
        x: (stateRatio?.ratioWidth - width) / 2,
        y: (stateRatio?.ratioHeight - width) / 2,
      },
      scale: {
        base: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
      },
      rotate: {
        a: 0,
        x: Number(svgContent?.attributes?.viewBox?.split(' ')[2]) / 2,
        y: Number(svgContent?.attributes?.viewBox?.split(' ')[3]) / 2,
        baseScale: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
      },
      outputSvgSize: shapeSize,
    });
    setShapeSize(shapeSize);
  }, [width, svgContent]);

  const onSubmitFinal = () => {
    const newAttrs = {
      zIndex: 1,
      shapeData: [
        {
          cate: 'panel',
          type: 8,
          ...svgContent,
          outlinePadding: 12 / (width / Number(svgContent?.attributes?.viewBox?.split(' ')[2])),
          transform: editMode
            ? baseSvgContent?.attrs?.shapeData[0].transform
            : {
                x: (stateRatio?.ratioWidth - width) / 2,
                y: (stateRatio?.ratioHeight - width) / 2,
              },
          scale: {
            base: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
          },
          width: width,
          height: width,
          rotate: {
            a: editMode ? baseSvgContent?.attrs?.shapeData[0]?.rotate?.a : 0,
            x: Number(svgContent?.attributes?.viewBox?.split(' ')[2]) / 2,
            y: Number(svgContent?.attributes?.viewBox?.split(' ')[3]) / 2,
            baseScale: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
          },
          shapeSize: shapeSize,
          strokeLinejoin: false,
        },
      ],
    };

    if (editMode) {
      updateItem(baseSvgContent?.id, (prevData) => ({
        ...newAttrs,
        zIndex: baseSvgContent?.attrs?.zIndex,
      }));
    } else {
      createItem({
        id: nanoid(),
        attrs: newAttrs,
      });
    }
    handleSetFeature();
  };

  return (
    <>
      {editMode && <div className="flex justify-center mb-3 text-[24px] text-[#515c6f]">カットライン調整</div>}

      <div className={styles.editor}>
        <div
          className={`${styles.stage} !mb-3`}
          style={{
            width: stateRatio?.ratioWidth,
            height: stateRatio?.ratioHeight,
          }}
        >
          {svgContent && (
            <SvgMerge
              shapeData={[
                {
                  type: 8,
                  ...svgContent,
                  cate: 'panel',
                  outlinePadding: 12 / (width / Number(svgContent?.attributes?.viewBox?.split(' ')[2])),
                  transform: {
                    x: (stateRatio?.ratioWidth - width) / 2,
                    y: (stateRatio?.ratioHeight - width) / 2,
                  },
                  scale: {
                    base: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
                  },
                  width: width,
                  height: width,
                  rotate: {
                    a: 0,
                    x: Number(svgContent?.attributes?.viewBox?.split(' ')[2]) / 2,
                    y: Number(svgContent?.attributes?.viewBox?.split(' ')[3]) / 2,
                    baseScale: width / Number(svgContent?.attributes?.viewBox?.split(' ')[2]),
                  },
                  shapeSize: shapeSize,
                  strokeLinejoin: false,
                },
              ]}
              outline={{
                showOutline: true,
              }}
            />
          )}
        </div>
      </div>

      {isShowSlider && (
        <div className="my-react-add-text flex justify-center mb-2">
          <div className="w-[500px]">
            <div className={stylesPanel.content}>
              <p className={stylesPanel.scaleTitle}>拡大・縮小</p>

              <div className={stylesPanel.scale}>
                <Slider
                  tooltip={{ formatter: null }}
                  defaultValue={width}
                  min={WIDTH_PANEL_STAMP_ITEM_LINE - PANEL_RANGE_SLIDER}
                  max={WIDTH_PANEL_STAMP_ITEM_LINE + PANEL_RANGE_SLIDER}
                  step={RANGE_SLIDER_STEP_SIZE}
                  onChange={(e) => {
                    setWidth(Number(e));
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      <MenuBottom
        list={MenuStep2AddPanel({
          onClickEnlarge: () => setIsShowSlider(true),
        })}
      />

      <div className="flex justify-center gap-2 mt-3">
        <Button
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            if (editMode) {
              handleSetFeature();
            } else {
              setStep((prev: number) => prev - 1);
            }
          }}
        >
          {'<<前のステップへ'}
        </Button>
        <Button className="button-form button-form-Submit !w-auto" onClick={() => onSubmitFinal()}>
          OK
        </Button>
      </div>
    </>
  );
};

export default Step2AddPanel;
