import './modalPreviewFile.modules.scss';
import { Modal } from 'antd';

type ProviderProps = {
  isShowModal: boolean;
  className?: string;
  width?: number;
  isClose?: boolean;
  onCancel: () => void;
  svgImage?: string;
};
const ModalPreviewFile = ({
  isShowModal,
  className,
  width,
  isClose = true,
  onCancel,
  svgImage
}: ProviderProps) => {
  return (
    <Modal
      title=""
      open={isShowModal}
      width={width || 400}
      className={`modal-preview ${className}`}
      closable={false}
      footer={null}
      destroyOnClose={true}
      centered
      onCancel={onCancel}
    >
      {isClose && (
        <div className="cover-image-template">
          {svgImage && (
            <div
              dangerouslySetInnerHTML={{ __html: svgImage }}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default ModalPreviewFile;
