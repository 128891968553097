const IconDelete = ({ className }: any) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={15} height={15} viewBox="0 0 15 15" fill="none">
    <path
      d="M14.5 7.5C14.5 11.366 11.366 14.5 7.5 14.5C3.63401 14.5 0.5 11.366 0.5 7.5C0.5 3.63401 3.63401 0.5 7.5 0.5C11.366 0.5 14.5 3.63401 14.5 7.5Z"
      fill="#818181"
      stroke="#FFF8F8"
    />
    <mask id="path-2-inside-1_6387_12559" fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 14.375C11.3341 14.375 14.4 11.2732 14.4 7.5C14.4 3.72684 11.3341 0.625 7.5 0.625C3.66592 0.625 0.6 3.72684 0.6 7.5C0.6 11.2732 3.66592 14.375 7.5 14.375ZM7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 14.375C11.3341 14.375 14.4 11.2732 14.4 7.5C14.4 3.72684 11.3341 0.625 7.5 0.625C3.66592 0.625 0.6 3.72684 0.6 7.5C0.6 11.2732 3.66592 14.375 7.5 14.375ZM7.5 15C11.6421 15 15 11.6421 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15Z"
      fill="black"
    />
    <path
      d="M13.4 7.5C13.4 10.7219 10.7808 13.375 7.5 13.375V15.375C11.8874 15.375 15.4 11.8244 15.4 7.5H13.4ZM7.5 1.625C10.7808 1.625 13.4 4.27809 13.4 7.5H15.4C15.4 3.17559 11.8874 -0.375 7.5 -0.375V1.625ZM1.6 7.5C1.6 4.27809 4.21923 1.625 7.5 1.625V-0.375C3.1126 -0.375 -0.4 3.17559 -0.4 7.5H1.6ZM7.5 13.375C4.21923 13.375 1.6 10.7219 1.6 7.5H-0.4C-0.4 11.8244 3.1126 15.375 7.5 15.375V13.375ZM14 7.5C14 11.0899 11.0899 14 7.5 14V16C12.1944 16 16 12.1944 16 7.5H14ZM7.5 1C11.0899 1 14 3.91015 14 7.5H16C16 2.80558 12.1944 -1 7.5 -1V1ZM1 7.5C1 3.91015 3.91015 1 7.5 1V-1C2.80558 -1 -1 2.80558 -1 7.5H1ZM7.5 14C3.91015 14 1 11.0899 1 7.5H-1C-1 12.1944 2.80558 16 7.5 16V14Z"
      fill="#FFF8F8"
      mask="url(#path-2-inside-1_6387_12559)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2848 11.1292L4.41287 3.97097L4.625 3.75L11.4969 10.9082L11.2848 11.1292Z"
      fill="white"
      stroke="#FFF8F8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.72054 10.8754L11.0931 3.89377L11.2949 4.125L3.92234 11.1066L3.72054 10.8754Z"
      fill="white"
      stroke="#FFF8F8"
    />
  </svg>
);

export default IconDelete;
