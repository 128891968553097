import 'moment/locale/ja';
import { Button, Input, Pagination, Select, Spin, Table } from 'antd';
import { useMemo, useState } from 'react';
import searchIcon from 'src/assets/images/search-icon.png';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { pageSizeList } from 'src/assets/constants';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import IconAdd from 'src/assets/icon/IconAdd';
import moment from 'moment';
import { decodeUrl } from 'src/utils/decodeUrl';
import ReactImageFallback from 'react-image-fallback';
import noImage from 'src/assets/images/no-image.png';
import { useListAcrylicPlate } from 'src/api/useListAcrylicPlate';
import { getIndexTable } from 'src/utils';
import { numberWithCommas } from 'src/utils/price';

interface DataType {
  key: string;
  name: string;
  location: string;
  updated_at: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: '番号',
    key: 'index',
    dataIndex: 'index',
    width: 90,
  },
  {
    title: '画像のサムネイル',
    dataIndex: 'image_url',
    key: 'image_url',
    width: 200,
  },
  {
    title: '香水ラベル名',
    dataIndex: 'name',
    key: 'name',
    width: 350,
  },
  {
    title: '作成日時',
    key: 'created_at',
    dataIndex: 'created_at',
    width: 200,
  },
  {
    title: 'サイズ',
    key: 'size_title',
    dataIndex: 'size_title',
    width: 150,
  },
  {
    title: '単価  (円)',
    key: 'price',
    dataIndex: 'price',
    width: 140,
    align: 'right',
  },
  {
    title: '配送料  (円)',
    key: 'shipping_fee',
    dataIndex: 'shipping_fee',
    width: 160,
    align: 'right',
  },
  {
    title: '',
    key: '',
    dataIndex: '',
    width: 30,
  },
];

const AcrylicPlatesListComponent = () => {
  const history = useNavigate();
  const [keyword, setKeyword] = useState<string>('');
  const [keywordSearch, setKeywordSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [sizePage, setSizePage] = useState<number>(10);

  const { data: acrylicListData, isLoading } = useListAcrylicPlate(keywordSearch, page, sizePage);

  const dataSource = useMemo(() => {
    if (acrylicListData) {
      return acrylicListData?.records?.data?.map((acrylic: any, index: any) => ({
        ...acrylic,
        key: acrylic?.id,
        index: <span className="line-1">{getIndexTable(page, sizePage, index)}</span>,
        image_url: (
          <span className="inline-block">
            <ReactImageFallback
              src={decodeUrl(acrylic?.image_url)}
              fallbackImage={noImage}
              initialImage={<Spin />}
              alt=""
              className="image-in-table-acrylic-plate"
            />
          </span>
        ),
        name: (
          <span
            onClick={() => handleGoToAcrylicPlateDetail(acrylic?.acrylic_plate_id)}
            className="line-1 break-all column-redirect cursor-pointer"
            style={{ display: '-webkit-inline-box' }}
          >
            {acrylic?.acrylic_plate?.title}
          </span>
        ),
        created_at: (
          <span className="line-1">
            {acrylic?.created_at ? moment(acrylic?.created_at).utc().format('YYYY/MM/DD') : ''}
          </span>
        ),
        size: <span className="line-1">{acrylic?.size}</span>,
        price: <span className="line-1">{numberWithCommas(acrylic?.price)} 円</span>,
        shipping_fee: <span className="line-1">{numberWithCommas(acrylic?.shipping_fee)} 円</span>,
      }));
    }
    return [];
  }, [acrylicListData]);

  const handleChangeKeyword = (value: string) => {
    setKeyword(value);
  };

  const iconSearch = () => {
    return (
      <div className="p-[5px] cursor-pointer" onClick={handleSearch}>
        <img src={searchIcon} alt="" />
      </div>
    );
  };

  const handleChangeSizePage = (value: number) => {
    setPage(1);
    setSizePage(value);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleSearch = () => {
    setPage(1);
    setKeywordSearch(keyword?.trim());
    setKeyword(keyword?.trim());
  };

  const handleGoToCreatePage = () => {
    history('/product/acrylic-plates/create');
  };

  const handleGoToAcrylicPlateDetail = (id: number | undefined) => {
    history(`/product/acrylic-plates/${id}`);
  };

  return (
    <div className="page-content company-page">
      <div className="mb-[16px]">
        <h2 className="page-content-title border-bottom-title">香水ラベル一覧</h2>
      </div>
      <div className="page-content-table">
        <div className="flex justify-between items-center p-[16px]">
          <div className="flex items-center">
            <Input
              onPressEnter={handleSearch}
              className="input-search mr-[8px] min-w-[423px]"
              placeholder="香水ラベル名を検索してください"
              suffix={iconSearch()}
              onChange={(e) => handleChangeKeyword(e.target.value)}
              value={keyword}
            />
          </div>
          <Button className="button-add max-h-[40px] w-[128px]" onClick={handleGoToCreatePage}>
            <IconAdd className="mr-[10px]" />
            追加
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={isLoading}
          locale={{
            emptyText: <TableNoteNoData />,
          }}
        />

        <div className="py-[20px] px-[12px] flex justify-between items-center">
          <div className="pagination-info">
            {acrylicListData?.limitFrom} - {acrylicListData?.limitTo} 件表示/全 {acrylicListData?.totalRow} 件中
          </div>
          <div className="flex">
            <Pagination
              current={page}
              total={acrylicListData?.totalRow}
              className="pagination-main mr-[20px]"
              showSizeChanger={false}
              pageSize={sizePage}
              onChange={(page) => handleChangePage(page)}
            />
            <Select
              defaultValue={10}
              style={{ width: 153 }}
              className="pagination-options"
              onChange={(e) => handleChangeSizePage(e)}
              options={pageSizeList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AcrylicPlatesListComponent;
