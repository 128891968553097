import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Step1 from './components/Step1';
import Step2AddPanel from './components/Step2';
import { Button } from 'antd';
import { getElementSvgFile } from 'src/utils';

export default function AddPanel({ handleSetFeature, editMode, baseSvgContent, defaultStep }: any) {
  const [step, setStep] = useState<number>(editMode ? defaultStep : 1);
  const [panel, setPanel] = useState<any>();
  const [svgContent, setSvgContent] = useState<any>();

  useEffect(() => {
    if (editMode && baseSvgContent) {
      setSvgContent(baseSvgContent?.attrs?.shapeData[0]);
    } else setSvgContent(false);
  }, [baseSvgContent, editMode]);

  useEffect(() => {
    if (!editMode) {
      if (panel?.file_path_url) {
        getElementSvgFile(panel?.file_path_url).then((res: any) => {
          setSvgContent({
            ...res,
          });
        });
      } else {
        setSvgContent({
          ...panel,
        });
      }
    }
  }, [panel, panel?.file_path_url]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 panel={panel} setPanel={setPanel} setStep={setStep} handleSetFeature={handleSetFeature} />;
      case 2:
        return (
          <Step2AddPanel
            svgContent={svgContent}
            handleSetFeature={handleSetFeature}
            setStep={setStep}
            editMode={editMode}
            defaultStep={defaultStep}
            baseSvgContent={baseSvgContent}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={styles.wrapRenderStep}>
      <Button
        className="button-form !w-auto mb-3"
        onClick={() => {
          handleSetFeature();
        }}
      >
        キャンセル
      </Button>

      {renderStep()}
    </div>
  );
}
