import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import Step1 from './components/Step1';
import Step2 from './components/Step2';
import Step3AddText from './components/Step3';
import Step4AddText from './components/Step4';
import { ListFontEn, ListFontJa } from './constants/listFont';
import { Button } from 'antd';
import { HEIGHT_STAGE, TypeLangFontTextEditor, WIDTH_STAGE } from 'src/assets/dataUI';

export default function AddText({
  handleSetFeature,
  editMode,
  baseSvgContent,
  defaultStep,
}: {
  handleSetFeature: () => void;
  editMode: any;
  baseSvgContent: any;
  defaultStep: any;
}) {
  const [svgContent, setSvgContent] = useState<any>();
  const [step, setStep] = useState<number>(editMode ? defaultStep : 1);
  const [text, setText] = useState<any>();
  const [textLength, setTextLength] = useState<any>();
  const [textStyle, setTextStyle] = useState<any>({
    fontFamily: ListFontJa?.[0]?.family,
    name: ListFontJa?.[0]?.name,
    color: '#aabbcc',
    letterSpacing: '0px',
    fontSize: '40px',
  });
  const [tabFont, setTabFont] = useState(TypeLangFontTextEditor.JA);

  const resetTextStyle = () => {
    setTextStyle({
      fontFamily: ListFontJa?.[0]?.family,
      name: ListFontJa?.[0]?.name,
      color: '#aabbcc',
      letterSpacing: '0px',
      fontSize: '40px',
    });
    setTabFont(TypeLangFontTextEditor.JA);
  };

  useEffect(() => {
    if (editMode && baseSvgContent) {
      const baseContent = baseSvgContent?.attrs?.shapeData[0];
      setSvgContent({
        ...baseContent,
        matrix: false,
        transform: {
          x:
            (WIDTH_STAGE -
              (baseContent?.image_url ? baseContent?.shapeSizeTranform?.width : baseContent?.shapeSize?.width)) /
            2,
          y:
            (HEIGHT_STAGE -
              (baseContent?.image_url ? baseContent?.shapeSizeTranform?.height : baseContent?.shapeSize?.height)) /
            2,
        },
        rotate: {
          a: 0,
          x: 0,
          y: 0,
          baseScale: 1,
        },
      });
      setText(baseContent?.text);
      setTextStyle(baseContent?.textStyle);
    }
  }, [editMode, baseSvgContent]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1
            text={text}
            setText={setText}
            setStep={setStep}
            handleSetFeature={handleSetFeature}
            resetTextStyle={resetTextStyle}
          />
        );
      case 2:
        return (
          <Step2
            text={text}
            textStyle={textStyle}
            setTextStyle={setTextStyle}
            setStep={setStep}
            setTextLength={setTextLength}
            listFontEn={ListFontEn}
            listFontJa={ListFontJa}
            setTabFont={setTabFont}
            tabFont={tabFont}
          />
        );
      // case 3:
      //   return (
      //     <Step3AddText
      //       svgContent={svgContent}
      //       setSvgContent={setSvgContent}
      //       text={text}
      //       textStyle={textStyle}
      //       setStep={setStep}
      //       textLength={textLength}
      //       editMode={editMode}
      //       handleSetFeature={handleSetFeature}
      //     />
      //   );
      case 3:
        return (
          <Step4AddText
            text={text}
            svgContent={svgContent}
            setSvgContent={setSvgContent}
            textStyle={textStyle}
            handleSetFeature={handleSetFeature}
            textLength={textLength}
            setStep={setStep}
            editMode={editMode}
            defaultStep={defaultStep}
            baseSvgContent={baseSvgContent}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={styles.wrapRenderStep}>
      <Button
        className="button-form !w-auto mb-3"
        onClick={() => {
          handleSetFeature();
        }}
      >
        キャンセル
      </Button>

      {renderStep()}
    </div>
  );
}
