import { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { Input, Button, Form, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';
import success from 'src/assets/images/tick-circle.png';
import { forgotPassword } from 'src/api/userInfor';
import Loading from 'src/components/common/loading';

const ConfirmEmail = () => {
  const history = useNavigate();
  const [antForm] = Form.useForm();
  const [messError, setMessError] = useState('');
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState<boolean>(false);

  const { isLoading, mutate: onForgotPassword } = useMutation((param: { email: string }) => forgotPassword(param), {
    onSuccess: () => {
      setIsOpenModalSuccess(true);
    },
    onError: (error: any) => {
      if (error.response?.status === 404) {
        setMessError('メールアドレスが正しくないです。確認し、再度入力してください');
        return;
      }
      setMessError('システムエラー');
    },
  });

  const handleSendEmailForgotPassword = useCallback(async () => {
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    const values = antForm.getFieldsValue();
    return onForgotPassword(values);
  }, [antForm, onForgotPassword]);

  const handleChangeEmail = () => {
    setMessError('');
  };
  const handleEnterSubmit = (e: any) => {
    if (e.key === 'Enter') {
      handleSendEmailForgotPassword();
    }
  };

  const handleCloseModalSuccess = () => {
    setIsOpenModalSuccess(false);
    history('/login');
  };

  // eslint-disable-next-line
  const validateEmail = (rule: any, value: string, callback: any) => {
    // eslint-disable-next-line
    const emailRegex = /^\w+([\.\-\+]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!value?.trim()) {
      callback('メールアドレスを入力してください');
    } else if (!emailRegex.test(value.trim())) {
      callback('メールアドレスが無効です');
    } else {
      callback();
    }
  };

  return (
    <div className="login">
      {isLoading && <Loading />}
      <div className="login__form">
        <div className="login__form-content">
          <h1 className="form-title mb-[24px] text-center">パスワードの忘れ</h1>
          <p className="text-[16px] text-[#2E2E2E] ">
            ご登録いただいているメールアドレスを入力し、確認ボタンをクリックしてください。
          </p>
          <p className="mb-[16px] text-[16px] text-[#2E2E2E] ">パスワード再設定用のメールをお送りします。</p>
          <Form form={antForm} onKeyPress={(e) => handleEnterSubmit(e)} layout="vertical" autoComplete="off">
            <div className="mb-[16px]">
              <Form.Item
                label="メール："
                name="email"
                required
                rules={[{ validator: (rule, value, callback) => validateEmail(rule, value, callback) }]}
              >
                <Input
                  onChange={() => handleChangeEmail()}
                  className="login__form__input"
                  size="large"
                  placeholder="メールを入力してください"
                />
              </Form.Item>
              {messError && <p className="invalid-feedback whitespace-pre-line">{messError}</p>}
            </div>
            <Button
              onClick={() => handleSendEmailForgotPassword()}
              loading={isLoading}
              className="login__form__button mx-auto block"
            >
              <span></span>確認
            </Button>
          </Form>
        </div>
      </div>
      <Modal
        title=""
        open={isOpenModalSuccess}
        width={400}
        className="modal-success"
        footer={false}
        closable={false}
        centered={true}
        afterClose={handleCloseModalSuccess}
      >
        <img src={success} alt="" className="mb-[16px] mx-auto" />
        <div className="text-[#2E2E2E] text-[20px] font-medium text-center mb-[8px]">メールが送信されました</div>
        <p className="text-[#2E2E2E] text-[14px] text-center mb-[44px]">
          パスワード再設定用のメールをお送りしました。ご登録いただいているメールでどうぞご確認ください。
        </p>
        <Button className="button-add w-full h-[48px]" onClick={() => handleCloseModalSuccess()}>
          ログイン画面へ戻る
        </Button>
      </Modal>
    </div>
  );
};

export default ConfirmEmail;
