export const ListFontEn = [
  {
    name: 'Lato',
    family: 'Lato',
  },
  {
    name: 'Merriweather',
    family: 'Merriweather',
  },
  {
    name: 'Montserrat',
    family: 'Montserrat',
  },
  {
    name: 'Noto Sans',
    family: 'Noto Sans',
  },
  {
    name: 'Open Sans',
    family: 'OpenSans',
  },
  {
    name: 'Poppins',
    family: 'Poppins',
  },
  {
    name: 'Radio Canada Big',
    family: 'Radio Canada Big',
  },
  {
    name: 'Raleway',
    family: 'Raleway',
  },
  {
    name: 'Roboto',
    family: 'Roboto',
  },
  {
    name: 'Rubik',
    family: 'Rubik',
  },
];

export const ListFontJa = [
  {
    name: 'Noto Sans Japanese',
    family: 'Noto Sans Japanese',
  },

  {
    name: 'Noto Serif Japanese',
    family: 'Noto Serif Japanese',
  },

  {
    name: 'M PLUS 1p',
    family: 'M PLUS 1p',
  },

  {
    name: 'Zen Kaku Gothic New',
    family: 'Zen Kaku Gothic New',
  },

  {
    name: 'Zen Maru Gothic',
    family: 'Zen Maru Gothic',
  },

  {
    name: 'BIZ UDPGothic',
    family: 'BIZ UDPGothic',
  },

  {
    name: 'Dela Gothic One',
    family: 'Dela Gothic One',
  },

  {
    name: 'M PLUS 1',
    family: 'M PLUS 1',
  },

  {
    name: 'Shippori Mincho B1',
    family: 'Shippori Mincho B1',
  },

  {
    name: 'Kosugi',
    family: 'Kosugi',
  },

  {
    name: 'M PLUS 2',
    family: 'M PLUS 2',
  },

  {
    name: 'Zen Kaku Gothic Antique',
    family: 'Zen Kaku Gothic Antique',
  },

  {
    name: 'Murecho',
    family: 'Murecho',
  },

  {
    name: 'BIZ UDGothic',
    family: 'BIZ UDGothic',
  },

  {
    name: 'Yusei Magic',
    family: 'Yusei Magic',
  },

  {
    name: 'IBM Plex Sans JP',
    family: 'IBM Plex Sans JP',
  },

  {
    name: 'DotGothic16',
    family: 'DotGothic16',
  },

  {
    name: 'Zen Kurenaido',
    family: 'Zen Kurenaido',
  },

  {
    name: 'Monomaniac One',
    family: 'Monomaniac One',
  },

  {
    name: 'Mochiy Pop One',
    family: 'Mochiy Pop One',
  },

  {
    name: 'RocknRoll One',
    family: 'RocknRoll One',
  },

  {
    name: 'Stick',
    family: 'Stick',
  },

  {
    name: 'Rampart One',
    family: 'Rampart One',
  },

  {
    name: 'Hina Mincho',
    family: 'Hina Mincho',
  },

  {
    name: 'Mochiy Pop P One',
    family: 'Mochiy Pop P One',
  },

  {
    name: 'Reggae One',
    family: 'Reggae One',
  },

  {
    name: 'Hachi Maru Pop',
    family: 'Hachi Maru Pop',
  },

  {
    name: 'M PLUS 1 Code',
    family: 'M PLUS 1 Code',
  },

  {
    name: 'Yomogi',
    family: 'Yomogi',
  },

  {
    name: 'Aoboshi One',
    family: 'Aoboshi One',
  },

  {
    name: 'Chokokutai',
    family: 'Chokokutai',
  },

  {
    name: 'Cherry Bomb One',
    family: 'Cherry Bomb One',
  },

  {
    name: 'Slackside One',
    family: 'Slackside One',
  },

  {
    name: 'Tsukimi Rounded',
    family: 'Tsukimi Rounded',
  },

  {
    name: 'Darumadrop One',
    family: 'Darumadrop One',
  },

  {
    name: 'Palette Mosaic',
    family: 'Palette Mosaic',
  },

  {
    name: 'Shizuru',
    family: 'Shizuru',
  },

  {
    name: 'Yuji Hentaigana Akari',
    family: 'Yuji Hentaigana Akari',
  },

  {
    name: 'Rock 3D',
    family: 'Rock 3D',
  },

  {
    name: 'Yuji Hentaigana Akebono',
    family: 'Yuji Hentaigana Akebono',
  },
];
