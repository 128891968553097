import { Dropdown, Space, MenuProps, message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { logout } from 'src/api/auth';
import { clearToken, setLocalStorage } from 'src/utils/cookie';
import { useQueryClient } from 'react-query';
import IconLogout from 'src/assets/icon/IconLogout';
import IconLock from 'src/assets/icon/IconLock';
import './header.modules.scss';
import { useUserInFo } from 'src/api/useUserInfo';
import { useEffect, useState } from 'react';
import IconArrowDown from 'src/assets/icon/IconArrowDown';

const UserInfo = () => {
  const history = useNavigate();
  const queryClient = useQueryClient();
  const items: MenuProps['items'] = [
    {
      label: (
        <div className="user-info-item" onClick={() => handleGoToChangePassword()}>
          <IconLock className={'mr-[8px] menu-icon'} />
          <span>パスワードの変更</span>
        </div>
      ),
      key: 1,
    },
    {
      label: (
        <div className="user-info-item" onClick={() => handleLogout()}>
          <IconLogout className={'mr-[8px] menu-icon'} />
          <span>ログアウト</span>
        </div>
      ),
      key: 2,
    },
  ];

  const [userName, setUserName] = useState<string>('');

  const { mutateAsync: onLogout } = useMutation(() => logout(), {
    onSuccess: async () => {
      history('/login');
      clearToken();
      localStorage.clear();
      queryClient.clear();
    },
    onError: () => {
      message.error('システムエラー');
    },
  });

  const { data } = useUserInFo();

  useEffect(() => {
    if (!data) return;
    const { role, name } = data;
    setUserName(name);

    setLocalStorage('role', role);
  }, [data]);

  const handleLogout = () => {
    onLogout();
  };

  const handleGoToChangePassword = () => {
    history('/change-password');
  };

  const handleGetDropdownContainer = () => {
    return document.getElementById('header')!;
  };

  return (
    <div className="flex items-center ml-auto">
      <Dropdown
        overlayClassName="user-info "
        menu={{ items }}
        trigger={['click']}
        getPopupContainer={handleGetDropdownContainer}
      >
        <Space>
          <p className="header__user-name mr-[10px] min-w-[200px] text-right">{userName}</p>
          <IconArrowDown />
        </Space>
      </Dropdown>
    </div>
  );
};

export default UserInfo;
