import React from 'react';
import { generatePointLine, calculateNewPoint } from '../utils';

//tinymask using for socket
const GenerateTinyMaskSvg = ({ shapeInfo }: any) => {
  switch (shapeInfo?.type) {
    case 100:
      return (
        <>
          <rect
            width={shapeInfo?.width + 5}
            height={shapeInfo?.height + 5}
            x={shapeInfo?.x}
            y={shapeInfo?.y}
            transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
            fill="white"
          />
          <rect
            width={shapeInfo?.width}
            height={shapeInfo?.height}
            x={shapeInfo?.x}
            y={shapeInfo?.y}
            transform={shapeInfo?.transform ? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})` : ''}
            stroke="black"
          />
        </>
      );
    case 99:
      return (
        <>
          <circle
            cx={shapeInfo.startX}
            cy={shapeInfo.startY}
            r={shapeInfo.smallRadius + 2 + shapeInfo.borderSmallWidth}
            fill="white"
          />
          <circle cx={shapeInfo.startX} cy={shapeInfo.startY} r={shapeInfo.smallRadius} stroke="black" />
        </>
      );
    case 98:
      return (
        <>
          <polygon points={generatePointLine(shapeInfo?.points)} fill="white" />
          <polygon points={generatePointLine(calculateNewPoint(shapeInfo?.points, shapeInfo?.scale1))} stroke="black" />
        </>
      );
    default:
      return <></>;
  }
};

export default GenerateTinyMaskSvg;
