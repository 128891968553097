import 'moment/locale/ja';
import { DatePicker, Pagination, Select, Table } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { pageSizeList, rangeTimeFitler } from 'src/assets/constants';
import iconSelect from 'src/assets/images/arrow-down-2.png';
import iconCalendar from 'src/assets/images/calendar-filter.png';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import { useListOrderStatus } from 'src/api/useListOrderStatus';
import { useListOrder } from 'src/api/useListOrder';
import moment from 'moment';
import { numberWithCommas } from 'src/utils/price';
import { getClassStatusOrder, handleGetStatusOrder } from 'src/utils';
import Icon from '@ant-design/icons';
import { TypeFilterRangeTime } from 'src/assets/constants/enums';

interface DataType {
  key: string;
  name: string;
  location: string;
  updated_at: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: '注文番号',
    key: 'order_code',
    dataIndex: 'order_code',
    width: 311,
  },
  {
    title: '注文日時',
    key: 'created_at',
    dataIndex: 'created_at',
    width: 290,
  },
  {
    title: 'ステータス',
    key: 'status',
    dataIndex: 'status',
    width: 205,
    align: 'left',
  },
  {
    title: '注文金額',
    dataIndex: 'total_price',
    key: 'total_price',
    width: '20%',
    align: 'right',
  },
  {
    title: '',
    dataIndex: '',
    key: '',
    width: '15%',
    align: 'left',
  },
];

const OrderStatisticListComponent = () => {
  const history = useNavigate();
  const [status, setStatus] = useState<number | undefined>();
  const [typeRangeTime, setTypeRangeTime] = useState<TypeFilterRangeTime | any>(TypeFilterRangeTime.THIS_MONTH);
  const [page, setPage] = useState<number>(1);
  const [dates, setDates] = useState<Array<any>>([
    moment()?.startOf('month').format('YYYY-MM-DD'),
    moment()?.endOf('month').format('YYYY-MM-DD'),
  ]);
  const [sizePage, setSizePage] = useState<number>(10);
  const [listStatusData, setListStatusData] = useState([]);
  const { data: listOrderData, isLoading } = useListOrder({
    page,
    size: sizePage,
    status,
    dates,
    order_by: 'created_at',
    get_total: 1,
  });
  const { data: listStatus } = useListOrderStatus();

  const dataSource = useMemo(() => {
    if (listOrderData) {
      return listOrderData?.records?.data?.map((order: any) => ({
        ...order,
        key: order?.id,
        order_code: (
          <span onClick={() => handleGoOrderDetail(order?.id)} className="cursor-pointer column-redirect">
            {order?.order_code}
          </span>
        ),
        created_at: (
          <span className="site-name line-1 break-all">
            {order?.created_at ? moment(order?.created_at).utc().format('YYYY/MM/DD') : ''}
          </span>
        ),
        status: (
          <span className={`company-request-status inline-block ${getClassStatusOrder(order?.status)}`}>
            {handleGetStatusOrder(order?.status)}
          </span>
        ),
        total_price: <span className="line-1 break-all">{numberWithCommas(order?.total_price)} 円</span>,
      }));
    }
    return [];
  }, [listOrderData]);

  const handleChangeSizePage = (value: number) => {
    setPage(1);
    setSizePage(value);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleGoOrderDetail = (id: number | undefined) => {
    history(`/order/${id}?isFromRevenue=1`);
  };

  const handleSearchStatus = (status: number) => {
    setPage(1);
    setStatus(status);
  };

  const onChangeStartDateFilter = (value: any) => {
    setPage(1);
    setDates([moment(value).format('YYYY-MM-DD'), dates?.[1] || undefined]);
    setTypeRangeTime(null);
  };

  const onChangeEndDateFilter = (value: any) => {
    setPage(1);
    setDates([dates?.[0] || undefined, moment(value).format('YYYY-MM-DD')]);
    setTypeRangeTime(null);
  };

  const onGetFistDayOfMonth = (month?: any) => {
    return month?.startOf('month').format('YYYY-MM-DD');
  };

  const onGetLastDayOfMonth = (month?: any) => {
    return month?.endOf('month').format('YYYY-MM-DD');
  };

  const onChangeRangeTime = (value: number) => {
    let firstDayOfMonth: any;
    let lastDayOfMonth: any;
    setTypeRangeTime(value);
    if (value === TypeFilterRangeTime.THIS_MONTH) {
      firstDayOfMonth = onGetFistDayOfMonth(moment());
      lastDayOfMonth = onGetLastDayOfMonth(moment());
    } else if (value === TypeFilterRangeTime.LAST_MONTH) {
      firstDayOfMonth = onGetFistDayOfMonth(moment().subtract(1, 'months'));
      lastDayOfMonth = onGetLastDayOfMonth(moment().subtract(1, 'months'));
    } else if (value === TypeFilterRangeTime.LAST_TWO_MONTH) {
      firstDayOfMonth = onGetFistDayOfMonth(moment().subtract(2, 'months'));
      lastDayOfMonth = onGetLastDayOfMonth(moment().subtract(2, 'months'));
    }
    setDates([firstDayOfMonth, lastDayOfMonth]);
    setPage(1);
  };

  const disabledStartDates = (date: any) => {
    if (date && dates?.[1]) {
      return moment(date)?.isAfter(moment(dates?.[1]));
    }
    return false;
  };

  const disabledEndDates = (date: any) => {
    if (date && dates?.[0]) {
      return moment(date)?.isBefore(moment(dates?.[0]));
    }
    return false;
  };

  const renderSelectIcon = () => {
    return <img src={iconSelect} alt="icon-select" />;
  };

  useEffect(() => {
    if (!(listStatus && listStatus.length)) return;
    setListStatusData(listStatus);
  }, [listStatus]);

  return (
    <div className="page-content company-page">
      <div className="page-content-table">
        <div className="flex justify-between items-center p-[16px] list-order-statistic">
          <div className="flex items-center">
            <Select
              options={listStatusData}
              placeholder="ステータス"
              className="company-select h-[40px] w-[200px] mr-[8px]"
              allowClear={true}
              onChange={(value: number) => handleSearchStatus(value)}
              suffixIcon={renderSelectIcon()}
            />
            <DatePicker
              className="date-picker-custom w-[150px] h-[40px]"
              suffixIcon={<Icon component={() => <img src={iconCalendar} alt="iconCalendar" />} />}
              inputReadOnly
              picker="date"
              format={'YYYY/MM/DD'}
              onChange={onChangeStartDateFilter}
              allowClear={false}
              value={moment(dates?.[0])}
              placeholder="From"
              disabledDate={disabledStartDates}
            />
            <div className="pr-[8px] pl-[8px]">~</div>
            <DatePicker
              className="date-picker-custom w-[150px] h-[40px] "
              suffixIcon={<Icon component={() => <img src={iconCalendar} alt="iconCalendar" />} />}
              inputReadOnly
              picker="date"
              format={'YYYY/MM/DD'}
              onChange={onChangeEndDateFilter}
              allowClear={false}
              value={moment(dates?.[1])}
              placeholder="To"
              disabledDate={disabledEndDates}
            />
          </div>
          <Select
            options={rangeTimeFitler}
            placeholder="ー"
            className="company-select h-[40px] w-[100px]"
            allowClear={false}
            onChange={onChangeRangeTime}
            suffixIcon={renderSelectIcon()}
            value={typeRangeTime}
          />
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={isLoading}
          locale={{
            emptyText: <TableNoteNoData />,
          }}
        />
        <div className="wrap-total-revenue">
          <div className="view-title-total-price">
            <div className="title-total-price">合計金額</div>
            <div className="total-price-revenue">
              {listOrderData?.totalPrice ? ` ${numberWithCommas(listOrderData?.totalPrice || 0)} 円` : 'ー'}
            </div>
          </div>
          <div className="view-right-title-total-price" />
        </div>
        <div className="py-[20px] px-[12px] flex justify-between items-center">
          <div className="pagination-info">
            {listOrderData?.limitFrom} - {listOrderData?.limitTo} 件表示/全 {listOrderData?.totalRow} 件中
          </div>
          <div className="flex">
            <Pagination
              current={page}
              total={listOrderData?.totalRow}
              className="pagination-main mr-[20px]"
              showSizeChanger={false}
              pageSize={sizePage}
              onChange={(page) => handleChangePage(page)}
            />
            <Select
              defaultValue={10}
              style={{ width: 153 }}
              className="pagination-options"
              onChange={(e) => handleChangeSizePage(e)}
              options={pageSizeList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatisticListComponent;
