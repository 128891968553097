import 'moment/locale/ja';
import { Button, Input, Pagination, Select, Table } from 'antd';
import { useMemo, useState } from 'react';
import searchIcon from 'src/assets/images/search-icon.png';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { ROLE, pageSizeList } from 'src/assets/constants';
import { getIndexTable, handleGetRole } from 'src/utils';
import iconSelect from 'src/assets/images/arrow-down-2.png';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import { useListStaff } from 'src/api/useListStaff';
import IconAdd from 'src/assets/icon/IconAdd';

interface DataType {
  key: string;
  name: string;
  location: string;
  updated_at: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: '番号',
    key: 'index',
    dataIndex: 'index',
    width: 100,
  },
  {
    title: '氏名',
    dataIndex: 'name',
    key: 'name',
    width: 300,
  },
  {
    title: 'メールアドレス',
    dataIndex: 'email',
    key: 'email',
    width: 426,
  },
  {
    title: '電話番号',
    key: 'phone',
    dataIndex: 'phone',
    width: 175,
  },
  {
    title: 'ロール',
    key: 'role',
    dataIndex: 'role',
    width: 160,
  },
];

const StaffListComponent = () => {
  const history = useNavigate();
  const [keyword, setKeyword] = useState<string>('');
  const [keywordSearch, setKeywordSearch] = useState<string>('');
  const [role, setRole] = useState<number | undefined>();
  const [page, setPage] = useState<number>(1);
  const [sizePage, setSizePage] = useState<number>(10);
  const { data: staffListData, isLoading } = useListStaff(keywordSearch, page, sizePage, role);

  const dataSource = useMemo(() => {
    if (staffListData) {
      return staffListData?.records?.data?.map((staff: any, index: any) => ({
        ...staff,
        key: staff?.id,
        index: <span className="line-1">{getIndexTable(page, sizePage, index)}</span>,
        name: (
          <span onClick={() => handleGoToEditStaff(staff?.id)} className="cursor-pointer column-redirect">
            {staff?.name}
          </span>
        ),
        email: <span className="line-1 break-all">{staff?.email}</span>,
        phone: (
          <span className="line-1 break-all">
            {staff?.phone_1 ? `${staff?.phone_1}-${staff?.phone_2}-${staff?.phone_3}` : ''}
          </span>
        ),
        role: <span className="line-1 break-all">{handleGetRole(staff?.role)}</span>,
      }));
    }
    return [];
  }, [staffListData]);

  const handleChangeKeyword = (value: string) => {
    setKeyword(value);
  };

  const iconSearch = () => {
    return (
      <div className="p-[5px] cursor-pointer" onClick={handleSearch}>
        <img src={searchIcon} alt="" />
      </div>
    );
  };

  const handleChangeSizePage = (value: number) => {
    setPage(1);
    setSizePage(value);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleSearch = () => {
    setPage(1);
    setKeywordSearch(keyword?.trim());
    setKeyword(keyword?.trim());
  };

  const renderSelectIcon = () => {
    return <img src={iconSelect} alt="iconSelect" />;
  };

  const handleChangeRole = (role: number) => {
    setPage(1);
    setRole(role);
    setKeywordSearch(keyword?.trim());
    setKeyword(keyword?.trim());
  };

  const handleGoToCreatePage = () => {
    history('/staff/create');
  };

  const handleGoToEditStaff = (id: number | string) => {
    history(`/staff/edit/${id}`);
  };

  return (
    <div className="page-content company-page">
      <div className="mb-[16px]">
        <h2 className="page-content-title border-bottom-title">社内ユーザー一覧</h2>
      </div>
      <div className="page-content-table">
        <div className="flex justify-between items-center p-[16px]">
          <div className="flex items-center">
            <Input
              onPressEnter={handleSearch}
              className="input-search mr-[8px] min-w-[423px]"
              placeholder="氏名を検索してください"
              suffix={iconSearch()}
              onChange={(e) => handleChangeKeyword(e.target.value)}
              value={keyword}
            />
            <Select
              options={ROLE}
              placeholder="ロール"
              className="company-select h-[40px] w-[200px]"
              allowClear={true}
              onChange={(value: number) => handleChangeRole(value)}
              suffixIcon={renderSelectIcon()}
            />
          </div>
          <Button className="button-add max-h-[40px] w-[128px]" onClick={handleGoToCreatePage}>
            <IconAdd className="mr-[10px]" />
            追加
          </Button>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={isLoading}
          locale={{
            emptyText: <TableNoteNoData />,
          }}
        />

        <div className="py-[20px] px-[12px] flex justify-between items-center">
          <div className="pagination-info">
            {staffListData?.limitFrom} - {staffListData?.limitTo} 件表示/全 {staffListData?.totalRow} 件中
          </div>
          <div className="flex">
            <Pagination
              current={page}
              total={staffListData?.totalRow}
              className="pagination-main mr-[20px]"
              showSizeChanger={false}
              pageSize={sizePage}
              onChange={(page) => handleChangePage(page)}
            />
            <Select
              defaultValue={10}
              style={{ width: 153 }}
              className="pagination-options"
              onChange={(e) => handleChangeSizePage(e)}
              options={pageSizeList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffListComponent;
