import React from "react";
import GenerateShapeSvgByType from "../GenerateShapeSvg";

const ArraySvg = ({
	shapeInfo,
	outline,
	drawOutline,
	handleClick,
	index,
}: any) => {
	return (
		<>
			{shapeInfo.pathList.map((pathListItem: any) => {
				return GenerateShapeSvgByType({
					shapeInfo: {
						...pathListItem,
						transform: shapeInfo?.transform,
					},
					outline,
					drawOutline,
					handleClick,
				});
			})}
		</>
	);
};

export default ArraySvg;
