import 'moment/locale/ja';
import { Input, Pagination, Select, Table } from 'antd';
import { useMemo, useState } from 'react';
import searchIcon from 'src/assets/images/search-icon.png';
import { useNavigate } from 'react-router-dom';
import type { ColumnsType } from 'antd/es/table';
import { pageSizeList } from 'src/assets/constants';
import { useListUser } from 'src/api/useListUser';
import TableNoteNoData from 'src/components/common/tableNoteNoData';
import moment from 'moment';
import { formatZipCode } from 'src/utils/formatZipCode';

interface DataType {
  key: string;
  name: string;
  location: string;
  updated_at: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: '登録日時',
    key: 'created_at',
    dataIndex: 'created_at',
    width: 130,
  },
  {
    title: 'ユーザー名',
    dataIndex: 'name',
    key: 'name',
    width: 300,
  },
  {
    title: '氏名',
    dataIndex: 'full_name',
    key: 'full_name',
    width: 300,
  },
  {
    title: '電話番号',
    key: 'phone',
    dataIndex: 'phone',
    width: 200,
  },
  {
    title: '住所',
    key: 'address',
    dataIndex: 'address',
    width: 350,
  },
  {
    title: () => {
      return <p className="text-center text-[16px] text-[#2E2E2E]"></p>;
    },
    key: 'action',
    dataIndex: 'action',
    width: 100,
  },
];

const UserListComponent = () => {
  const history = useNavigate();
  const [name, setName] = useState<string>('');
  const [nameSearch, setNameSearch] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [sizePage, setSizePage] = useState<number>(10);
  const { data: listUserData, isLoading } = useListUser(nameSearch, page, sizePage);

  const dataSource = useMemo(() => {
    if (listUserData) {
      return listUserData?.records?.data?.map((user: any) => ({
        ...user,
        key: user?.id,
        created_at: (
          <span className="line-1 break-all">
            {user?.created_at ? moment(user?.created_at).utc().format('YYYY/MM/DD') : ''}
          </span>
        ),
        name: (
          <span
            onClick={() => handleGoUserDetail(user?.id)}
            className="line-1 break-all column-redirect cursor-pointer"
            style={{ display: '-webkit-inline-box' }}
          >
            {user?.name}
          </span>
        ),
        full_name: <span className="line-1 break-all">{user?.full_name}</span>,
        phone: (
          <span className="line-1 break-all">
            {user?.phone_1 ? `${user?.phone_1}-${user?.phone_2}-${user?.phone_3}` : ''}
          </span>
        ),
        address: (
          <span className="line-1 break-all">
            {user?.post_code
              ? `〒${formatZipCode(user?.post_code)} ${user?.address ? user.address : ''}`
              : `${user?.address ? user.address : ''}`}
          </span>
        ),
      }));
    }
    return [];
  }, [listUserData]);

  const iconSearchName = () => {
    return (
      <div className="p-[5px] cursor-pointer" onClick={handleSearchName}>
        <img src={searchIcon} alt="icon-search" />
      </div>
    );
  };

  const handleChangeSizePage = (value: number) => {
    setPage(1);
    setSizePage(value);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleGoUserDetail = (id: number | undefined) => {
    history(`/user/${id}`);
  };

  const handleSearchName = () => {
    setPage(1);
    setNameSearch(name?.trim());
    setName(name?.trim());
  };

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleGoEditUser = (id: number | undefined) => {
    history(`/user/edit/${id}`);
  };

  return (
    <div className="page-content company-page">
      <div className="mb-[16px]">
        <h2 className="page-content-title border-bottom-title">LINEユーザー一覧</h2>
      </div>
      <div className="page-content-table">
        <div className="flex justify-between items-center p-[16px]">
          <div className="flex items-center">
            <Input
              onPressEnter={handleSearchName}
              className="input-search mr-[8px] min-w-[423px]"
              placeholder="ユーザー名又は氏名を検索してください"
              suffix={iconSearchName()}
              onChange={(e) => handleChangeName(e.target.value)}
              value={name}
            />
          </div>
        </div>

        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          loading={isLoading}
          locale={{
            emptyText: <TableNoteNoData />,
          }}
        />

        <div className="py-[20px] px-[12px] flex justify-between items-center">
          <div className="pagination-info">
            {listUserData?.limitFrom} - {listUserData?.limitTo} 件表示/全 {listUserData?.totalRow} 件中
          </div>
          <div className="flex">
            <Pagination
              current={page}
              total={listUserData?.totalRow}
              className="pagination-main mr-[20px]"
              showSizeChanger={false}
              pageSize={sizePage}
              onChange={(page) => handleChangePage(page)}
            />
            <Select
              defaultValue={10}
              style={{ width: 153 }}
              className="pagination-options"
              onChange={(e) => handleChangeSizePage(e)}
              options={pageSizeList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserListComponent;
