import { apiFormData } from 'src/api/axios';

export interface AcrylicPlateEditType {
  title: string;
  image: File | undefined;
  acrylic_plate_sizes: Array<{
    image?: File;
    size_title: string;
    price: string;
    width: string;
    height: string;
    thickness: string;
    shipping_fee: string;
  }>;
  acrylic_plate_size_delete_ids: Array<any>;
}

export const useEditAcrylic = (params?: AcrylicPlateEditType, id?: number | string) =>
  apiFormData.post(`/api/cms/acrylic-plate/${id}`, params);
