import { api } from 'src/api/axios';

export interface StaffCreateType {
  name: string;
  email: string;
  phone_1: string;
  phone_2: string;
  phone_3: string;
  role: number | string;
}

export const useCreateStaff = (params: StaffCreateType) => api.post(`/api/cms/admin`, params);
