import { useQuery } from 'react-query';
import { api } from 'src/api/axios';

export const useListNotification = (keyword: string, page: number, size: number, status?: number | string) =>
  useQuery(['listNotify', keyword, status, page, size], async () => {
    const res = await api.get('/api/cms/notification', {
      params: {
        keyword: keyword,
        page: page,
        size: size,
        status: status,
      },
    });
    return res.data.data;
  });
