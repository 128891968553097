const IconEnlargeReduce = ({ className }: any) => (
  <svg className={className} width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 35V23.3333H4.38805V28.3884L10.4149 22.3612L13.1362 25.0826L7.10931 31.1098H12.1641V34.998L0.5 35ZM25.5832 12.6387L22.8619 9.91735L28.8888 3.89016H23.834V0H35.5V11.6667H31.6119V6.61911L25.5832 12.6387Z"
      fill="black"
    />
  </svg>
);

export default IconEnlargeReduce;
