import { Button, Form, Input, Radio, Select, Spin, Upload, UploadFile, UploadProps, message } from 'antd';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  HIGHLIGHT_HIDE,
  HIGHLIGHT_SHOW,
  NOTIFICATION_STATUS,
  NOTIFY_STATUS_HIDE,
  NOTIFY_STATUS_SHOW,
} from 'src/assets/constants';
import { NotifyEditType, useEditNotify } from 'src/api/useEditNotify';
import { NotifyCreateType, useCreateNotify } from 'src/api/useCreateNotify';
import { useDetailNotify } from 'src/api/useDetailNotify';
import ImgCrop from 'antd-img-crop';
import ModalConfirm from 'src/components/common/modal/modalConfirm';
import IconDelete from 'src/assets/icon/IconDelete';
import IconUpload from 'src/assets/icon/IconUpload';
import iconSelect from 'src/assets/images/arrow-down-2.png';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import TextEditor from 'src/components/Editor';
import { Editor } from '@tinymce/tinymce-react';
import ModalPreviewNotify from 'src/components/common/modal/modalPreviewNotify';
import 'src/components/features/superAdmin/form.modules.scss';
import NotFoundPage from 'src/pages/notFoundPage';

const tinyMCEKey = process.env.REACT_APP_PUBLIC_TINYMCE_API_KEY;

const getBase64 = (img: any, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const useWatchTitle = (form: any) => {
  return Form.useWatch('title', form);
};

const NotificationCreateEditComponent = () => {
  const location = useLocation();
  const [antForm] = Form.useForm();
  const title = useWatchTitle(antForm);
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [isVisibleModelConfirmBack, setIsVisibleModelConfirmBack] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [imageFile, setImageFile] = useState<File>();
  const [imageMessError, setImageMessError] = useState<string>('');
  const [content, setContent] = useState<string>('');
  const [isOpenModalPreview, setIsOpenModalPreview] = useState<boolean>(false);
  const [isDisabledHighlight, setIsDisableHighlight] = useState<boolean>(() => {
    if (id) return false;
    return true;
  });
  const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(() => {
    if (location.pathname?.includes('edit')) return false;
    return true;
  });

  const { data: detailData, isLoading, isError } = useDetailNotify(Number(id));

  const { isLoading: isLoadingCreate, mutate: onCreateNotify } = useMutation(
    (param: NotifyCreateType) => useCreateNotify(param),
    {
      onSuccess: () => {
        message.success('お知らせ登録に成功しました');
        history('/notification');
        antForm.resetFields();
      },
      onError: () => {
        message.error('システムにエラーが発生しました。後でもう一度お試しください');
      },
    }
  );

  const { isLoading: isLoadingEdit, mutate: onEditNotify } = useMutation(
    (param: NotifyEditType) => useEditNotify(param, Number(id)),
    {
      onSuccess: () => {
        message.success('お知らせ編集は成功しました');
        history('/notification');
        antForm.resetFields();
        queryClient.clear();
      },
      onError: () => {
        message.error('システムにエラーが発生しました。後でもう一度お試しください');
      },
    }
  );

  const handleCreateEditStaff = async () => {
    await trimmedValue();
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    // if (!imageUrl) {
    //   setImageMessError('ファイルをアップロードしてください');
    //   return;
    // }
    if (!isDisableSubmit && content?.trim()) {
      const valueForm = antForm.getFieldsValue();
      const params = {
        ...valueForm,
        content: content,
        image_thumbnail: imageFile,
      };
      if (id) {
        onEditNotify(params);
      } else {
        onCreateNotify(params);
      }
    }
  };

  const handleGoBackNotifyList = () => {
    history('/notification');
  };

  const handleCancel = () => {
    setIsVisibleModelConfirmBack(false);
  };

  const trimmedValue = async () => {
    const allValueForm = antForm.getFieldsValue();
    Object.keys(allValueForm).forEach((key: any) => {
      if (typeof allValueForm[key] === typeof '') {
        antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() });
      }
    });
  };

  const renderSelectIcon = () => {
    return <img src={iconSelect} alt="icon-select" />;
  };

  const handleChange: UploadProps['onChange'] = (info: any) => {
    antForm.setFields([
      {
        name: 'image_thumbnail',
        errors: [],
      },
    ]);
    const isJpgOrPng = info.file.type === 'image/jpeg' || info.file.type === 'image/png';
    if (!isJpgOrPng) {
      setImageMessError('アップロードするファイルは「JPG, JPEG, PNG」のいずれかの形式にしてください');
      return;
    }
    const isLt5MB = info.file.size / 1024 / 1024 < 5;
    if (!isLt5MB) {
      setImageMessError('ファイルサイズが5MBを超えています');
      return;
    }
    if (info.file.originFileObj) {
      setImageMessError('');
      getBase64(info.file.originFileObj as any, (url) => {
        setImageUrl(url);
      });
      setImageFile(info.file.originFileObj);
    } else {
      setImageMessError('');
      getBase64(info.fileList[0].originFileObj as any, (url) => {
        setImageUrl(url);
      });
      setImageFile(info.fileList[0].originFileObj);
    }
  };

  const beforeUpload = async (file: any) => {
    antForm.setFields([
      {
        name: 'image_thumbnail',
        errors: [],
      },
    ]);
    const isLt5MB = file.size / 1024 / 1024 < 5;
    if (RegExp(/(jpg|jpeg|png)/i).test(`${file.type} ${file?.name}`)) {
      if (!isLt5MB) {
        setImageMessError('ファイルサイズが5MBを超えています');
        return;
      } else {
        const url = window.URL.createObjectURL(file);
        setImageUrl(url);
        setImageFile(file);
        setImageMessError('');
      }
    } else {
      setImageMessError('アップロードするファイルは「JPG, JPEG, PNG」のいずれかの形式にしてください');
      return;
    }
    return false;
  };

  const uploadButton = (
    <button className="upload-file" type="button">
      <IconUpload />
      <p className="text-upload">ファイルアップロード</p>
    </button>
  );

  const handleDeleteThumbnail = () => {
    setImageUrl('');
    setImageMessError('');
    // antForm.setFields([
    //   {
    //     name: 'image_thumbnail',
    //     errors: ['ファイルをアップロードしてください'],
    //   },
    // ]);
  };

  useEffect(() => {
    if (id) {
      setImageUrl(detailData?.image_thumbnail_url);
      setContent(detailData?.content);
      if (detailData?.status === NOTIFY_STATUS_HIDE) {
        setIsDisableHighlight(true);
      } else {
        setIsDisableHighlight(false);
      }
    }
  }, [id, detailData]);

  const handleGoBack = () => {
    history(-1);
  };

  const handleOpenModalPreview = () => {
    setIsOpenModalPreview(true);
  };

  const handleCloseModalPreview = () => {
    setIsOpenModalPreview(false);
  };

  const handleChangeStatus = (status: number) => {
    if (status === NOTIFY_STATUS_SHOW) {
      setIsDisableHighlight(false);
    } else {
      setIsDisableHighlight(true);
      antForm.setFieldsValue({
        ['highlight']: HIGHLIGHT_HIDE,
      });
    }
  };

  const handleChangeValue = (allvalues: []) => {
    const listValue = Object.keys(allvalues).map((key: any) => allvalues[key]);
    listValue.shift();
    listValue.pop();
    const validateErrorValueList = [];
    listValue?.forEach((value: any) => {
      if (value === undefined || value === '') {
        validateErrorValueList.push(value);
      }
    });
    if (validateErrorValueList.length > 0) {
      setIsDisableSubmit(true);
    } else {
      setIsDisableSubmit(false);
    }
  };

  if (location.pathname?.includes('edit')) {
    if (!detailData || Object.keys(detailData).length === 0) {
      if (isError) {
        return <NotFoundPage />;
      }
      return null;
    }
  }

  return (
    <div className="construction-create page-content company-page">
      <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
        <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
          <div className="mr-[17px]">
            <IconArrowLeft />
          </div>
          <span className="line-1">{location.pathname?.includes('edit') ? 'お知らせの編集' : 'お知らせの追加'}</span>
        </div>
      </div>

      {isLoading ? (
        <Spin className="position-center" />
      ) : (
        <Form
          form={antForm}
          className="form-create w-[900px] mx-auto"
          layout="vertical"
          autoComplete="off"
          onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}
        >
          <div className="pb-[12px]">
            <div className="mb-[16px]">
              <Form.Item
                // required
                label="画像サムネイル："
                name="image_thumbnail"
                initialValue={detailData?.image_thumbnail_url}
                // rules={[
                //   {
                //     required: !imageUrl,
                //     message: 'ファイルをアップロードしてください',
                //   },
                // ]}
              >
                <>
                  <ImgCrop
                    showGrid
                    showReset
                    rotationSlider
                    aspect={16 / 9}
                    modalTitle="サムネイル画像のトリミング"
                    modalOk="トリミング"
                    modalCancel="キャンセル"
                    resetText="リセット"
                  >
                    <Upload
                      listType="picture-card"
                      showUploadList={false}
                      multiple={false}
                      maxCount={1}
                      beforeUpload={(file) => beforeUpload(file)}
                      className="upload-file-image"
                    >
                      {imageUrl ? <img src={imageUrl} alt="thumbnail" className="thumbnail-upload" /> : uploadButton}
                    </Upload>
                  </ImgCrop>
                  {imageUrl && (
                    <button className="icon-delete" onClick={handleDeleteThumbnail} type="button">
                      <IconDelete />
                    </button>
                  )}
                </>
              </Form.Item>
              {imageMessError && <p className="invalid-feedback whitespace-pre-line">{imageMessError}</p>}
            </div>
            <div className="mb-[16px]">
              <Form.Item
                required
                label="表示/非表示："
                name="status"
                initialValue={location.pathname?.includes('edit') ? detailData?.status : NOTIFY_STATUS_HIDE}
              >
                <Select
                  className="company-input-select h-[51px]"
                  allowClear={false}
                  options={NOTIFICATION_STATUS}
                  suffixIcon={renderSelectIcon()}
                  placeholder="表示/非表示を選択してくだいさい"
                  onChange={handleChangeStatus}
                />
              </Form.Item>
            </div>
            <div className="mb-[16px]">
              <Form.Item
                label="Pickup："
                name="highlight"
                initialValue={location.pathname?.includes('edit') ? detailData?.highlight : HIGHLIGHT_HIDE}
              >
                <Radio.Group className="flex gap-[48px]" disabled={isDisabledHighlight}>
                  <Radio value={HIGHLIGHT_SHOW} className="radio-label">
                    あり
                  </Radio>
                  <Radio value={HIGHLIGHT_HIDE} className="radio-label">
                    なし
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </div>
            <div className="mb-[16px]">
              <Form.Item
                required
                label="タイトル："
                name="title"
                initialValue={detailData?.title}
                rules={[
                  {
                    validator: (rule, value, callback, length: number = 255) => {
                      if (!value?.trim()) {
                        callback('タイトルを入力してください');
                      } else if (length && value?.trim()?.length > length) {
                        callback('タイトルは255文字以内で入力してください');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                <Input className="input-form" placeholder="タイトルを入力してください" />
              </Form.Item>
            </div>
            <div className="mb-[16px]">
              <Form.Item
                required
                label="内容："
                name="content"
                initialValue={detailData?.content}
                rules={[
                  {
                    validator: (rule, value, callback, length: number = 3000) => {
                      if (!content?.trim()) {
                        callback('内容を入力してください');
                      } else if (length && content.replace(/<\/?[^>]+(>|$)/g, '')?.trim()?.length > length) {
                        callback('内容は3000文字以内で入力してください');
                      } else {
                        callback();
                      }
                    },
                  },
                ]}
              >
                <Editor
                  apiKey={tinyMCEKey ?? ''}
                  init={{
                    plugins: 'autolink link image lists',
                    toolbar: [
                      'undo redo | selectall | styles | fontsize fontfamily forecolor backcolor | bold italic underline strikethrough | link image | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
                    ],
                    menubar: false,
                    branding: false,
                    statusbar: false,
                    height: 700,
                    language: 'ja',
                    file_picker_callback: (callback, value, meta) => {
                      if (meta.filetype === 'image') {
                        const input = document.createElement('input');
                        input.setAttribute('type', 'file');
                        input.setAttribute('accept', 'image/*');

                        input.onchange = function () {
                          const file = input.files ? input.files[0] : null;
                          if (file) {
                            const reader = new FileReader();
                            reader.onload = function (e) {
                              if (typeof e.target?.result === 'string') {
                                callback(e.target.result, { alt: file.name });
                              } else {
                                console.error('FileReader result is not a string');
                              }
                            };
                            reader.readAsDataURL(file);
                          }
                        };

                        input.click();
                      }
                    },
                  }}
                  initialValue={detailData?.content ?? ''}
                  onEditorChange={(content) => {
                    setContent(content ?? '');
                  }}
                />

                {/* <Editor
                  apiKey={tinyMCEKey ?? ''}
                  init={{
                    plugins:
                      'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed linkchecker a11ychecker tinymcespellchecker permanentpen powerpaste advtable advcode editimage advtemplate ai mentions tinycomments tableofcontents footnotes mergetags autocorrect typography inlinecss markdown',
                    toolbar:
                      'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
                    tinycomments_mode: 'embedded',
                    tinycomments_author: 'Author name',
                    mergetags_list: [
                      { value: 'First.Name', title: 'First Name' },
                      { value: 'Email', title: 'Email' },
                    ],
                    height: 700,
                    language: 'ja',
                    ai_request: (request: any, respondWith: any) =>
                      respondWith.string(() => Promise.reject('See docs to implement AI Assistant')),
                  }}
                  initialValue={detailData?.content || ''}
                  onEditorChange={(content, editor) => {
                    console.log({ content });

                    setContent(content);
                  }}
                /> */}
              </Form.Item>
            </div>
          </div>

          <div className="footer-form flex">
            <Button className="button-form mr-[16px]" onClick={handleGoBack}>
              キャンセル
            </Button>
            <Button className="button-form button-form-review mr-[16px]" onClick={handleOpenModalPreview}>
              プレビュー
            </Button>
            <Button
              className="button-form button-form-Submit"
              loading={isLoadingCreate || isLoadingEdit}
              onClick={handleCreateEditStaff}
              disabled={isDisableSubmit || !content?.trim()}
            >
              <span></span>
              {location.pathname?.includes('edit') ? 'OK' : '追加'}
            </Button>
          </div>
        </Form>
      )}
      <ModalConfirm
        isShowModal={isVisibleModelConfirmBack}
        onCancel={handleCancel}
        onOk={handleGoBackNotifyList}
        content="このページを離れますか？"
        subContent="ページから移動すると、保存されていない変更は失われます。"
      />

      <ModalPreviewNotify
        isOpenModalPreview={isOpenModalPreview}
        handleCloseModalPreview={handleCloseModalPreview}
        title={title}
        created_at={location.pathname?.includes('edit') ? detailData?.created_at : new Date()}
        content={content}
      />
    </div>
  );
};

export default NotificationCreateEditComponent;
