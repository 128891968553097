import { Button, Form, Input, Modal, message } from 'antd';
import { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { trimmedValue } from 'src/utils';
import { changePassword } from 'src/api/userInfor';
import success from 'src/assets/images/tick-circle.png';
import { clearToken } from 'src/utils/cookie';
import { isFirstLogin } from 'src/utils/auth';

interface TypeFormChangePass {
  new_password: string;
  old_password: string;
  new_password_confirmation: string;
}

const ChangePasswordComponent = () => {
  const [messErrorOldPass, setMessErrorOldPass] = useState<string>('');
  const [messErrorNewPass, setMessErrorNewPass] = useState<string>('');
  const [antForm] = Form.useForm();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);
  const [isOpenModalSuccess, setIsOpenModalSuccess] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const { isLoading, mutate: onChangePassword } = useMutation((param: TypeFormChangePass) => changePassword(param), {
    onSuccess: () => {
      setIsOpenModalSuccess(true);
      clearToken();
      localStorage.clear();
      queryClient.clear();
      antForm.resetFields();
    },
    onError: (error: any) => {
      if (error.response?.status === 401) {
        setMessErrorOldPass('元パスワードが正しくないです。再度入力してください');
        return;
      }
      if (error.response?.status === 422) {
        setMessErrorNewPass('新しいパスワードは元パスワードと一致するのはいけません');
        return;
      }
      message.error('システムエラー');
    },
  });

  const handleChangePassword = async () => {
    await trimmedValue(antForm);
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    const values = antForm.getFieldsValue();
    onChangePassword(values);
  };

  const handleChangeValue = (allvalues: []) => {
    const listValue = Object.keys(allvalues).map((key: any) => allvalues[key]);
    const validateErrorValueList = [];
    listValue?.forEach((value: any) => {
      if (value === undefined || value === '') {
        validateErrorValueList.push(value);
      }
    });
    if (validateErrorValueList.length > 0) {
      setIsDisableSubmit(true);
    } else {
      setIsDisableSubmit(false);
    }
  };

  const handleGoBack = () => {
    history(-1);
  };

  useEffect(() => {
    if (id) {
      setIsDisableSubmit(false);
    }
  }, [id]);

  const validateFields = (rule: any, value: string, callback: any, name: string) => {
    if (!value?.trim()) {
      callback(`元パスワードを入力してください`);
    } else {
      callback();
    }
  };

  const validateNewPassword = (rule: any, value: string, callback: any) => {
    const valueForm = antForm.getFieldsValue();
    const passwordRegex =
      // eslint-disable-next-line
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[_~?&!@#%|,.<>=:;\\/\-\$\^\[\]\{\}\+\'\"\*\(\)])[A-Za-z0-9_~?&!@#%|,.<>=:;\\/\-\$\^\[\]\{\}\+\'\"\*\(\)]+$/;
    if (!value?.trim()) {
      callback(`新しいパスワードを入力してください`);
    } else if (value?.trim()?.length < 8 || value?.trim()?.length > 32) {
      callback(`パスワードは半角英数字8文字以上32文字以内で入力してください`);
    } else if (!passwordRegex.test(value?.trim())) {
      callback(`パスワードには大文字、小文字、数字、特殊文字を少なくとも1つ含むことです。`);
    } else if (valueForm['old_password'] === value) {
      callback(`新しいパスワードは元パスワードと一致するのはいけません`);
    } else {
      callback();
    }
  };

  // eslint-disable-next-line
  const validateConfirmPassword = (rule: any, value: string, callback: any) => {
    const valueForm = antForm.getFieldsValue();
    if (!value?.trim()) {
      callback(`新しいパスワード確認を入力してください`);
    } else if (valueForm['new_password'] !== value) {
      callback(`新しいパスワード確認は新しいパスワードと一致しないといけません`);
    } else {
      callback();
    }
  };

  const onClickButtonOk = async () => {
    history('/login');
  };

  const handleResetMessErrorOldPass = () => {
    setMessErrorOldPass('');
  };

  const handleResetMessErrorNewPass = () => {
    setMessErrorNewPass('');
  };

  return (
    <div className="login">
      <div className="login__form m-auto">
        <div className="login__form-content">
          <h1 className="form-title mb-[24px]">パスワードの再設定</h1>

          {isFirstLogin() && (
            <div className="first-login mb-[24px]">
              <span className="note-icon">⁂</span>
              初期にログインする時、パスワードの再設定は必要になりますので、新しいパスワードを変更 してください。
            </div>
          )}

          <Form
            layout="vertical"
            autoComplete="off"
            form={antForm}
            // eslint-disable-next-line
            onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}
            className="form-create"
          >
            <div className="">
              <div className="mb-[16px]">
                <Form.Item
                  required
                  label="元パスワード："
                  name="old_password"
                  rules={[
                    {
                      validator: (rule, value, callback) =>
                        validateFields(rule, value, callback, '現在のパスワードを入力してください'),
                    },
                  ]}
                >
                  <Input.Password
                    onChange={handleResetMessErrorOldPass}
                    className="input-form login__form__input"
                    placeholder="元パスワードを入力してください"
                  />
                </Form.Item>
                {messErrorOldPass && <p className="invalid-feedback whitespace-pre-line">{messErrorOldPass}</p>}
              </div>

              <div className="mb-[16px]">
                <Form.Item
                  required
                  label="新しいパスワード："
                  name="new_password"
                  rules={[{ validator: (rule, value, callback) => validateNewPassword(rule, value, callback) }]}
                >
                  <Input.Password
                    onChange={handleResetMessErrorNewPass}
                    className="input-form login__form__input"
                    placeholder="新しいパスワードを入力してください"
                  />
                </Form.Item>
                {messErrorNewPass && <p className="invalid-feedback whitespace-pre-line">{messErrorNewPass}</p>}
              </div>
              <div className="mb-[16px]">
                <Form.Item
                  required
                  label="新しいパスワードの再確認："
                  name="new_password_confirmation"
                  dependencies={['new_password']}
                  rules={[{ validator: (rule, value, callback) => validateConfirmPassword(rule, value, callback) }]}
                >
                  <Input.Password
                    className="input-form login__form__input"
                    placeholder="新しいパスワードを再入力してください"
                  />
                </Form.Item>
              </div>
            </div>
            <div className="footer-form flex">
              {!isFirstLogin() && (
                <Button className="button-form mr-[16px]" onClick={handleGoBack}>
                  キャンセル
                </Button>
              )}

              <Button
                disabled={isDisableSubmit}
                loading={isLoading}
                className="button-form button-form-Submit mx-auto"
                onClick={() => handleChangePassword()}
              >
                <span></span>変更
              </Button>
            </div>
          </Form>
        </div>
      </div>
      <Modal
        title=""
        open={isOpenModalSuccess}
        width={400}
        className="modal-success"
        footer={false}
        closable={false}
        centered
      >
        <img src={success} alt="" className="mb-[16px] mx-auto" />
        <div className="text-[#2E2E2E] text-[20px] font-medium text-center mb-[8px]">パスワードが変更されました</div>
        <p className="text-[#2E2E2E] text-[14px] text-center mb-[44px]">
          パスワードは正常に変更されました。 新しいパスワードで再度ログインしてください。
        </p>
        <Button className="button-add w-full h-[48px]" onClick={() => onClickButtonOk()}>
          ログイン画面へ戻る
        </Button>
      </Modal>
    </div>
  );
};

export default ChangePasswordComponent;
