import { useQuery } from 'react-query';
import { api } from 'src/api/axios';

export const useListStaff = (name: string, page: number, size: number, role?: number | string) =>
  useQuery(['listStaff', name, role, page, size], async () => {
    const res = await api.get('/api/cms/admin', {
      params: {
        name: name,
        page: page,
        size: size,
        role: role,
      },
    });
    return res.data.data;
  });
