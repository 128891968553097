const IconFont = ({ className }: any) => (
  <svg className={className} width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.5 34.0465L8.67381 0H12.591L20.7648 34.0465H17.0043L15.0457 25.3646H6.21775L4.25917 34.0465H0.5ZM7.36679 20.4292H13.9058L10.7447 6.29983H10.5266L7.36679 20.4292ZM27.4749 34.9847C25.9503 35.1209 24.4497 34.3451 23.2783 32.8148C22.7649 32.0604 22.3625 31.1407 22.099 30.1196C21.8354 29.0984 21.717 28 21.7519 26.9003C21.7351 25.78 21.8865 24.6691 22.194 23.6565C22.5014 22.644 22.9564 21.758 23.5225 21.0695C24.8219 19.5041 26.4334 18.7053 28.0729 18.8139C28.8492 18.8059 29.6243 18.9056 30.3893 19.1116C31.0299 19.2725 31.6482 19.6037 32.2173 20.0906V18.8996C32.2361 18.2104 32.1552 17.5233 31.9807 16.8896C31.8062 16.2558 31.5425 15.6915 31.2093 15.2387C30.4294 14.2739 29.4501 13.7893 28.4568 13.8764C27.7114 13.8707 26.9751 14.1331 26.305 14.6432C25.6288 15.1588 25.0404 15.9171 24.5879 16.8561L22.2442 14.1314C22.907 12.6353 23.8067 11.433 24.8556 10.6419C26.0006 9.81637 27.2509 9.40896 28.5116 9.45074C30.3772 9.25311 32.223 10.1482 33.6888 11.9613C34.3096 12.8967 34.7921 14.0325 35.1027 15.2894C35.4132 16.5463 35.5443 17.8938 35.4868 19.2381V34.2179H32.2721V31.3239H32.1089C31.634 32.4629 30.9763 33.3876 30.2012 34.0058C29.3572 34.6743 28.4211 35.0117 27.4749 34.9847ZM28.0194 30.7284C28.572 30.7429 29.1207 30.5804 29.6315 30.251C30.1424 29.9217 30.6047 29.4324 30.9899 28.8133C31.3862 28.2116 31.6995 27.4897 31.9104 26.6917C32.1212 25.8937 32.2252 25.0368 32.216 24.1736C31.671 23.6989 31.0834 23.3551 30.4728 23.1539C29.8832 22.9388 29.2803 22.8245 28.6749 22.8132C27.7352 22.7188 26.8004 23.0915 26.0047 23.8779C25.7021 24.2223 25.459 24.679 25.2974 25.2065C25.1358 25.7341 25.0607 26.3162 25.0789 26.9003C25.0696 27.4285 25.1385 27.9528 25.2803 28.4325C25.4221 28.9122 25.6329 29.3343 25.8963 29.6658C26.5001 30.4042 27.2533 30.7803 28.0194 30.7263V30.7284Z"
      fill="black"
    />
  </svg>
);

export default IconFont;
