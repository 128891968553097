const IconErase = ({ className }: any) => (
  <svg className={className} width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.1719 31.3825H35.5V35.5H23.6655L27.1719 31.3825ZM5.25775 35.5L1.53242 31.1253C1.2025 30.7418 0.941346 30.2847 0.764377 29.7808C0.587407 29.2769 0.498204 28.7363 0.502043 28.1912C0.485945 27.6411 0.565116 27.093 0.734762 26.5798C0.904407 26.0667 1.161 25.5993 1.48898 25.2061L20.7759 1.74145C21.0908 1.34253 21.4738 1.02602 21.8998 0.812285C22.3258 0.598551 22.7853 0.492446 23.2485 0.500944C23.7081 0.489145 24.165 0.588073 24.5908 0.791612C25.0167 0.99515 25.4025 1.29902 25.7245 1.68441L34.447 11.9271C34.7767 12.3095 35.0362 12.7671 35.2095 13.2718C35.3828 13.7764 35.4663 14.3174 35.4548 14.8613C35.4663 15.4053 35.3828 15.9462 35.2095 16.4508C35.0362 16.9554 34.7767 17.4131 34.447 17.7954L19.7195 35.5H5.25775ZM18.2321 31.3825L31.9953 14.8103L23.318 4.61849L3.94589 28.0831L6.75031 31.3764L18.2321 31.3825Z"
      fill="black"
    />
  </svg>
);

export default IconErase;
