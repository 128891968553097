import React from 'react';

type LayoutProps = {
  navBar?: React.ReactNode;
  settingBar?: React.ReactNode;
  children: React.ReactNode;
  isSelect: boolean;
};

const Layout: React.FC<LayoutProps> = ({ navBar, settingBar, children, isSelect }) => {
  return (
    <>
      {children}

      {isSelect ? navBar : settingBar}
    </>
  );
};

export default Layout;
