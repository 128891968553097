import { useFetchParts } from 'src/api/useFetchParts';
import styles from '../index.module.scss';
import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { HEIGHT_STAGE, WIDTH_STAGE } from 'src/assets/dataUI';
import { useRatioContext } from 'src/hook/useChangeRatio';

type Props = {
  panel: any;
  setPanel: (val: any) => void;
  setStep: (val: any) => void;
  handleSetFeature: any;
};

export default function Step1({ panel, setPanel, setStep, handleSetFeature }: Props) {
  const { data } = useFetchParts([4]);
  const {stateRatio} = useRatioContext()

  return (
    <div className={styles.step1}>
      <div className={styles.step1}>
        <div className={styles.wrapChooseStamp}>
          <div
            className={styles.img}
            style={{
              width: stateRatio?.ratioWidth,
              height: stateRatio?.ratioHeight,
            }}
          >
            {panel?.file_path_url && <img src={panel?.file_path_url} alt="stamp" style={{ width: 120, height: 120 }} />}
          </div>

          <div
            className={styles.listStamp}
            style={{
              height: stateRatio?.ratioHeight,
            }}
          >
            {data?.data[4]?.map((item: any) => {
              return (
                <div className={styles.panelType} key={item?.name + 'panelItem'}>
                  <p className={styles.title}>{item?.name}</p>

                  <div className={styles.panels} id="panels">
                    {item?.parts?.map((i: any) => {
                      return (
                        <div
                          className={`${styles.panelItem} ${i.id === panel?.id ? styles.panelItemBorder : ''}`}
                          key={i.key}
                          onClick={() => {
                            setPanel(i);
                          }}
                        >
                          <img src={i?.file_path_url} alt={i?.file_name} />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex justify-center gap-2">
          <Button
            className="button-form button-form-Submit !w-auto"
            onClick={() => {
              handleSetFeature();
            }}
          >
            {'<<詳細画面へ戻る'}
          </Button>
          <Button
            className="button-form button-form-Submit !w-auto"
            disabled={!panel}
            onClick={() => {
              setStep((prev: number) => prev + 1);
            }}
          >
            {'次のステップへ>>'}
          </Button>
        </div>
      </div>
    </div>
  );
}
