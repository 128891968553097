import { Button, Form, Input, Spin, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useDetailUser } from 'src/api/useDetailUser';
import { UserEditType, useEditUser } from 'src/api/useEditUser';
import IconArrowLeft from 'src/assets/icon/IconArrowLeft';
import NotFoundPage from 'src/pages/notFoundPage';
import moment from 'moment';

const UserEditComponent = () => {
  const [antForm] = Form.useForm();
  const history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(true);
  const [phone1, setPhone1] = useState<string>('');
  const [phone2, setPhone2] = useState<string>('');
  const [phone3, setPhone3] = useState<string>('');
  const [messageErrorPhone1, setMessageErrorPhone1] = useState<string>('');
  const [messageErrorPhone2, setMessageErrorPhone2] = useState<string>('');
  const [messageErrorPhone3, setMessageErrorPhone3] = useState<string>('');

  const { data: detailData, isLoading: isLoadingDetail, isError } = useDetailUser(Number(id));

  const { isLoading: isLoadingEdit, mutate: onEditUser } = useMutation(
    (param: UserEditType) => useEditUser(param, Number(id)),
    {
      onSuccess: () => {
        message.success('ユーザー情報の編集に成功しました');
        history(`/user/${id}`);
        antForm.resetFields();
        queryClient.clear();
      },
      onError: () => {
        message.error('ニューザー情報の編集は失敗しました。再度確認してください');
      },
    }
  );

  const handleEditUser = async () => {
    await trimmedValue();
    try {
      await antForm.validateFields();
    } catch {
      return;
    }
    if (messageErrorPhone1 === '' && messageErrorPhone2 === '' && messageErrorPhone3 === '' && !isDisableSubmit) {
      const valueForm = antForm.getFieldsValue();
      const params = {
        full_name: valueForm.full_name,
        address: valueForm.address,
        phone_1: phone1,
        phone_2: phone2,
        phone_3: phone3,
        post_code: valueForm.post_code,
      };
      if (id) {
        onEditUser(params);
      }
    }
  };

  useEffect(() => {
    if (id) {
      setIsDisableSubmit(false);
    }
  }, [id]);

  const handleGoBack = () => {
    history(-1);
  };

  const trimmedValue = async () => {
    const allValueForm = antForm.getFieldsValue();
    Object.keys(allValueForm).forEach((key: any) => {
      if (typeof allValueForm[key] === typeof '') {
        antForm.setFieldsValue({ [key]: allValueForm[key]?.trim() });
      }
    });
  };

  const validateFullName = (rule: any, value: string, callback: any) => {
    if (!value?.trim()) {
      callback(`氏名を入力してください`);
    } else if (value?.trim()?.length > 15) {
      callback(`氏名は15文字以内で入力してください`);
    } else {
      callback();
    }
  };

  const validateAddress = (rule: any, value: string, callback: any) => {
    if (!value?.trim()) {
      callback(`住所を入力してください`);
    } else if (value?.trim()?.length > 255) {
      callback(`住所は255文字以内で入力してください`);
    } else {
      callback();
    }
  };

  const validatePostCode = (rule: any, value: string, callback: any) => {
    const postCodeRegex = /^([0-9]{7}$)+$|^$/i; // Assuming 7-digit number format
    if (!value?.trim()) {
      callback('郵便番号を入力してください');
    } else if (!postCodeRegex.test(value.trim())) {
      callback('郵便番号は半角数字7文字で入力してください');
    } else {
      callback();
    }
  };

  const validatePhone1 = (rule: any, value: string, callback: any) => {
    const phoneRegex = /^([0-9]{2,4}$)+$|^$/i;
    if (!value?.trim()) {
      callback();
      setMessageErrorPhone1('電話番号を入力してください');
    } else if (!phoneRegex.test(value.trim())) {
      callback();
      setMessageErrorPhone1('各ブロックは半角数字２文字以上４文字以内で入力してください');
    } else {
      callback();
      setMessageErrorPhone1('');
    }
  };

  const validatePhone2 = (rule: any, value: string, callback: any) => {
    const phoneRegex = /^([0-9]{2,4}$)+$|^$/i;
    if (!value?.trim()) {
      callback();
      setMessageErrorPhone2('電話番号を入力してください');
    } else if (!phoneRegex.test(value.trim())) {
      callback();
      setMessageErrorPhone2('各ブロックは半角数字２文字以上４文字以内で入力してください');
    } else {
      callback();
      setMessageErrorPhone2('');
    }
  };

  const validatePhone3 = (rule: any, value: string, callback: any) => {
    const phoneRegex = /^([0-9]{2,4}$)+$|^$/i;
    if (!value?.trim()) {
      callback();
      setMessageErrorPhone3('電話番号を入力してください');
    } else if (!phoneRegex.test(value.trim())) {
      setMessageErrorPhone3('各ブロックは半角数字２文字以上４文字以内で入力してください');
    } else {
      callback();
      setMessageErrorPhone3('');
    }
  };

  const handleChangePhone1 = (valuePhone1: string) => {
    setPhone1(valuePhone1);
  };

  const handleChangePhone2 = (valuePhone2: string) => {
    setPhone2(valuePhone2);
  };
  const handleChangePhone3 = (valuePhone3: string) => {
    setPhone3(valuePhone3);
  };

  const handleChangeValue = (allvalues: []) => {
    const listValue = Object.keys(allvalues).map((key: any) => allvalues[key]);
    const validateErrorValueList = [];
    listValue?.forEach((value: any) => {
      if (value === undefined || value === '') {
        validateErrorValueList.push(value);
      }
    });
    if (validateErrorValueList.length > 0) {
      setIsDisableSubmit(true);
    } else {
      setIsDisableSubmit(false);
    }
  };

  useEffect(() => {
    if (!detailData) return;
    if (detailData?.phone_1) {
      setPhone1(detailData?.phone_1);
    }
    if (detailData?.phone_2) {
      setPhone2(detailData?.phone_2);
    }
    if (detailData?.phone_3) {
      setPhone3(detailData?.phone_3);
    }
  }, [detailData]);

  if (!detailData || Object.keys(detailData).length === 0) {
    if (isError) {
      return <NotFoundPage />;
    }
    return null;
  }

  return (
    <div className="construction-create page-content company-page">
      {isLoadingDetail ? (
        <Spin />
      ) : (
        <>
          <div className="page-content-title border-bottom-title mb-[30px] flex items-center justify-between">
            <div className="inline-flex items-center cursor-pointer" onClick={handleGoBack}>
              <div className="mr-[17px]">
                <IconArrowLeft />
              </div>
              <span className="line-1">基本情報の編集</span>
            </div>
          </div>

          <Form
            form={antForm}
            className="form-create w-[900px] mx-auto"
            layout="vertical"
            autoComplete="off"
            onValuesChange={(value, allvalues) => handleChangeValue(allvalues)}
          >
            <div className="pb-[12px]">
              <div className="mb-[16px]">
                <Form.Item
                  label="登録日時"
                  name="created-at"
                  initialValue={moment(detailData?.created_at).utc().format('YYYY/MM/DD')}
                >
                  <Input disabled className="input-form" />
                </Form.Item>
              </div>
              <div className="mb-[16px]">
                <Form.Item label="ユーザー名" name="name" initialValue={detailData?.name}>
                  <Input disabled className="input-form" />
                </Form.Item>
              </div>

              <div className="mb-[16px]">
                <Form.Item
                  required
                  label="氏名"
                  name="full_name"
                  initialValue={detailData?.full_name}
                  rules={[{ validator: (rule, value, callback) => validateFullName(rule, value, callback) }]}
                >
                  <Input className="input-form" placeholder="氏名を入力してください" />
                </Form.Item>
              </div>

              <div className="mb-[16px]">
                <label htmlFor="phone_1" className="form-label-phone">
                  電話番号 <span>*</span>
                </label>
                <div className="form-input-phone flex gap-[10px] items-center">
                  <Form.Item
                    name="phone_1"
                    initialValue={detailData?.phone_1}
                    rules={[
                      {
                        validator: (rule, value, callback) => validatePhone1(rule, value, callback),
                      },
                    ]}
                  >
                    <Input
                      className="input-form w-[100px]"
                      placeholder="0000"
                      onChange={(e) => handleChangePhone1(e.target.value)}
                      maxLength={4}
                    />
                  </Form.Item>
                  <span className="mark">－</span>
                  <Form.Item
                    name="phone_2"
                    initialValue={detailData?.phone_2}
                    rules={[
                      {
                        validator: (rule, value, callback) => validatePhone2(rule, value, callback),
                      },
                    ]}
                  >
                    <Input
                      className="input-form w-[100px]"
                      placeholder="0000"
                      onChange={(e) => handleChangePhone2(e.target.value)}
                      maxLength={4}
                    />
                  </Form.Item>
                  <span className="mark">－</span>
                  <Form.Item
                    name="phone_3"
                    initialValue={detailData?.phone_3}
                    rules={[
                      {
                        validator: (rule, value, callback) => validatePhone3(rule, value, callback),
                      },
                    ]}
                  >
                    <Input
                      className="input-form w-[100px]"
                      placeholder="0000"
                      onChange={(e) => handleChangePhone3(e.target.value)}
                      maxLength={4}
                    />
                  </Form.Item>
                </div>
                {(messageErrorPhone1 || messageErrorPhone2 || messageErrorPhone3) && (
                  <p className="invalid-feedback whitespace-pre-line">
                    {messageErrorPhone1 || messageErrorPhone2 || messageErrorPhone3}
                  </p>
                )}
              </div>

              <div className="mb-[16px]">
                <Form.Item
                  required
                  label="郵便番号"
                  name="post_code"
                  initialValue={detailData?.post_code}
                  rules={[{ validator: (rule, value, callback) => validatePostCode(rule, value, callback) }]}
                >
                  <Input className="input-form" placeholder="郵便番号を検索してください" maxLength={7} />
                </Form.Item>
              </div>

              <div className="mb-[16px]">
                <Form.Item
                  required
                  label="住所"
                  name="address"
                  initialValue={detailData?.address}
                  rules={[{ validator: (rule, value, callback) => validateAddress(rule, value, callback) }]}
                >
                  <Input className="input-form" placeholder="住所を入力してください" />
                </Form.Item>
              </div>
            </div>
            <div className="footer-form flex">
              <Button className="button-form mr-[16px]" onClick={handleGoBack}>
                キャンセル
              </Button>
              <Button
                disabled={isDisableSubmit}
                className="button-form button-form-Submit"
                loading={isLoadingEdit}
                onClick={handleEditUser}
              >
                <span></span>OK
              </Button>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default UserEditComponent;
