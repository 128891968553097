import { useEffect, useState } from 'react';
import TemplateDesignCreateEditComponent from './templateDesignCreateEditComponent';
import TemplateInfoGeneralCreateEditComponent from './templateInfoGeneralCreateEditComponent';
import { useDetailTemplate } from 'src/api/useDetailTemplate';
import { useParams } from 'react-router-dom';
import Loading from 'src/components/common/loading';
import NotFoundPage from 'src/pages/notFoundPage';
import { useCart } from '../../common/Context';

const TemplateCreateEditComponent = () => {
  const { id } = useParams<{ id: string }>();
  const [thumbnailUrl, setThumbnailUrl] = useState<string>('');
  const [thumbnailFile, setThumbnailFile] = useState<Array<any>>();
  const [infoGeneralTemplate, setInfoGeneralTemplate] = useState<any>();
  const [imageUrl, setImageUrl] = useState<string>('');
  const [imageFile, setImageFile] = useState<Array<any>>();
  const [background, setBackground] = useState<Array<any>>();
  const isDesignPage = location.pathname?.includes('design');
  const { data: detailData, isLoading: isLoadingDetail, refetch, isError } = useDetailTemplate(Number(id));
  const { setAcrylic } = useCart();

  useEffect(() => {
    if (detailData) {
      setInfoGeneralTemplate({
        ...detailData,
        acrylic_plate: detailData?.acrylic_plate_size?.acrylic_plate_id,
        height: detailData?.acrylic_plate_size?.height,
        width: detailData?.acrylic_plate_size?.width,
        aspect_ratio: detailData?.acrylic_plate_size?.aspect_ratio
      });

      setThumbnailUrl(detailData?.thumbnail_url);
      setImageUrl(detailData?.image_url);
    }
  }, [detailData]);

  useEffect(() => {
    if (infoGeneralTemplate) {
      setAcrylic(infoGeneralTemplate);
    }
  }, [infoGeneralTemplate]);

  if ((!detailData || Object.keys(detailData).length === 0 || isError) && !isLoadingDetail && id) {
    return <NotFoundPage />;
  }

  return (
    <>
      {isLoadingDetail ? (
        <Loading />
      ) : (
        <>
          {isDesignPage && infoGeneralTemplate ? (
            <TemplateDesignCreateEditComponent
              setImageUrl={setImageUrl}
              setImageFile={setImageFile}
              background={background}
              setBackground={setBackground}
              detailData={detailData}
              infoGeneralTemplate={infoGeneralTemplate}
            />
          ) : (
            <TemplateInfoGeneralCreateEditComponent
              thumbnailUrl={thumbnailUrl}
              thumbnailFile={thumbnailFile}
              imageFile={imageFile}
              imageUrl={imageUrl}
              background={background}
              setThumbnailUrl={setThumbnailUrl}
              setThumbnailFile={setThumbnailFile}
              setInfoGeneralTemplate={setInfoGeneralTemplate}
              infoGeneralTemplate={infoGeneralTemplate}
            />
          )}
        </>
      )}
    </>
  );
};

export default TemplateCreateEditComponent;
