import React from "react";

//{
// type: 5,//polygon
// points: [{
// x: 100,
// y: 10
// },{
// x: 150,
// y: 190
// },{
// x: 50,
// y: 190
// }],//this is triangle, in this case, scale must scale point to the center of the shape, we can also write a function to do this
// fillColor: 'red'
// }
const PolygonSvg = ({
	shapeInfo,
	outline,
	drawOutline,
	handleClick,
	index,
}: any) => {
	if (drawOutline) {
		return (
			<polygon
				id={index}
				onClick={handleClick}
				points={shapeInfo?.points
					?.map((point: any) => {
						return point?.x + "," + point?.y;
					})
					.join(" ")}
				fill={outline?.paddingColor ?? "white"}
				stroke={shapeInfo?.stroke ?? "black"}
				strokeWidth={shapeInfo?.outlinePadding ?? "0.5"}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform={
					shapeInfo?.transform
						? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
						: ""
				}
			/>
		);
	} else {
		return (
			<polygon
				points={shapeInfo?.points
					?.map((point: any) => {
						return point?.x + "," + point?.y;
					})
					.join(" ")}
				fill={shapeInfo?.fillColor}
				transform={
					shapeInfo?.transform
						? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
						: ""
				}
			/>
		);
	}
};

export default PolygonSvg;
