import React from 'react';
import { generateTransformAttributes } from '../utils';

const DeleteMaskList = ({ shapeData, isDeleteMode, isRestoreMode, isSingleShowMode }: any) => {
  return (
    <>
      {shapeData.map((shapeInfo: any, shapeIndex: any) => {
        if (shapeInfo?.deletePath && shapeInfo?.deletePath?.length > 0) {
          return (
            <>
              {isRestoreMode ? (
                <></>
              ) : (
                <mask id={`mask_delete_${isSingleShowMode ? '_' + shapeIndex : shapeIndex}`}>
                  <circle cx="0" cy="0" r="10000" fill="white" />
                  {shapeInfo?.deletePath.map((fullPath: any, indexPath: any) => {
                    return (
                      <path
                        key={indexPath}
                        d={`M${fullPath?.path
                          .map((path: any) => {
                            return `${path?.x} ${path?.y}`;
                          })
                          .join('L')}`}
                        stroke={
                          fullPath?.type === 'delete'
                            ? !isSingleShowMode
                              ? 'black'
                              : 'black'
                            : fullPath?.type === 'restore'
                            ? 'white'
                            : 'red'
                        }
                        strokeWidth={fullPath?.size}
                        fill={'none'}
                        transform={isSingleShowMode || isRestoreMode ? '' : generateTransformAttributes({ shapeInfo })}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    );
                  })}
                </mask>
              )}
              {shapeInfo?.imageBackUp || shapeInfo?.text ? (
                <mask id={`mask_restore_${shapeIndex}`}>
                  {isRestoreMode ? (
                    <circle cx="0" cy="0" r="10000" fill="#fff" opacity={0.5} />
                  ) : (
                    <circle cx="0" cy="0" r="10000" fill="#000" />
                  )}
                  {shapeInfo?.deletePath.map((fullPath: any, indexPath: any) => {
                    return (
                      <path
                        key={indexPath}
                        d={`M${fullPath?.path
                          .map((path: any) => {
                            return `${path?.x} ${path?.y}`;
                          })
                          .join('L')}`}
                        stroke={fullPath?.type === 'delete' ? 'black' : fullPath?.type === 'restore' ? 'white' : 'red'}
                        strokeWidth={fullPath?.size}
                        opacity={1}
                        fill={'none'}
                        transform={isSingleShowMode || isRestoreMode ? '' : generateTransformAttributes({ shapeInfo })}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    );
                  })}
                </mask>
              ) : (
                <></>
              )}
            </>
          );
        } else {
          return (
            <>
              {shapeInfo?.imageBackUp || shapeInfo?.text ? (
                <mask id={`mask_restore_${shapeIndex}`}>
                  {isRestoreMode ? (
                    <circle cx="0" cy="0" r="10000" fill="#fff" opacity={0.5} />
                  ) : (
                    <circle cx="0" cy="0" r="10000" fill="#000" />
                  )}
                </mask>
              ) : (
                <></>
              )}
            </>
          );
        }
      })}
    </>
  );
};

export default DeleteMaskList;
