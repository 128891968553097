import { apiFormData } from 'src/api/axios';

export interface NotifyEditType {
  title: string;
  content: string;
  image_thumbnail: File;
  status: number | string;
  highlight?: number | string;
}

export const useEditNotify = (params: NotifyEditType, id: number | string) =>
apiFormData.post(`/api/cms/notification/${id}`, params);
