import React from 'react';
import IconBagTick from 'src/assets/icon/IconBagTick';
import IconCancel from 'src/assets/icon/IconCancel';
import IconReceipt from 'src/assets/icon/IconReceipt';
import IconVerify from 'src/assets/icon/IconVerify';

interface ProgressTypeProps {
  status: number;
  cancel?: boolean;
  time_accepted?: string;
  time_processing?: string;
  time_delivered?: string;
  time_cancel?: string;
  stepsOption?: any;
  classNameStepper?: any;
}

const Progress: React.FC<ProgressTypeProps> = ({
  status = 1,
  cancel = false,
  time_accepted = '',
  time_processing = '',
  time_delivered = '',
  time_cancel = '',
  stepsOption,
  classNameStepper = '',
}) => {
  const STEPS = [
    {
      icon: <IconReceipt />,
      name: '注文受付',
      time: time_accepted,
      processing: 1,
    },
    {
      icon: <IconVerify />,
      name: '処理中',
      time: time_processing,
      processing: 2,
    },
    {
      icon: <IconBagTick />,
      name: '配送済',
      time: time_delivered,
      processing: 3,
    },
  ];

  return (
    <div className="stepper-wrapper w-[780px] mx-auto">
      {cancel ? (
        <>
          <div className="stepper-item completed">
            <div className="step-counter">
              <IconReceipt />
            </div>
            <div className="step-name">注文受付</div>
            {time_accepted && <div className="step-time">{time_accepted}</div>}
          </div>
          <div className="stepper-item cancel">
            <div className="step-counter">
              <IconCancel />
            </div>
            <div className="step-name">キャンセル</div>
            {time_cancel && <div className="step-time">{time_cancel}</div>}
          </div>
        </>
      ) : (
        <>
          {(stepsOption || STEPS)?.map((step: any, index: any) => (
            <div
              key={index}
              className={`stepper-item ${classNameStepper} ${step.processing <= status ? 'completed' : ''}`}
            >
              <div className="step-counter">{step.icon}</div>
              <div className="step-name">{step.name}</div>
              {step.time && <div className="step-time">{step.time}</div>}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Progress;
