import { HEIGHT_STAGE, WIDTH_STAGE } from 'src/assets/dataUI';
import BaseSvgPath from './components/BaseSvgPath';
import OutlineSvgPath from './components/OutlineSvgPath';
import TinyMaskPath from './components/TinyMaskPath';
import SmallMaskPath from './components/SmallMaskPath';
import ExternalMaskPath from './components/ExternalMaskPath';
import MoveablePart from './components/MoveablePart';
import {
  startDrag,
  drag,
  endDrag,
  startDeletePath,
  dragDelete,
  endDragDelete,
  handleIsDownAndMoveForDelete,
} from './controllers/TouchController';
import { cloneDeep } from 'lodash';
import DeleteMaskList from './components/DeleteMaskList';
import { MAPPING_FONT } from './font/mapping';
import PathResolve from './PathResolve';

//this function will be used to generate the final SVG which will show to user
export const mergeSvgPath = ({
  backgroundImage,
  shapeData,
  outline,
  handleClick,
  hiddenIndex,
  onDragEnd,
  allowClickSvG = false,
  removeItem,
  onRotateEnd,
  extraState,
  isDeleteMode,
  isRestoreMode,
  isSingleShowMode,
  shapeSizeData,
  onDragDeleteEnd,
  onChangeCursorPosition,
  cursorSize,
  whiteBackground,
  isEndPhase,
  stateRatio
}: any) => {
  //The svg will show inside an svg object
  //based on shapeData we will generate the base shape 1st
  //if outline.showOutline = true => we will show the overlay data to the layer above
  // console.log({
  // shapeData,
  // outline,
  // hiddenIndex,
  // });
  const dataMoveable = shapeData[hiddenIndex];
  const handleDelete = () => {
    removeItem(dataMoveable?.id);
    handleClick(-1);
  };
  const textMetrics = getTextMetrics(
    dataMoveable?.text,
    dataMoveable?.fontFamily ?? 'Times New Roman',
    dataMoveable?.fontSize ?? '18px'
  );
  const widthText = textMetrics?.width + 2 + dataMoveable?.outlinePadding + 20;
  const heightText =
    textMetrics?.actualBoundingBoxAscent -
    textMetrics?.actualBoundingBoxDescent +
    dataMoveable?.outlinePadding +
    4 +
    (dataMoveable?.text ? textMetrics?.actualBoundingBoxDescent * 2 : 0) +
    20;

  if (isSingleShowMode && shapeData[0]) {
    //this will automatically render only single view for creating image
    // it will have Layer_2 as id
    // console.log({
    //   shapeSizeData,
    //   shape: shapeData[0]
    // })

    if (shapeSizeData) {
      let scaleRatioCheckWidth = shapeData[0]?.width / shapeSizeData?.width;
      let scaleRatioCheckHeight = shapeData[0]?.height / shapeSizeData?.height;
      let scaleRatioCheck = scaleRatioCheckHeight;

      if (scaleRatioCheckWidth > scaleRatioCheckHeight) {
        scaleRatioCheck = scaleRatioCheckWidth;
      }

      return (
        <svg
          version="1.1"
          style={{
            backgroundImage: backgroundImage,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'relative',
            ...(whiteBackground ? { background: '#fff' } : {}),
          }}
          xmlns="http://www.w3.org/2000/svg"
          id={whiteBackground ? 'Layer_3' : 'Layer_2'}
          data-name="Layer 2"
          width={shapeSizeData?.width}
          height={shapeSizeData?.height}
          viewBox={`0 0 ${shapeData[0]?.width} ${shapeData[0]?.height}`}
        >
          {/* {shapeData[0]?.type == 1 ? (
            <defs>
              <style>
                {`
              @font-face {
                font-family: "${shapeData[0]?.fontFamily}";
                src: url("${MAPPING_FONT[shapeData[0]?.fontFamily]?.base64}") format('truetype');
              }

              text {
                    font-family: "${shapeData[0]?.fontFamily}"
              }`}
              </style>
            </defs>
          ) : (
            <></>
          )} */}
          <DeleteMaskList
            shapeData={shapeData}
            isDeleteMode={isDeleteMode}
            isRestoreMode={isRestoreMode}
            isSingleShowMode={isSingleShowMode}
          />
          <BaseSvgPath
            shapeData={[
              {
                ...shapeData[0],
                transform: {
                  x: -(
                    (((shapeData[0]?.height - shapeSizeData?.height) / 2) * shapeData[0]?.width) /
                      shapeSizeData?.height +
                    (shapeSizeData?.x * shapeData[0]?.width) / shapeSizeData?.width
                  ),
                  y: -(
                    (((shapeData[0]?.width - shapeSizeData?.width) / 2) * shapeData[0]?.height) / shapeSizeData?.width +
                    (shapeSizeData?.y * shapeData[0]?.width) / shapeSizeData?.width
                  ),
                },
                scale: {
                  base: scaleRatioCheck,
                },
              },
            ]}
            outline={outline}
            handleClick={handleClick}
            allowClickSvG={allowClickSvG}
            hiddenIndex={hiddenIndex}
            onDragEnd={onDragEnd}
            onRotateEnd={onRotateEnd}
            extraState={extraState}
            isSingleShowMode={isSingleShowMode}
            whiteBackground={whiteBackground}
          />
        </svg>
      );
    } else {
      return (
        <svg
          version="1.1"
          style={{
            backgroundImage: backgroundImage,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            position: 'relative',
            ...(whiteBackground ? { background: '#fff' } : {}),
          }}
          xmlns="http://www.w3.org/2000/svg"
          id={whiteBackground ? 'Layer_3' : 'Layer_2'}
          data-name="Layer 2"
          width={shapeData[0]?.width}
          height={shapeData[0]?.height}
          viewBox={`0 0 ${shapeData[0]?.width} ${shapeData[0]?.height}`}
        >
          {/* {shapeData[0]?.type == 1 ? (
            <defs>
              <style>
                {`
              @font-face {
                font-family: "${shapeData[0]?.fontFamily}";
                src: url("${MAPPING_FONT[shapeData[0]?.fontFamily]?.base64}") format('truetype');
              }

              text {
                    font-family: "${shapeData[0]?.fontFamily}"
              }`}
              </style>
            </defs>
          ) : (
            <></>
          )} */}
          <filter id="outline">
            <feMorphology operator="dilate" radius="1" in="SourceAlpha" result="THICKNESS" />
            <feComposite operator="out" in="THICKNESS" in2="SourceGraphic"></feComposite>
          </filter>
          <filter id="negative">
            <feColorMatrix
              values="0 0 0 0 0
              1 1 1 1 0
              0 0 0 0 0
              0 0 0 1 0"
            />
          </filter>
          <DeleteMaskList
            shapeData={shapeData}
            isDeleteMode={isDeleteMode}
            isRestoreMode={isRestoreMode}
            isSingleShowMode={isSingleShowMode}
          />
          <BaseSvgPath
            shapeData={[
              {
                ...shapeData[0],
                transform: {
                  x: 0,
                  y: 0,
                },
                scale: {
                  base: 1,
                },
              },
            ]}
            outline={outline}
            handleClick={handleClick}
            allowClickSvG={allowClickSvG}
            hiddenIndex={hiddenIndex}
            onDragEnd={onDragEnd}
            onRotateEnd={onRotateEnd}
            extraState={extraState}
            isSingleShowMode={isSingleShowMode}
            whiteBackground={whiteBackground}
          />
        </svg>
      );
    }
  }

  let isDownAndMove = false;
  //we define a testdata 1st, normally this will take data from svgPathArray
  //we starting to merge 2 path together
  if (!isDeleteMode && !isRestoreMode) {
    //if it was show outline => we will need to create double path of each path, then modify its stroke width and color, we will keep outline as a filter
    return (
      <svg
        version="1.1"
        style={{
          backgroundImage: backgroundImage,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          position: 'relative',
        }}
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        width={stateRatio?.ratioWidth}
        height={stateRatio?.ratioHeight}
        viewBox={`0 0 ${stateRatio?.ratioWidth} ${stateRatio?.ratioHeight}`}
        onClick={(e: any) => {
          if (allowClickSvG) {
            if (e?.target?.id == 'Layer_1') {
              handleClick(-1);
            }
          }
        }}
        onMouseDown={(e: any) => {
          if (e?.target?.id !== 'Layer_1') {
            isDownAndMove = true;
            startDrag({
              index: hiddenIndex,
              handleClick,
              dataMoveable,
              extraState,
              evt: e,
            });
          } else {
            endDrag({
              evt: e,
              onDragEnd,
              onRotateEnd,
              index: hiddenIndex,
              extraState,
              dataMoveable,
            });
          }
        }}
        onMouseMove={(e: any) => {
          if (hiddenIndex > -1) {
            drag({
              evt: e,
              index: hiddenIndex,
              handleClick,
              dataMoveable,
              onRotateEnd,
              extraState,
              onDragEnd,
              isDownAndMove,
            });
          }
        }}
        onMouseUp={(e: any) => {
          isDownAndMove = false;
          endDrag({
            evt: e,
            onDragEnd,
            onRotateEnd,
            index: hiddenIndex,
            extraState,
          });
        }}
        onMouseLeave={(e: any) => {
          if (hiddenIndex > -1) {
            isDownAndMove = false;
            endDrag({
              evt: e,
              onDragEnd,
              onRotateEnd,
              index: hiddenIndex,
              extraState,
            });
          }
        }}
      >
        {/* <filter id="outline">
          <feMorphology operator="dilate" radius="1" in="SourceAlpha" result="THICKNESS" />
          <feComposite operator="out" in="THICKNESS" in2="SourceGraphic"></feComposite>
        </filter>
        <filter id="outline_minimun">
          <feMorphology operator="dilate" radius="0" in="SourceAlpha" result="THICKNESS" />
          <feComposite operator="out" in="THICKNESS" in2="SourceGraphic"></feComposite>
        </filter> */}
        {/* {shapeData[0]?.type == 1 ? (
          <defs>
            <style>
              {shapeData?.map((shapeInfo: any) => {
                return shapeInfo?.type !== 1
                  ? ""
                  : `@font-face {
                    font-family: "${shapeInfo?.fontFamily}";
                    src: url("${MAPPING_FONT[shapeInfo?.fontFamily]?.base64}") format('truetype');
                  }
    
                  text {
                    font-family: "${shapeInfo?.fontFamily}"
                  }`;
              })}
            </style>
          </defs>
        ) : (
          <></>
        )} */}

        <DeleteMaskList shapeData={shapeData} isDeleteMode={isDeleteMode} isRestoreMode={isRestoreMode} />
        <TinyMaskPath shapeData={shapeData} hiddenIndex={hiddenIndex} />
        <SmallMaskPath shapeData={shapeData} hiddenIndex={hiddenIndex} />
        {outline?.showOutline ? (
          <OutlineSvgPath
            shapeData={shapeData}
            outline={outline}
            hiddenIndex={hiddenIndex}
            handleClick={handleClick}
            allowClickSvG={allowClickSvG}
            onDragEnd={onDragEnd}
            onRotateEnd={onRotateEnd}
            extraState={extraState}
            isEndPhase={isEndPhase}
          />
        ) : (
          <></>
        )}
        <BaseSvgPath
          shapeData={shapeData}
          outline={outline}
          handleClick={handleClick}
          allowClickSvG={allowClickSvG}
          hiddenIndex={hiddenIndex}
          onDragEnd={onDragEnd}
          onRotateEnd={onRotateEnd}
          extraState={extraState}
        />
        <ExternalMaskPath shapeData={shapeData} hiddenIndex={hiddenIndex} handleClick={handleClick} />
        <MoveablePart
          dataMoveable={dataMoveable}
          hiddenIndex={hiddenIndex}
          outline={outline}
          handleClick={handleClick}
          handleDelete={handleDelete}
          heightText={heightText}
          widthText={widthText}
          extraState={extraState}
        />
      </svg>
    );
  } else {
    //normally this kind of edit will have only 1 shape => we will add that default to that shape
    return (
      <svg
        version="1.1"
        className={'svgView'}
        style={{
          backgroundImage: backgroundImage,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          position: 'relative',
        }}
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        data-name="Layer 1"
        width={stateRatio?.ratioWidth}
        height={stateRatio?.ratioHeight}
        viewBox={`0 0 ${stateRatio?.ratioWidth} ${stateRatio?.ratioHeight}`}
        onClick={(e: any) => {
          if (allowClickSvG) {
            if (e?.target?.id == 'Layer_1') {
              handleClick(-1);
            }
          } else {
          }
        }}
        onMouseDown={(e: any) => {
          //we will start a path
          handleIsDownAndMoveForDelete(true);
          startDeletePath({
            index: 0,
            handleClick,
            dataMoveable: shapeData[0],
            extraState,
            evt: e,
            onChangeCursorPosition,
            cursorSize,
          });
          // if (e?.target?.id !== "Layer_1") {
          //   startDrag({
          //     index: hiddenIndex,
          //     handleClick,
          //     dataMoveable,
          //     extraState,
          //     evt: e,
          //   });
          // } else {
          //   endDrag({
          //     evt: e,
          //     onDragEnd,
          //     onRotateEnd,
          //     index: hiddenIndex,
          //     extraState,
          //     dataMoveable,
          //   });
          // }
        }}
        onMouseMove={(e: any) => {
          //we adding deleted path to output
          dragDelete({
            evt: e,
            extraState,
            onDragDeleteEnd,
            onChangeCursorPosition,
          });
        }}
        onMouseLeave={(e: any) => {
          handleIsDownAndMoveForDelete(false);
          //we try end the path that we are creating
          endDragDelete({
            evt: e,
            onDragDeleteEnd,
          });
        }}
        onMouseUp={(e: any) => {
          handleIsDownAndMoveForDelete(false);
          //we try end the path that we are creating
          endDragDelete({
            evt: e,
            onDragDeleteEnd,
            onChangeCursorPosition,
          });
        }}
      >
        <filter id="outline">
          <feMorphology operator="dilate" radius="1" in="SourceAlpha" result="THICKNESS" />
          <feComposite operator="out" in="THICKNESS" in2="SourceGraphic"></feComposite>
        </filter>
        <filter id="dummy-filter">
          <feColorMatrix type="saturate" values="1" />
        </filter>
        {/* {shapeData[0]?.type == 1 ? (
          <defs>
            <style>
              {shapeData?.map((shapeInfo: any) => {
                return shapeInfo?.type !== 1
                  ? ""
                  : `@font-face {
                    font-family: "${shapeInfo?.fontFamily}";
                    src: url("${MAPPING_FONT[shapeInfo?.fontFamily]?.base64}") format('truetype');
                  }
    
                  text {
                    font-family: "${shapeInfo?.fontFamily}"
                  }`;
              })}
            </style>
          </defs>
        ) : (
          <></>
        )} */}
        <DeleteMaskList shapeData={shapeData} isDeleteMode={isDeleteMode} isRestoreMode={isRestoreMode} />
        <TinyMaskPath shapeData={shapeData} hiddenIndex={hiddenIndex} />
        <SmallMaskPath shapeData={shapeData} hiddenIndex={hiddenIndex} />
        {outline?.showOutline ? (
          <OutlineSvgPath
            shapeData={shapeData}
            outline={outline}
            hiddenIndex={hiddenIndex}
            handleClick={handleClick}
            allowClickSvG={allowClickSvG}
            onDragEnd={onDragEnd}
            onRotateEnd={onRotateEnd}
            extraState={extraState}
          />
        ) : (
          <></>
        )}
        <BaseSvgPath
          shapeData={shapeData}
          outline={outline}
          handleClick={handleClick}
          allowClickSvG={allowClickSvG}
          hiddenIndex={hiddenIndex}
          onDragEnd={onDragEnd}
          onRotateEnd={onRotateEnd}
          extraState={extraState}
          isRestoreMode={isRestoreMode}
        />
        <ExternalMaskPath shapeData={shapeData} hiddenIndex={hiddenIndex} handleClick={handleClick} />
        <MoveablePart
          dataMoveable={dataMoveable}
          hiddenIndex={hiddenIndex}
          outline={outline}
          handleClick={handleClick}
          handleDelete={handleDelete}
          heightText={heightText}
          widthText={widthText}
          extraState={extraState}
        />
      </svg>
    );
  }
};

//calculate type 98 polygon
export const generatePointLine = (pointArray: any) => {
  let output = '';

  output = pointArray
    .map((point: any) => {
      return point.x + ',' + point.y;
    })
    .join(' ');

  return output;
};

export const calculateNewPoint = (pointArray: any, scalePercent: any) => {
  let output = pointArray;

  let centerPointX = 0;
  let centerPointY = 0;

  pointArray.map((point: any) => {
    centerPointX += point.x;
    centerPointY += point.y;
    return point;
  });

  centerPointX = centerPointX / pointArray.length;
  centerPointY = centerPointY / pointArray.length;

  output = pointArray.map((point: any) => {
    let newX = point.x + (point.x - centerPointX) * (scalePercent - 1);
    let newY = point.y + (point.y - centerPointY) * (scalePercent - 1);
    return {
      x: newX,
      y: newY,
    };
  });

  return output;
};

export const getTextMetrics: any = (text: any, font: any, fontSize: any, letterSpacing: '1px') => {
  // re-use canvas object for better performance
  const canvas = getTextMetrics.canvas || (getTextMetrics.canvas = document.createElement('canvas'));
  const context = canvas.getContext('2d');
  context.font = `${fontSize} ${font}`;
  //context.letterSpacing = letterSpacing;
  let metrics = context.measureText(text);

  //modify text letter spacing by code
  metrics.extra = (text?.length - 1) * Number(letterSpacing?.replace('px', ''));

  return metrics;
};

export const getCorrectRotationRadian = ({ coord, rootRotation, rotationCenter, rootAngle }: any) => {
  let output = 0;
  //radian of root rotation
  let dx1 = rootRotation.x - rotationCenter.x;
  let dy1 = rootRotation.y - rotationCenter.y;
  let radian1 = Math.atan2(dy1, dx1);

  //radian of current rotation
  let dx2 = coord.x - rotationCenter.x;
  let dy2 = coord.y - rotationCenter.y;
  let radian2 = Math.atan2(dy2, dx2);

  let radians = radian2 - radian1;

  let degrees = (radians * 180) / Math.PI;

  output = degrees + rootAngle;

  return output;
};

export const generateTransformAttributes = ({
  shapeInfo,
  isAddScaleToRotate,
  isMoveToDoublePadding,
  isMoveToEndPadding,
  additionRotate = 0,
  isNoScale,
  isTextRotate,
  isApplyMatrix,
  isTransformImage,
}: any) => {
  let output = '';

  let attributes = [];

  if (shapeInfo?.transform) {
    //this using to move the start of item to x = -2 * outline padding y = -2 * outline padding
    //using for drawing delete Mark

    let transformData = shapeInfo?.transform;
    if (isTransformImage && shapeInfo?.transform_image) {
      transformData = shapeInfo?.transform_image;
    }

    if (isMoveToDoublePadding) {
      attributes.push(
        `translate(${(transformData?.x - 2 * shapeInfo?.outlinePadding - 6).toFixed(2)},${(
          transformData?.y -
          2 * shapeInfo?.outlinePadding -
          6
        ).toFixed(2)})`
      );
    } else if (isMoveToEndPadding) {
      //this case use for rotate mark => we move it at the end of bottom right , add extra 2 outline padding to both x and y
      attributes.push(
        `translate(${(
          transformData?.x +
          Number(shapeInfo?.attributes?.viewBox?.split(' ')[2]) +
          3 * shapeInfo?.outlinePadding
        ).toFixed(2)},${(
          transformData?.y +
          Number(shapeInfo?.attributes?.viewBox?.split(' ')[3]) +
          3 * shapeInfo?.outlinePadding
        ).toFixed(2)})`
      );
    } else {
      attributes.push(`translate(${transformData?.x?.toFixed(2)},${transformData?.y?.toFixed(2)})`);
    }
  }

  if (shapeInfo?.scale) {
    if (isNoScale) {
      //
    } else {
      attributes.push(`scale(${shapeInfo?.scale?.base ?? 1})`);
    }
  }

  if (shapeInfo?.matrix && isApplyMatrix) {
    if (shapeInfo.type !== 1) {
      attributes.push(`matrix(-1, 0, 0, 1, ${Number(shapeInfo?.attributes?.viewBox?.split(' ')[2])}, 0)`);
    } else {
      attributes.push(`matrix(-1, 0, 0, 1, ${shapeInfo?.width}, 0)`);
    }
  }

  if (shapeInfo?.rotate) {
    let rotateValue = additionRotate + shapeInfo?.rotate?.a;
    if (isAddScaleToRotate) {
      attributes.push(
        `rotate(${rotateValue} ${shapeInfo?.rotate?.x * (shapeInfo?.rotate?.baseScale ?? 1)} ${
          shapeInfo?.rotate?.y * (shapeInfo?.rotate?.baseScale ?? 1)
        })`
      );
    } else {
      if (shapeInfo?.matrix && isApplyMatrix) {
        attributes.push(`rotate(${360 - rotateValue} ${shapeInfo?.rotate?.x} ${shapeInfo?.rotate?.y})`);
      } else {
        attributes.push(`rotate(${rotateValue} ${shapeInfo?.rotate?.x} ${shapeInfo?.rotate?.y})`);
      }
    }
  }

  output = attributes.join(' ');

  return output;
};

export const calculateSvgSize = ({ idToCheck, baseShapeInfo, isText }: any) => {
  let output = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
  if (isText) {
  } else {
    let elementToCalculate: any = document.getElementById(idToCheck);
    if (elementToCalculate) {
      output = elementToCalculate?.getBBox();
    }
  }
  var test = cloneDeep(output);

  return output;
};

export const revertCalculationBeforeTransform = ({ transform, rotate, scale, outputSvgSize }: any) => {
  let output = outputSvgSize;

  //1st we do transform
  if (transform?.x || transform?.y) {
    output.x = output.x - transform?.x;
    output.y = output.y - transform?.y;
  }

  //1st, we revert scale
  if (scale?.base) {
    output.width = output.width / scale?.base;
    output.height = output.height / scale?.base;
    output.x = output.x / scale?.base;
    output.y = output.y / scale?.base;
  }

  //3rd we do rotation, currently we don't do it xD

  return output;
};

export const revertCalculationPointBeforeTransform = ({ transform, rotate, scale, point, isMatrix }: any) => {
  let output = point;
  //1st we do transform
  if (transform?.x || transform?.y) {
    output.x = output.x - transform?.x;
    output.y = output.y - transform?.y;
  }

  //1st, we revert scale
  if (scale?.base) {
    output.x = output.x / scale?.base;
    output.y = output.y / scale?.base;
  }

  //3rd we do rotation, currently we don't do it xD
  if (isMatrix) {
    output.x = 2 * (rotate?.x / scale?.base) - output.x;
  }

  return output;
};

export const getPathMinxMiny = ({ pathStringArray, transform }: any) => {
  let minX = 999;
  let minY = 999;
  let lastTextPos = 0;

  //we create new path resolve object to get this value correct
  pathStringArray.map((path: any) => {
    let pathObject = new PathResolve(path);
    //we use function to get minX and minY of point
    let minObject = pathObject.getStartCoordinate();
    if (minObject?.x < minX) {
      minX = minObject?.x;
    }
    if (minObject?.y < minY) {
      minY = minObject?.y;
    }
  });
  //this value currently caculated wrong

  return {
    x: minX - transform?.x,
    y: minY - transform?.y,
  };
};

//Move object mean the minX and miny that we will need to move
export const revertPathStringByTransform = ({ pathString, transform, moveObject }: any) => {
  let pathObject = new PathResolve(pathString);
  pathObject.getOutputRevert(transform, moveObject);
  return pathObject.getPathString();
};

export const revertPathStringByScale = ({ pathString, scale, isNeedReplace }: any) => {
  let output = pathString;
  let pathObject = new PathResolve(pathString);
  pathObject.scale(1 / scale?.base);

  output = pathObject.getPathString();
  return output;
};

export const applyCalculationAfterTransform = ({ transform, rotate, scale, centerPoint }: any) => {
  let output = centerPoint;

  //1st, we revert scale
  if (scale?.base) {
    output.width = output.width * scale?.base;
    output.height = output.height * scale?.base;
    output.x = output.x * scale?.base;
    output.y = output.y * scale?.base;
  }

  //2nd we do transform
  if (transform?.x || transform?.y) {
    output.x = output.x + transform?.x;
    output.y = output.y + transform?.y;
  }

  return output;
};

export const DEFAULT_EXTRA_STATE = {
  isRotate: false,
  svgShapeEditing: false,
  startPositionDrag: false,
  lastPositionDrag: false,
  transformEditing: false,
  offsetEditing: false,
  currentCenter: false,
  startMouseRotate: false,
  isMoving: false,
  currentEdittingIndex: false,
  selectedElementData: false,
  currentCoordData: false,
};

export const base64ToUint8Array = (base64: any) => {
  var binaryString = atob(base64);
  var bytes = new Uint8Array(binaryString.length);
  for (var i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

export const generateBlobFromBase64 = ({ base64String }: any) => {
  if (typeof base64String !== 'string') {
    return base64String;
  }

  let match: any = base64String.match(/^data:image\/(\w+);base64,/);
  let parseParttern = /^data:image\/(\w+);base64,/;
  let isExtra = false;

  if (!match) {
    match = base64String.match(/^data:image\/svg\+xml;charset=utf-8;base64,/);
    parseParttern = /^data:image\/svg\+xml;charset=utf-8;base64,/;
    isExtra = true;
    //throw new Error("Failed to decode base64-string.");
  } else {
  }

  if (!match) {
    console.log('no match');
  }

  const imageSuffix = match[1];
  const base64StringWithoutPrefix = base64String.replace(parseParttern, '');
  const uint8Array = base64ToUint8Array(base64StringWithoutPrefix);

  const blob = new Blob([uint8Array], {
    type: isExtra ? `image/svg+xml;charset=utf-8` : `image/${imageSuffix}`,
  });

  return blob;
};
