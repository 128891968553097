import { nanoid } from 'nanoid';
import useItem from './useItem';
import { HEIGHT_STAGE, WIDTH_STAGE } from '../assets/dataUI';

const useHotkeyFunc = () => {
  const { stageData, createItem, updateItem } = useItem();
  const duplicateItems = (selectedItems: any, ratioSize: any) => {
    createItem({
      id: nanoid(),
      attrs: {
        zIndex: 1,
        shapeData: selectedItems?.attrs?.shapeData.map((item: any) => {
          if (item?.type === 1) {
            return {
              ...item,
              transform: {
                x: (ratioSize?.ratioWidth - item?.width) / 2,
                y: (ratioSize?.ratioHeight - item?.height) / 2,
              },
            };
          } else if (item?.type === 9) {
            return {
              ...item,
              transform: {
                x: (ratioSize?.ratioWidth - item?.shapeSize?.width) / 2,
                y: (ratioSize?.ratioHeight - item?.shapeSize?.height) / 2,
              },
            };
          } else
            return {
              ...item,
              transform: {
                x: (ratioSize?.ratioWidth - item?.width) / 2,
                y: (ratioSize?.ratioHeight - item?.width) / 2,
              },
            };
        }),
      },
    });
  };

  const layerUp = (selectedItems: any, setEdittingIndex: any) => {
    const maxZIndex = stageData.reduce((maxZ, item) => Math.max(maxZ, item?.attrs?.zIndex), 0);

    updateItem(selectedItems?.id, (prevData) => ({
      ...selectedItems?.attrs,
      zIndex: maxZIndex + 1,
    }));

    setEdittingIndex(-1);
  };

  const layerDown = (selectedItems: any, setEdittingIndex: any) => {
    const maxZIndex = stageData.reduce((maxZ, item) => Math.min(maxZ, item?.attrs?.zIndex), 0);

    updateItem(selectedItems?.id, (prevData) => ({
      ...selectedItems?.attrs,
      zIndex: maxZIndex - 1,
    }));

    setEdittingIndex(-1);
  };

  const flipHorizontally = (selectedItems: any, setEdittingIndex: any) => {
    updateItem(selectedItems?.id, (prevData) => ({
      ...selectedItems?.attrs,
      shapeData: selectedItems?.attrs?.shapeData.map((item: any) => {
        return {
          ...item,
          matrix: item?.matrix ? false : true,
        };
      }),
    }));
    // setEdittingIndex(-1);
  };

  const flipVertically = (selectedItems: any) => {
    updateItem(selectedItems?.id, (prevData) => ({
      ...selectedItems?.attrs,
      shapeData: selectedItems?.attrs?.shapeData.map((item: any) => {
        return {
          ...item,
          matrix: item?.matrix ? false : true,
        };
      }),
    }));
  };

  return {
    duplicateItems,
    layerUp,
    layerDown,
    flipHorizontally,
    flipVertically,
  };
};

export default useHotkeyFunc;
