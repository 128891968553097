import RevenueChartComponent from './revenueChartComponent';
import OrderStatisticListComponent from './orderStatisticListComponent';

const RevenueComponent = () => {
  return (
    <div className="page-content company-page">
      <div className="mb-[16px]">
        <h2 className="page-content-title border-bottom-title">売上一覧</h2>
      </div>
      <RevenueChartComponent />
      <OrderStatisticListComponent />
    </div>
  );
};

export default RevenueComponent;
