import styles from 'src/components/features/superAdmin/EditorComponent/FinalStep/index.module.scss';
import stylesStamp from '../index.module.scss';
import {
  HEIGHT_STAGE,
  OUTLINE_PADDING_DEFAULT,
  RANGE_SLIDER_STEP_SIZE,
  WIDTH_PANEL_STAMP_ITEM,
  WIDTH_STAGE,
} from 'src/assets/dataUI';
import { MenuStepEraseBrush } from '../../SvgMerge/menu';
import SvgMerge from '../../SvgMerge';
import MenuBottom from 'src/components/common/menuBottom';
import { Button, Slider } from 'antd';
import { cloneDeep, clone } from "lodash";
import {
  DEFAULT_EXTRA_STATE,
  revertCalculationPointBeforeTransform,
  revertPathStringByScale,
} from '../../SvgMerge/utils';
import { useEffect, useRef, useState } from 'react';
import { endAddPathDelete, startAddPathDelete, startAddPathRestore } from '../../SvgMerge/controllers/TouchController';
import { generateSvgFromUrl } from '../../SvgMerge/ImageToSvg';
import { nodeToObject } from 'src/utils';
import { Spin } from 'antd';

const TAB_STAMP = {
  eraser: 'eraser',
  recover: 'recover',
};

const Step2AddStamp = ({
  svgContent,
  setStep,
  width,
  setWidth,
  imageSize,
  setImageSize,
  setSvgContent,
  newImage,
  setNewImage,
  imageBackUp,
  deletePath,
  setDeletePath,
  isDeleteReCover,
  setIsDeleteReCover,
  isPng,
  isStep2,
  setIsStep2,
}: any) => {
  const [blobLink, setBlobLink] = useState('');
  const [blobPathLink, setBlobPathLink] = useState('');
  const [base64Blob, setBase64Blob] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [deletePathScale, setDeletePathScale] = useState([]);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isRestore, setIsRestore] = useState(false);
  const [lastestAction, setLatestAction] = useState('');
  const [createNewImage, setCreateNewImage] = useState(false);
  const [tab, setTab] = useState('');
  const [cursorSize, setCursorSize] = useState(20);
  const pathSimplifyRef = useRef(null);

  const [currentCursorPosition, setCurrentCursorPosition] = useState<any>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (isStep2) {
      setTimeout(() => {
        setCreateNewImage(true);
      }, 1000);
    }
  }, []);

  const [pathChanged, setPathChanged] = useState(isPng);
  const [reUpdatePath, setReUpdatePath] = useState(false);

  useEffect(() => {
    if (createNewImage) {
      setIsLoading(true);
      //get path from layer 3, get image from layer 2
      var baseSvg: any = document.getElementById('Layer_2');
      var svgString = new XMLSerializer().serializeToString(baseSvg);
      const svgLine = new Blob([svgString], {
        type: 'image/svg+xml',
      });

      const urlBase = URL.createObjectURL(svgLine);
      setBlobLink(urlBase);
      var baseSvgPath: any = document.getElementById('Layer_3');
      var svgStringPath = new XMLSerializer().serializeToString(baseSvgPath);
      const svgLinePath = new Blob([svgStringPath], {
        type: 'image/svg+xml',
      });

      const urlBasePath = URL.createObjectURL(svgLinePath);
      setTimeout(() => {
        setBlobPathLink(urlBasePath);
        console.log('setted');
      }, 2000);
      setCreateNewImage(false);
    }
  }, [createNewImage]);

  const onChangeDeletePath = ({ newPoint, pathIndex, path, type, size }: any) => {
    if (newPoint) {
      let pointData = cloneDeep(newPoint);
      let pointData2 = cloneDeep(newPoint);
      //if have new point => we only add that point to last data
      let newPointToAdd = revertCalculationPointBeforeTransform({
        transform: svgContent?.transform,
        rotate: svgContent?.rotate,
        scale: {
          base: svgContent?.scale?.base ?? 1,
        },
        point: pointData,
      });

      let newPointToAddScale = revertCalculationPointBeforeTransform({
        transform: svgContent?.transform,
        rotate: svgContent?.rotate,
        scale: {
          base:
            ((svgContent?.scale?.base ?? 1) * svgContent?.width) /
            (imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width),
        },
        point: pointData2,
      });

      let currentOutput: any = deletePath;
      let currentOutputScale: any = deletePathScale;

      if (deletePath[pathIndex]) {
        currentOutput[pathIndex] = {
          path: [...currentOutput[pathIndex]?.path, ...[newPointToAdd]],
          type: type,
          size: size,
        };

        currentOutputScale[pathIndex] = {
          path: [...currentOutputScale[pathIndex]?.path, ...[newPointToAddScale]],
          type: type,
          size: (size * (imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width)) / svgContent?.width,
        };
      } else {
        currentOutput.push({
          path: [newPointToAdd],
          type: type,
          size: size,
        });

        currentOutputScale.push({
          path: [newPointToAddScale],
          type: type,
          size: (size * (imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width)) / svgContent?.width,
        });
      }

      setDeletePath(cloneDeep(currentOutput));
      setDeletePathScale(cloneDeep(currentOutputScale));
    } else {
      let newData = cloneDeep(path);
      let newData2 = cloneDeep(path);
      let output: any = deletePath;
      let outputScale: any = deletePathScale;
      if (deletePath[pathIndex]) {
        output[pathIndex] = {
          path: newData.map((pathInfo: any) => {
            return revertCalculationPointBeforeTransform({
              transform: svgContent?.transform,
              rotate: svgContent?.rotate,
              scale: {
                base: svgContent?.scale?.base ?? 1,
              },
              point: pathInfo,
            });
          }),
          type: type,
          size: size,
        };

        outputScale[pathIndex] = {
          path: newData2.map((pathInfo: any) => {
            return revertCalculationPointBeforeTransform({
              transform: svgContent?.transform,
              rotate: svgContent?.rotate,
              scale: {
                base:
                  ((svgContent?.scale?.base ?? 1) * svgContent?.width) /
                  (imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width),
              },
              point: pathInfo,
            });
          }),
          type: type,
          size: (size * (imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width)) / svgContent?.width,
        };
      } else {
        output.push({
          path: newData.map((pathInfo: any) => {
            return revertCalculationPointBeforeTransform({
              transform: svgContent?.tranform,
              rotate: svgContent?.rotate,
              scale: svgContent?.scale,
              point: pathInfo,
            });
          }),
          type: type,
          size: size,
        });

        outputScale.push({
          path: newData2.map((pathInfo: any) => {
            return revertCalculationPointBeforeTransform({
              transform: svgContent?.tranform,
              rotate: svgContent?.rotate,
              scale: {
                base:
                  ((svgContent?.scale?.base ?? 1) * svgContent?.width) /
                  (imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width),
              },
              point: pathInfo,
            });
          }),
          type: type,
          size: (size * (imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width)) / svgContent?.width,
        });
      }

      setDeletePath(clone(output));
      setDeletePathScale(clone(outputScale));
    }
  };

  const svgElement = (
    <SvgMerge
      shapeData={[
        {
          type: 9,
          ...svgContent,
          imageBackUp: imageBackUp,
          deletePath,
        },
      ]}
      outline={{
        showOutline: false,
      }}
      extraState={DEFAULT_EXTRA_STATE}
      isDeleteMode={isDeleting}
      isRestoreMode={isRestore}
      onDragDeleteEnd={onChangeDeletePath}
      onChangeCursorPosition={setCurrentCursorPosition}
      cursorSize={cursorSize}
    />
  );

  return (
    <>
      {loading ? (
        <div
          style={{
            backgroundColor: 'rgba(0,0,0,0.3)',
            display: 'flex',
            position: 'fixed',
            zIndex: 9999999,
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <></>
      )}

      <div className={styles.editor}>
        <div
          className={`${styles.stage} ${styles.stageImage}`}
          style={
            {
              width: WIDTH_STAGE,
              height: HEIGHT_STAGE,
              '--bg-pos':
                isDeleting || isRestore
                  ? `${currentCursorPosition?.x - cursorSize / 2}px ${currentCursorPosition?.y - cursorSize / 2}px`
                  : `0px 0px`,
              '--bg-size': `${cursorSize}px ${cursorSize}px`,
            } as React.CSSProperties
          }
        >
          {svgContent && svgElement}
        </div>
      </div>

      <div style={{ display: 'none' }}>
        <SvgMerge
          shapeData={[
            {
              type: 9,
              ...svgContent,
              outlinePadding: OUTLINE_PADDING_DEFAULT,
              imageBackUp: imageBackUp,
              scale: {
                base: 2,
              },
              width: imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width,
              height: imageSize?.baseHeight ?? svgContent?.lastShapeSizeData?.height,
              attributes: {
                ...svgContent.attributes,
                height: imageSize?.baseHeight ?? svgContent?.lastShapeSizeData?.height,
                width: imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width,
                viewBox: `0 0 ${imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width} ${
                  imageSize?.baseHeight ?? svgContent?.lastShapeSizeData?.height
                }`,
              },
              size_image: {
                width: imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width,
                height: imageSize?.baseHeight ?? svgContent?.lastShapeSizeData?.height,
              },
              deletePath: deletePathScale,
            },
          ]}
          outline={{
            showOutline: false,
          }}
          extraState={DEFAULT_EXTRA_STATE}
          isDeleteMode={isDeleting}
          isRestoreMode={isRestore}
          onDragDeleteEnd={onChangeDeletePath}
          isSingleShowMode={true}
          whiteBackground={false}
        />
        <SvgMerge
          shapeData={[
            {
              type: 9,
              ...svgContent,
              scale: {
                base: 2,
              },
              width: imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width,
              height: imageSize?.baseHeight ?? svgContent?.lastShapeSizeData?.height,
              attributes: {
                ...svgContent.attributes,
                height: imageSize?.baseHeight ?? svgContent?.lastShapeSizeData?.height,
                width: imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width,
                viewBox: `0 0 ${imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width} ${
                  imageSize?.baseHeight ?? svgContent?.lastShapeSizeData?.height
                }`,
              },
              size_image: {
                width: imageSize?.baseWidth ?? svgContent?.lastShapeSizeData?.width,
                height: imageSize?.baseHeight ?? svgContent?.lastShapeSizeData?.height,
              },
              outlinePadding: OUTLINE_PADDING_DEFAULT,
              imageBackUp: imageBackUp,
              deletePath: deletePathScale,
            },
          ]}
          outline={{
            showOutline: false,
          }}
          extraState={DEFAULT_EXTRA_STATE}
          isDeleteMode={isDeleting}
          isRestoreMode={isRestore}
          onDragDeleteEnd={onChangeDeletePath}
          isSingleShowMode={true}
          whiteBackground={true}
        />
      </div>
      <div
        style={{
          position: 'absolute',
          top: 0,
          width: imageSize?.baseWidth + 'px',
          zIndex: -1,
        }}
      >
        <img
          id="blob_link_img"
          src={blobLink}
          alt=""
          onLoad={(e) => {
            let img = e.currentTarget; // Get the image element from the event
            let response: any = '';
            let resultBase64: any = '';
            var canvas: any = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            canvas.height = img.height;
            canvas.width = img.width;
            setTimeout(() => {
              ctx.drawImage(img, 0, 0);
              resultBase64 = canvas.toDataURL();
              setBase64Blob(resultBase64);
            }, 1000);
          }}
        />
        <img
          id="blob_path_link_img"
          src={blobPathLink}
          alt=""
          onLoad={(e) => {
            let imgPath = e.currentTarget; // Get the image element from the event
            var canvas2: any = document.createElement('canvas');
            var ctx2 = canvas2.getContext('2d');
            canvas2.height = imgPath.height - 1;
            canvas2.width = imgPath.width - 1;
            setTimeout(() => {
              ctx2.drawImage(
                imgPath,
                0,
                0,
                imgPath.width - 1,
                imgPath.height - 1,
                0,
                0,
                imgPath.width - 1,
                imgPath.height - 1
              );
              let responsePath: any = '';
              let resultBase64Path: any = '';
              resultBase64Path = canvas2.toDataURL();
              generateSvgFromUrl(resultBase64Path, (svg: any) => {
                if (svg) {
                  const parser = new DOMParser();
                  const svgDoc = parser.parseFromString(svg, 'image/svg+xml');
                  const svgContentData = nodeToObject(svgDoc);
                  let maxTotal = 0;
                  let minTotal = 999;
                  svgContentData?.children[0]?.children.map(function (item: any, index: any) {
                    let fillColorPath = item.attributes?.fill.replace('rgb(', '').replace(')', '').split(',');
                    let totalColor = 0;
                    if (fillColorPath.length == 3) {
                      totalColor = Number(fillColorPath[0]) + Number(fillColorPath[1]) + Number(fillColorPath[2]);
                    }

                    if (totalColor > maxTotal) {
                      maxTotal = totalColor;
                    }

                    if (totalColor < minTotal) {
                      minTotal = totalColor;
                    }
                    return true;
                  });

                  let filteredData = svgContentData?.children[0]?.children
                    .filter(function (item: any, index: any) {
                      let fillColorPath = item.attributes?.fill.replace('rgb(', '').replace(')', '').split(',');
                      let totalColor = 0;
                      if (fillColorPath.length == 3) {
                        totalColor = Number(fillColorPath[0]) + Number(fillColorPath[1]) + Number(fillColorPath[2]);
                      }

                      if (maxTotal - minTotal > 0) {
                        if (totalColor >= maxTotal) {
                          //remove all thing that nearest to white color
                          return false;
                        } else {
                          return true;
                        }
                      } else {
                        return true;
                      }
                    })
                    .map((pathInfo: any) => {
                      return {
                        ...pathInfo,
                        attributes: {
                          ...pathInfo?.attributes,
                          d: revertPathStringByScale({
                            pathString: pathInfo?.attributes?.d,
                            scale: {
                              base:
                                imageSize?.baseHeight / svgContent?.height ||
                                svgContent?.lastShapeSizeData?.height / svgContent?.height,
                            },
                          }),
                        },
                      };
                    });

                  setSvgContent({
                    ...svgContent,
                    ...svgContentData?.children[0],
                    ...(deletePath?.length > 0 ? { children: filteredData } : { children: svgContent?.children }),
                    original: deletePath?.length > 0 ? false : svgContentData?.original,
                    image_url: base64Blob,
                  });

                  if (deletePath.length > 0) {
                    setPathChanged(true);
                  }

                  setDeletePath([]);
                  setDeletePathScale([]);
                }
              });
              setIsLoading(false);
            }, 500);
          }}
        />
      </div>
      <canvas id="canvas" ref={pathSimplifyRef} style={{ display: 'none' }}></canvas>

      <div className="my-react-add-text flex justify-center mb-2">
        <div className="w-[500px]">
          {tab && (
            <div className={`${stylesStamp.content} ${stylesStamp.contentStep2}`}>
              {tab === TAB_STAMP?.eraser ? (
                <>
                  <p className={styles.scaleTitle}>消しゴム</p>

                  <div className={stylesStamp.scale}>
                    <Slider
                      tooltip={{ formatter: null }}
                      defaultValue={cursorSize}
                      min={10}
                      max={30}
                      step={RANGE_SLIDER_STEP_SIZE}
                      onChange={(e) => {
                        setCursorSize(e);
                      }}
                    />
                  </div>

                  <div className="flex justify-center w-full mt-1 ">
                    <Button
                      className="button-form button-form-Submit !w-auto"
                      onClick={(e: any) => {
                        setIsDeleting(false);
                        endAddPathDelete(1);
                        setCreateNewImage(true);
                        setTab('');
                      }}
                    >
                      編集した内容反映
                    </Button>
                  </div>
                </>
              ) : (
                <>
                  <p className={styles.scaleTitle}>修復ブラシ</p>

                  <div className={stylesStamp.scale}>
                    <Slider
                      tooltip={{ formatter: null }}
                      defaultValue={cursorSize}
                      min={10}
                      max={30}
                      step={RANGE_SLIDER_STEP_SIZE}
                      onChange={(e) => {
                        setCursorSize(e);
                      }}
                    />
                  </div>

                  <div className="flex justify-center w-full mt-1 ">
                    <Button
                      className="button-form button-form-Submit !w-auto"
                      onClick={(e: any) => {
                        setIsRestore(false);
                        endAddPathDelete(1);
                        setCreateNewImage(true);
                        setTab('');
                      }}
                    >
                      編集した内容反映
                    </Button>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {!tab && (
        <MenuBottom
          list={MenuStepEraseBrush({
            onClickErase: (e: any) => {
              setTab(TAB_STAMP.eraser);
              setIsDeleteReCover(true);
              if (isDeleting) {
                // setIsDeleting(false);
                // endAddPathDelete(1);
                // setCreateNewImage(true);
              } else {
                setLatestAction('delete');
                setIsDeleting(true);
                startAddPathDelete(deletePath.length);
              }
            },
            onClickBrush: (e: any) => {
              setTab(TAB_STAMP.recover);
              setIsDeleteReCover(true);
              if (isRestore) {
                // setIsRestore(false);
                // endAddPathDelete(1);
                // setCreateNewImage(true);
              } else {
                setLatestAction('restore');
                setIsRestore(true);
                startAddPathRestore(deletePath.length);
              }
            },
          })}
        />
      )}

      <div className="flex justify-center mt-3 gap-2">
        <Button
          disabled={!!tab}
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            setStep((prev: number) => prev - 1);
            setIsDeleteReCover(false);
            setWidth(WIDTH_PANEL_STAMP_ITEM);
            setIsStep2(true);
          }}
        >
          {'<<前のステップへ'}
        </Button>
        <Button
          disabled={!!tab}
          className="button-form button-form-Submit !w-auto"
          onClick={() => {
            setLatestAction('next');
            // setReUpdatePath(true);
            setStep((prev: number) => prev + 1);
          }}
        >
          {'次のステップへ>>'}
        </Button>
      </div>
    </>
  );
};

export default Step2AddStamp;
