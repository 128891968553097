import React from "react";

//{
// type: 4,//ecllipse
// radiusX: 30,
// radiusY: 50,
// startX: 50,//center X
// startY: 50,//center Y
// fillColor: 'red'
// }
const EclipseSvg = ({
	shapeInfo,
	outline,
	drawOutline,
	handleClick,
	index,
}: any) => {
	if (drawOutline) {
		return (
			<ellipse
				id={index}
				onClick={handleClick}
				rx={shapeInfo?.radiusX}
				ry={shapeInfo?.radiusY}
				cx={shapeInfo?.startX}
				cy={shapeInfo?.startY}
				fill={outline?.paddingColor ?? "white"}
				stroke={shapeInfo?.stroke ?? "black"}
				strokeWidth={shapeInfo?.outlinePadding ?? "0.5"}
				strokeLinecap="round"
				strokeLinejoin="round"
				transform={
					shapeInfo?.transform
						? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
						: ""
				}
			/>
		);
	} else {
		return (
			<ellipse
				onClick={handleClick}
				rx={shapeInfo?.radiusX}
				ry={shapeInfo?.radiusY}
				cx={shapeInfo?.startX}
				cy={shapeInfo?.startY}
				fill={shapeInfo?.fillColor}
				transform={
					shapeInfo?.transform
						? `translate(${shapeInfo?.transform?.x},${shapeInfo?.transform?.y})`
						: ""
				}
			/>
		);
	}
};

export default EclipseSvg;
