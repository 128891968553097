import { HEIGHT_STAGE, WIDTH_STAGE } from 'src/assets/dataUI';
import { calculateSvgSize, getTextMetrics } from '../../SvgMerge/utils';

export const generateTextShapeData = ({
  text,
  textStyle,
  textLength,
  svgContent,
  baseSvgContent,
  noRotate,
  editMode,
  newWidth,
  newHeight
}: any) => {
  let output: any = [];

  const infoMetricText = getTextMetrics(
    text,
    textStyle?.fontFamily ?? 'Times New Roman',
    svgContent?.fontSize,
    textStyle?.letterSpacing
  );

  let shapeSize = {
    x: 0,
    y: 0,
    width: infoMetricText?.width + infoMetricText?.extra,
    height: infoMetricText?.actualBoundingBoxAscent + infoMetricText?.actualBoundingBoxDescent,
  };

  let textShapeData = {
    ...svgContent,
    type: 1,
    text: text,
    startX: 0,
    startY: 0,
    fontSize: svgContent?.fontSize,
    fontWeight: 500,
    textLineWidth: 1,
    outlinePadding: textStyle?.outlinePadding ?? 8,
    fillColor: textStyle?.color,
    fontFamily: textStyle?.fontFamily,
    letterSpacing: textStyle?.letterSpacing,
    textStyle: {
      ...textStyle,
      fontSize: svgContent?.fontSize,
    },
    scale: {
      base: 1,
    },
    transform: editMode
      ? baseSvgContent?.attrs?.shapeData[0]?.transform
      : {
          x: (newWidth - shapeSize?.width) / 2,
          y: (newHeight - shapeSize?.height) / 2,
        },
    width: infoMetricText?.width + infoMetricText?.extra,
    height: infoMetricText?.actualBoundingBoxAscent + infoMetricText?.actualBoundingBoxDescent,
    shapeSize: shapeSize,
    matrix: baseSvgContent ? baseSvgContent?.attrs?.shapeData[0]?.matrix : false,
    metric: infoMetricText,
    rotate: {
      a: baseSvgContent ? (noRotate ? 0 : baseSvgContent?.attrs?.shapeData[0]?.rotate?.a) : 0,
      x: (infoMetricText?.width + infoMetricText?.extra) / 2,
      y: (infoMetricText?.actualBoundingBoxAscent + infoMetricText?.actualBoundingBoxDescent) / 2,
      baseScale: svgContent?.scale?.base ?? 1,
    },
  };

  output.push(textShapeData);
  return output;
};
