import { useQuery } from 'react-query';
import { api } from 'src/api/axios';

export const useFetchParts = (category_ids: number[]) => {
  return useQuery(['parts', category_ids], async () => {
    const url = `/api/user/part/list-parts`;
    const res = await api.get(url, {
      params: {
        category_ids,
      },
    });
    return res.data;
  });
};
