const IconTextSpacing = ({ className }: any) => (
  <svg className={className} width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.50037 35L0.5 28.0016L7.50037 21.0011L9.99443 23.4509L7.19392 26.2514H28.8061L26.0499 23.4454L28.4996 20.9956L35.5 27.9959L28.4996 34.9963L26.0056 32.5466L28.8061 29.746H7.19392L9.95014 32.5466L7.50037 35ZM9.07508 19.2492L16.2508 0H19.7584L26.9341 19.251H23.6112L21.9497 14.3515H14.15L12.3999 19.251L9.07508 19.2492ZM15.1136 11.5491H20.8882L18.0877 3.58694H17.9123L15.1136 11.5491Z"
      fill="black"
    />
  </svg>
);

export default IconTextSpacing;
