/* eslint-disable react/jsx-key */
import React from 'react';
import GenerateExternalMaskSvg from '../element/GenerateExternalMaskSvg';

const ExternalMaskPath = ({ shapeData, hiddenIndex, handleClick }: any) => {
  return (
    <>
      {shapeData?.map((shapeInfo: any, index: any) => {
        if (hiddenIndex === index || ![98, 99, 100].includes(shapeInfo?.type)) {
          <></>;
        } else {
          return <GenerateExternalMaskSvg shapeInfo={shapeInfo} handleClick={handleClick} />;
        }
      })}
    </>
  );
};

export default ExternalMaskPath;
