const IconEditorImage = ({ className }: any) => (
  <svg className={className} width="36" height="35" viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.38981 34.9996C3.87818 35.007 3.37048 34.9093 2.89804 34.7128C2.42561 34.5162 1.99849 34.225 1.64304 33.8569C1.27497 33.5015 0.983671 33.0744 0.787142 32.6019C0.590613 32.1295 0.493021 31.6218 0.500388 31.1102V3.88979C0.49313 3.37818 0.590774 2.87049 0.787297 2.39807C0.98382 1.92565 1.27506 1.49858 1.64304 1.14306C1.99849 0.774999 2.42561 0.483657 2.89804 0.287128C3.37048 0.0905985 3.87818 -0.00697937 4.38981 0.000388181H31.6102C32.1218 -0.00686995 32.6295 0.0908303 33.1019 0.287353C33.5743 0.483876 34.0014 0.775087 34.357 1.14306C34.7249 1.49858 35.0162 1.92565 35.2127 2.39807C35.4092 2.87049 35.5069 3.37818 35.4996 3.88979V31.1102C35.507 31.6218 35.4094 32.1295 35.2128 32.6019C35.0163 33.0744 34.725 33.5015 34.357 33.8569C34.0015 34.225 33.5744 34.5162 33.102 34.7128C32.6295 34.9093 32.1218 35.007 31.6102 34.9996H4.38981ZM4.38981 31.1102H31.6102V3.88979H4.38981V31.1102ZM6.3336 27.2207H29.6664L22.3749 17.4999L16.5417 25.277L12.1668 19.4438L6.3336 27.2207ZM11.1958 13.6105C11.58 13.6173 11.9615 13.5453 12.3167 13.399C12.672 13.2526 12.9935 13.0349 13.2614 12.7596C13.5369 12.4917 13.7546 12.1702 13.9009 11.815C14.0473 11.4597 14.1193 11.0782 14.1124 10.694C14.1192 10.3099 14.0471 9.92835 13.9008 9.57309C13.7544 9.21783 13.5368 8.89631 13.2614 8.62837C12.9935 8.35299 12.672 8.13534 12.3167 7.98898C11.9615 7.84263 11.58 7.77066 11.1958 7.77739C10.8117 7.77066 10.4302 7.84263 10.0749 7.98898C9.71966 8.13534 9.39815 8.35299 9.13021 8.62837C8.85482 8.89631 8.63723 9.21783 8.49088 9.57309C8.34452 9.92835 8.27248 10.3099 8.27922 10.694C8.27237 11.0782 8.34436 11.4597 8.49072 11.815C8.63709 12.1702 8.85474 12.4917 9.13021 12.7596C9.39794 13.0347 9.71915 13.2521 10.0741 13.3985C10.429 13.5449 10.812 13.617 11.1958 13.6105Z"
      fill="black"
    />
  </svg>
);

export default IconEditorImage;
