export enum TypeFilterRangeTime {
  THIS_MONTH = 1,
  LAST_MONTH = 2,
  LAST_TWO_MONTH = 3,
}

export enum TypeCategoryPart {
  HAS_SIZE = 1,
  NO_SIZE = 2,
}

export enum TypeCart {
  ACRYLIC_PLATE = "1",
  TEMPLATE_DESIGN = "2",
}