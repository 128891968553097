const IconEditBackground = ({ className }: any) => (
  <svg className={className} width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.5 30.2743C19.048 31.6599 21.0923 32.5024 23.3333 32.5024C28.1658 32.5024 32.0833 28.5849 32.0833 23.7524C32.0833 19.8871 29.5771 16.6073 26.101 15.4492"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.812 20.5508C20.2019 21.5422 20.416 22.622 20.416 23.7518C20.416 28.5843 16.4985 32.5018 11.666 32.5018C6.83352 32.5018 2.91602 28.5843 2.91602 23.7518C2.91602 19.8763 5.43559 16.5893 8.92602 15.4395"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 20.836C22.3325 20.836 26.25 16.9184 26.25 12.086C26.25 7.25344 22.3325 3.33594 17.5 3.33594C12.6675 3.33594 8.75 7.25344 8.75 12.086C8.75 16.9184 12.6675 20.836 17.5 20.836Z"
      stroke="black"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconEditBackground;
