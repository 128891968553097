const IconEdit = ({ className }: any) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={21} height={20} viewBox="0 0 21 20" fill="none">
    <path
      d="M11.5495 3.00002L4.7078 10.2417C4.44947 10.5167 4.19947 11.0584 4.14947 11.4334L3.84114 14.1333C3.7328 15.1083 4.4328 15.775 5.39947 15.6084L8.0828 15.15C8.4578 15.0834 8.9828 14.8084 9.24114 14.525L16.0828 7.28335C17.2661 6.03335 17.7995 4.60835 15.9578 2.86668C14.1245 1.14168 12.7328 1.75002 11.5495 3.00002Z"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.4082 4.20898C10.7665 6.50898 12.6332 8.26732 14.9499 8.50065"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 18.334H18"
      stroke="white"
      strokeWidth="1.5"
      strokeMiterlimit={10}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconEdit;
