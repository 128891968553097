import React from 'react';
import TextSvg from './shape/TextSvg';
import RectangleSvg from './shape/RectangleSvg';
import RectangleSvgSocket from './socket/SocketSvgRectangle';
import CircleSvg from './shape/CircleSvg';
import EclipseSvg from './shape/EclipseSvg';
import PolygonSvg from './shape/PolygonSvg';
import PathSvg from './shape/PathSvg';
import ArraySvg from './shape/ArraySvg';
import SvgContentList from './shape/SvgContentList';
import SocketSvgPolygon from './socket/SocketSvgPolygon';
import SocketSvg from './socket/SocketSvgCircle';
import SvgContentListImage from './shape/SvgContentListImage';

const GenerateShapeSvgByType = ({
  shapeInfo,
  outline,
  drawOutline,
  handleClick,
  index,
  isSingleShowMode,
  isRestoreMode,
  whiteBackground,
  isEndPhase,
  isSocket,
  hiddenIndex,
  isDrawInEdit,
}: any) => {
  switch (shapeInfo?.type) {
    case 1:
      return (
        <TextSvg
          shapeInfo={shapeInfo}
          outline={outline}
          drawOutline={drawOutline}
          handleClick={handleClick}
          index={index}
          isSingleShowMode={isSingleShowMode}
          isRestoreMode={isRestoreMode}
          whiteBackground={whiteBackground}
          isEndPhase={isEndPhase}
        />
      );
    case 2:
      return (
        <RectangleSvg
          shapeInfo={shapeInfo}
          outline={outline}
          drawOutline={drawOutline}
          handleClick={handleClick}
          index={index}
        />
      );
    case 3:
      return <CircleSvg shapeInfo={shapeInfo} outline={outline} drawOutline={drawOutline} handleClick={handleClick} />;
    case 4:
      return <EclipseSvg shapeInfo={shapeInfo} outline={outline} drawOutline={drawOutline} handleClick={handleClick} />;
    case 5:
      return <PolygonSvg shapeInfo={shapeInfo} outline={outline} drawOutline={drawOutline} handleClick={handleClick} />;
    case 6:
      return <PathSvg shapeInfo={shapeInfo} outline={outline} drawOutline={drawOutline} handleClick={handleClick} />;
    case 7:
      return <ArraySvg shapeInfo={shapeInfo} outline={outline} drawOutline={drawOutline} handleClick={handleClick} />;
    case 8:
      return (
        <SvgContentList
          shapeInfo={shapeInfo}
          outline={outline}
          drawOutline={drawOutline}
          handleClick={handleClick}
          index={index}
          isSingleShowMode={isSingleShowMode}
          isRestoreMode={isRestoreMode}
          isSocket={isSocket}
          hiddenIndex={hiddenIndex}
          isDrawInEdit={isDrawInEdit}
        />
      );
    case 9:
      return (
        <SvgContentListImage
          shapeInfo={shapeInfo}
          outline={outline}
          drawOutline={drawOutline}
          handleClick={handleClick}
          index={index}
          isSingleShowMode={isSingleShowMode}
          isRestoreMode={isRestoreMode}
          whiteBackground={whiteBackground}
        />
      );
    case 98:
      return (
        <SocketSvgPolygon shapeInfo={shapeInfo} outline={outline} drawOutline={drawOutline} handleClick={handleClick} />
      );
    case 99:
      return (
        <SocketSvg
          shapeInfo={shapeInfo}
          outline={outline}
          drawOutline={drawOutline}
          handleClick={handleClick}
          index={index}
        />
      );
    case 100:
      return (
        <RectangleSvgSocket
          shapeInfo={shapeInfo}
          outline={outline}
          drawOutline={drawOutline}
          handleClick={handleClick}
          index={index}
        />
      );
    default:
      return <></>;
  }
};

export default GenerateShapeSvgByType;
